import React from 'react'

export const IcoPaste = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-paste' viewBox="0 0 15 18" {...props} style={{...style,fill:'none'}}>
      <path d="M5.13636 8.99999H8.09091M5.13636 11.3636H8.09091M5.13636 13.7273H8.09091M10.4545 14.3182H12.2273C12.6974 14.3182 13.1483 14.1314 13.4808 13.799C13.8132 13.4665 14 13.0156 14 12.5454V4.35781C14 3.46357 13.3342 2.70484 12.4432 2.63078C12.1485 2.60634 11.8535 2.58533 11.5584 2.56775M11.5584 2.56775C11.6106 2.73721 11.6364 2.91356 11.6364 3.0909C11.6364 3.24762 11.5741 3.39792 11.4633 3.50874C11.3525 3.61956 11.2022 3.68181 11.0455 3.68181H7.5C7.17382 3.68181 6.90909 3.41709 6.90909 3.0909C6.90909 2.9089 6.93667 2.73321 6.98788 2.56775M11.5584 2.56775C11.3354 1.84448 10.661 1.31818 9.86364 1.31818H8.68182C8.30304 1.31827 7.93424 1.43962 7.62942 1.66448C7.3246 1.88934 7.09978 2.20588 6.98788 2.56775M6.98788 2.56775C6.69164 2.58587 6.39697 2.60715 6.1023 2.63078C5.21121 2.70484 4.54545 3.46357 4.54545 4.35781V6.04545M4.54545 6.04545H1.88636C1.39709 6.04545 1 6.44254 1 6.93181V15.7954C1 16.2847 1.39709 16.6818 1.88636 16.6818H9.56818C10.0575 16.6818 10.4545 16.2847 10.4545 15.7954V6.93181C10.4545 6.44254 10.0575 6.04545 9.56818 6.04545H4.54545ZM3.36364 8.99999H3.36994V9.0063H3.36364V8.99999ZM3.36364 11.3636H3.36994V11.3699H3.36364V11.3636ZM3.36364 13.7273H3.36994V13.7336H3.36364V13.7273Z" stroke='currentColor' strokeWidth="1.5"/>
    </svg>
  )
}
