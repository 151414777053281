import React from 'react'

export const IcoBancoProjetos = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-banco-projetos' viewBox="0 0 40 40" {...props} style={{...style,fill:'none'}}>
    <path d="M26.25 28.75V34.375C26.25 35.41 25.41 36.25 24.375 36.25H8.125C7.62772 36.25 7.15081 36.0525 6.79917 35.7009C6.44754 35.3492 6.25 34.8723 6.25 34.375V13.125C6.25 12.09 7.09 11.25 8.125 11.25H11.25C12.0876 11.2495 12.9238 11.3186 13.75 11.4567M26.25 28.75H31.875C32.91 28.75 33.75 27.91 33.75 26.875V18.75C33.75 11.3167 28.345 5.14837 21.25 3.9567C20.4238 3.81859 19.5876 3.74946 18.75 3.75003H15.625C14.59 3.75003 13.75 4.59003 13.75 5.62503V11.4567M26.25 28.75H15.625C15.1277 28.75 14.6508 28.5525 14.2992 28.2009C13.9475 27.8492 13.75 27.3723 13.75 26.875V11.4567M33.75 22.5V19.375C33.75 17.8832 33.1574 16.4525 32.1025 15.3976C31.0476 14.3427 29.6168 13.75 28.125 13.75H25.625C25.1277 13.75 24.6508 13.5525 24.2992 13.2009C23.9475 12.8492 23.75 12.3723 23.75 11.875V9.37503C23.75 8.63635 23.6045 7.9049 23.3218 7.22244C23.0391 6.53998 22.6248 5.91989 22.1025 5.39756C21.5801 4.87523 20.9601 4.46089 20.2776 4.17821C19.5951 3.89553 18.8637 3.75003 18.125 3.75003H16.25" stroke="currentColor" strokeWidth="1.15385" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.231 19H28.4617" stroke="currentColor" strokeLinecap="round"/>
    <path d="M19.231 22H28.4617" stroke="currentColor" strokeLinecap="round"/>
    <path d="M19.231 25H26.9233" stroke="currentColor" strokeLinecap="round"/>
    </svg>

  )
}