const initialStateFilters = {
  categoria: {},
  ciclo: {id: 1, name: 'Janeiro'},
  compararCiclos: {id: 'ativar', name: 'Ativar'},
  dataInicial: '',
  dataFinal: '',
}

const initialState = {
  projetos: [],
  projeto: {},
  cliente: {},
  graficos: [],
  graficosDefault: [],
  filtersProvisorio: initialStateFilters,
  totalAvaliacao: 0,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LIST_PROJETOS':
      return { ...state, projetos: payload}
    case 'INFO_PROJETO':
      return { ...state, projeto: payload}
    case 'INFO_CLIENTE':
      return { ...state, cliente: payload}
    case 'INFO_FILTERS_INDICADORES':
      return { ...state, filtersProvisorio: payload}
    case 'CHANGE_FILTERS_INDICADORES':
      return { ...state, filtersProvisorio: {...state.filtersProvisorio, [payload.name]: payload.value}}
    case 'CLEAN_FILTERS_INDICADORES':
      return { ...state, filtersProvisorio: initialStateFilters}
    case 'INFO_GRAFICOS_INDICADORES':
      return { ...state, graficos: payload}
    case 'INFO_GRAFICOS_INDICADORES_DEFAULT':
      return { ...state, graficosDefault: payload}
    case 'INFO_GRAFICO_INDICADORES_TOTAL_AVALIACAO':
      return { ...state, totalAvaliacao: payload}

    default:
      return state
  }
}
