import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../layout/redux/layoutActions'
import { Button, MaskValorMoedaex, Modal } from '../../components'
import { CheckoutProjetoProntoXD, cleanProjeto, setProjeto } from './redux/projetosActions'
import { iconeprojeto } from '../meusProjetos/redux/meusProjetos'
import { IF } from '../../helpers'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { projeto } = useSelector(state => state.projetosState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)
  const privado = usuario.uniqueId ? true : false
  
  useEffect(()=> {

  }, [])

  const handleCheckboxSelect = e => {
    const params = {
      id: e.Id,
      idPeriodo: e.valor.idPeriodo,
      moeda: usuario?.moedaIso4217
    }
    dispatch(CheckoutProjetoProntoXD(params, nlsPopup))
  }

  const handleCloseModal = e => {
    dispatch([
      cleanProjeto(),
      modalClose()
    ]) 
  }  

  return (
    <Modal
      name={'modalprojeto'}
      title={<>
      <div className='box-banco-projetos-projeto-topinfo-info'>
        <div>{projeto?.Icone?iconeprojeto(projeto?.Icone):null}</div>
        <div>
          <label>{projeto?.Descricao}</label>
          <small>{projeto?.Segmento}</small>
        </div>
      </div>
      </>}
      size='medium'
      open={statusModal === 'adquirirProjetoModal' ? true : false}
      close={() => handleCloseModal()}
      closeText={nls?.btnFechar}
      actions={<>
        <div className='modalprojeto-valores'>
          {projeto.periodo?.valorDesconto? <small className='valorvelho'>{`${MaskValorMoedaex(projeto.periodo?.valorDesconto, sistema.idioma)}${projeto.periodo?.periodoDesconto?` / ${projeto.periodo?.periodoDesconto}`:''}`}</small> :null}
          <div>
            <h4>{MaskValorMoedaex(projeto.valor?.valor?projeto.valor?.valor:0, sistema.idioma)} / {projeto.valor?.descricao}</h4>
            {projeto.valor?.percentualDesconto?<span className='desconto'>{projeto.valor?.percentualDesconto} {nls.desconto}</span>:null}
          </div>
        </div>
        {privado?
          <Button
            color='primary'
            onClick={()=> usuario?.permissoes?.VerAdquirirProjetoPronto ? handleCheckboxSelect(projeto) : null}
            disabled={!usuario?.permissoes?.VerAdquirirProjetoPronto}
            title={!usuario?.permissoes?.VerAdquirirProjetoPronto ? nls.mensagem.apenasUserMaster : nls.btnAdquirirProjeto}
          >{nls?.btnAdquirirProjeto}</Button>
          :
          <Button
            color='primary'
            onClick={()=> null}
            title={nls.btnCadastrar}
          >{nls.btnCadastrar}</Button>
        }
      </>}
    >
        <div>
          <div>
            <label>{nls.descricao}</label>
            <p>{projeto?.DescricaoLonga}</p>
          </div>
          <div>
            <label>{nls.empresas}</label>
            <p className='modalprojeto-empresas-virgula'>{projeto?.NomeEmpresasProjetoPronto}</p>
            {/* <p className='modalprojeto-empresas-virgula'>{projeto.nomeEmpresasProjetoPronto?.map(e=> <span>{e}</span>)}</p> */}
          </div>
          <div>
            <label>{nls.pais}</label>
            <p>{projeto?.Pais}</p>
          </div>
          <div className='modalprojeto-periodos'>
            <label>{nls.periodo}</label>
            <div>
              {projeto?.valores?.map(e=> {
                if (projeto.valor?.id === e.id) {
                  return <Button key={e.id} color='primary circle' onClick={()=> dispatch(setProjeto({name: 'valor', value: e}))}>{e.descricao}</Button>
                }
                return <Button key={e.id} type='link' onClick={()=> dispatch(setProjeto({name: 'valor', value: e}))}>{e.descricao}</Button>
              })}
            </div>
          </div>
        </div>
    </Modal>
  )
}
