import React from 'react'
import { IcoProjeto1, IcoProjeto2, IcoProjeto3, IcoProjeto4 } from '../../../components'

export const gerarLista = e => {
    const projetoinit = [
        {
            id: "111",
            icone: "icone1",
            nome: "Projeto",
            descricao: "",
            segmento: "",
            tipo: "",
            status: "",
            licenca: ""
        }
    ]  
    return Array(4).fill().map((_, i)=> ({
        id: i+1,
        icone: `projeto${i+1}`,
        nome: `Projeto ${i+1}`,
        descricao: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliq",
        segmento: `Segmento ${i+1}`,
        tipo: `Modelo pronto`,
        status: false,
        licencaData: "2023-12-08T00:09:51.744Z"
    }))
}

export const gerarListaEmpresas = e => {
    return Array(8).fill().map((_, i)=> ({
        id: i+1,
        logo: `logo${i+1}`,
        nome: `Projeto ${i+1}`,
        facebook: `facebook ${i+1}`,
        instagram: `instagram ${i+1}`,
        twitter: `twitter ${i+1}`,
        youtube: `youtube ${i+1}`,
        tiktok: `tiktok ${i+1}`,
        status: true,
        createAt: new Date().toISOString()
    }))
}

export const gerarListaEstabelecimentos = e => {
    return Array(8).fill().map((_, i)=> ({
        id: i+1,
        nome: `nome ${i+1}`,
        empresa: `empresa${i+1}`,
        grupo: `grupo ${i+1}`,
        rua: `rua ${i+1}`,
        bairro: `bairro ${i+1}`,
        cidade: `cidade ${i+1}`,
        estado: `estado ${i+1}`,
        cep: `cep ${i+1}`,
        pais: `pais ${i+1}`,
        latitudeLongitude: `latitudeLongitude ${i+1}`,
        status: true,
        createAt: new Date().toISOString()
    }))
}

export const gerarListaProdutos = e => {
    return Array(8).fill().map((_, i)=> ({
        id: i+1,
        nome: `nome ${i+1}`,
        grupo: `grupo ${i+1}`,
        urlAmazon: `amazon ${i+1}`,
        status: true,
        createAt: new Date().toISOString()
    }))
}

export const paginateInit = e => {
  return { 
    pageNumber: 1, 
    totalPages: 1, 
    totalElements: 0,
    perPage: 7
  }
}


export const iconesList = [
    {
      id: '01.png',
      color: '#7629D7',
      icon: <IcoProjeto1 />,
    },
    {
      id: '02.png',
      color: '#00A28F',
      icon: <IcoProjeto2 />,
    },
    {
      id: '03.png',
      color: '#0077DB',
      icon: <IcoProjeto3 />,
    },
    {
      id: '04.png',
      color: '#D7297D',
      icon: <IcoProjeto4 />,
    }
  ]

export const iconeprojeto = e => {
    let icone = iconesList.find(icon=> icon.id === e)
    if (!icone?.icon) {
      icone = iconesList[0]
    }
    return <div className='iconeprojeto' style={{backgroundColor: icone?.color}}>{icone?.icon}</div>
}


export const typeStatusCustom = e => {
  switch (e) {
    case 'Ativo':
      return {class: 'flag-success', text: e, status: true};
    case 'Active':
      return {class: 'flag-success', text: e, status: true};
    case 'Activo':
      return {class: 'flag-success', text: e, status: true};
    case 'SA':
      return {class: 'flag-success', text: e, status: true};
    case 'SAA':
      return {class: 'flag-warning', text: e, status: true};
    default:
      return {class: 'flag-danger', text: e, status: false};
  }
}