import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, MaskTelefone, MaskTelefoneInt, SelectDefault, verifySelectValue } from '../../components'
import { listarMeusDados, setInfoMeusDados } from './redux/meusDadosActions'

export default () => {
  const dispatch = useDispatch()
  const { erro, meusDados } = useSelector(state => state.meusDadosState)
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const [senhaVisivel, setSenhaVisivel] = useState(false)

  useEffect(()=> {
    dispatch(listarMeusDados())
  }, [])

  const formRequired = {
    nome: '',
    sobrenome: '',
    email: 'email',
    celular: '',
    idioma: ''
  }

  const handleChange = e => {
    dispatch(setInfoMeusDados(e))
  }

  const handleSee = e => {
    setSenhaVisivel(e.value)
  }
  
  return (
    <div className='box-meusdados'>
      <div className='colflex col2'>
        <Input
          name='nome'
          label={nls?.formNome}
          value={meusDados?.nome}
          placeholder={nls?.formNomePH}
          action={(e) => handleChange(e)}
          maxLength={50}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.nome
          }}
        />
        <Input
          name='sobrenome'
          label={nls?.formSobrenome}
          value={meusDados?.sobrenome}
          placeholder={nls?.formSobrenomePH}
          action={(e) => handleChange(e)}
          maxLength={50}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.sobrenome
          }}
        />

        <Input
          name='email'
          label={nls?.formEmail}
          value={meusDados?.email}
          placeholder={nls?.formEmailPH}
          action={(e) => handleChange(e)}
          maxLength={50}
          disabled={true}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.email
          }}
        />
        <Input
          name='celular'
          label={nls?.formCelular}
          value={meusDados?.celular}
          placeholder={nls?.formCelularPH}
          action={(e) => handleChange(e)}
          maxLength={50}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.celular
          }}
        />
        
      </div>
      <div>
        {/* <Input
          name='senha'
          label={nls?.formSenha}
          type={!senhaVisivel?'password':'text'}
          action={(e) => handleChange(e)}
          value={meusDados?.senha}
          placeholder={nls?.formSenhaPH}
          right={
            <Button type='link' onClick={()=> handleSee({name: 'senha', value: !senhaVisivel})}>
              {!senhaVisivel ? <IcoEye />
              : <IcoEyeBlocked />}
            </Button>
          }
          required={{
            erro,
            message: nls.mensagem,
            pattern: formRequired.senha
          }}
        /> */}
        <SelectDefault
          name='idioma'
          label={nls?.formIdioma}
          options={sistema.idiomas}
          selected={verifySelectValue({value: meusDados?.idioma.idiomaId?meusDados?.idioma.idiomaId:meusDados?.idioma.id, list: sistema.idiomas, val: 'idiomaId'})}
          action={(e) => handleChange(e)}
          selectedItem={false}
          optionLabel='descricaoIdioma'
          optionValue='idioma'
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.idioma
          }}
        />
      </div>
    </div>
  )
}