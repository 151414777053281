const initialStateFilters = {
  categoria: {},
  empresa: {},
  ciclo: {id: 1, name: 'Janeiro'},
  compararCiclos: {id: 'ativar', name: 'Ativar'},
  dataInicial: '',
  dataFinal: '',
}
const initialStatePaginate = { 
  pageNumber: 1, 
  totalPages: 1, 
  totalElements: 0,
  perPage: 7
}

const initialState = {
  projetos: [],
  projeto: {},
  cliente: {},
  comentarios: [],
  filtersProvisorio: initialStateFilters,
  paginate: initialStatePaginate,
  totalAvaliacao: 0,
  erroComentario: false,
  sobreComentarios: [{ id: 1, assunto: "Loja", tipo: "lojas" },
  { id: 2, assunto: "Produto", tipo: "produtos" }], 
  sobreComentario: { id: 1, assunto: "Loja", tipo: "lojas" } // Começa com Loja, depois coloca Empresa
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LIST_PROJETOS':
      return { ...state, projetos: payload}
    case 'INFO_PROJETO':
      return { ...state, projeto: payload}
    case 'INFO_CLIENTE':
      return { ...state, cliente: payload}
    case 'INFO_FILTERS_COMENTARIOS':
      return { ...state, filtersProvisorio: payload}
    case 'CHANGE_FILTERS_COMENTARIOS':
      return { ...state, filtersProvisorio: {...state.filtersProvisorio, [payload.name]: payload.value}}
    case 'CLEAN_FILTERS_COMENTARIOS':
      return { ...state, filtersProvisorio: initialStateFilters}
    case 'LIST_COMENTARIOS':
      return { ...state, comentarios: payload}
    case 'PAGINATE_COMENTARIOS':
      return { ...state, paginate: {...initialState.paginate, ...payload}}
    case 'COMENTARIOS_TOTAL_AVALIACAO':
      return { ...state, totalAvaliacao: payload}
    case 'ERRO_COMENTARIO':
        return { ...state, erroComentario: payload}
    case 'CHANGE_SOBRECOMENTARIO':
        return { ...state, sobreComentario: payload}

    default:
      return state
  }
}
