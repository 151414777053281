
export const colors = ['#0077db', '#17d9db', '#00a28f', '#ffb900', '#673AB7', '#61b303']
export const colorsContrast = ['#5695ca', '#62b7b8', '#6ac2b8', '#e0c680', '#9f82d3', '#a2d36a']
export const graficoOptions = {
  colors: colors,
  BarChart: {
    width: 500,
    height: 300,
    margin: {
      top: 40,
      right: 40,
      left: 20,
      bottom: 20,
    }
  },
  CartesianGrid: {
    stroke: '#e6e6e6',
    vertical: false
  },
  Brush:{
    dataKey: 'name',
    height: 30,
    stroke: colors[0],
    startIndex: 0,
    endIndex: 4,
  },
  XAxis: {
    dataKey: 'name',
    fontSize: '12px',
    stroke: '#6c7293',
    axisLine: false,
    tickLine: false,
  },
  YAxis: {
    unit: "%",
    fontSize: '12px',
    stroke: "#6c7293", 
    axisLine: false,
    tickLine: false,
  },
  Legend: {
    align: 'center'
  },
  Bar: {
    radius: [6, 6, 6, 6],
    label: {
      fill: '#6c7293',
      unit: '%',
      fontSize: '11px',
    }
  }
}

//const { sistema } = useSelector(state=> state.layoutState)
//const nls = require(`./nls/${sistema.idioma}.json`)

export const CustomLabel = ({x, y, width, offset, name, value}) => (
  <text name={name} x={x} y={value < 0 ? y + 16 : y} dy={-4} dx={(width - offset) / 2} width={width} fontSize={graficoOptions.Bar.label.fontSize} fill={graficoOptions.Bar.label.fill}  textAnchor="middle">{value}{value?graficoOptions.Bar.label.unit:''}</text>
)

export const CustomTooltip = ({ active, payload, label, labels, msgNA }) => (active && payload && payload.length ? (
  <div className="custom-tooltip">
    <p className="label">{`${label}`}</p>
    {payload.map(({name, value, color}, i)=> {
      const labelItem = labels.filter(f=> f.type === name)
      
      //const infoatual = payload[0].payload.item[0].info;
      //const infoanterior = payload[0].payload.item[1]?.info;
      //console.error('Payload Info atual' + infoatual);
      //console.error('Payload Info anterior' + infoanterior);
      //if(value === 0 && (infoatual === -1 || infoanterior === -1))
      //  value = 'Dado Não disponível'

      if(payload[i].payload.item[i].info === -1)
        value = msgNA;
      // value = 'Dado Não disponível';
      
      return (
      <p className="desc"key={`tooltipcustom-${name}-${i}`}>
        <span style={{backgroundColor: color}}></span> 
        {`${labelItem.length && labelItem[0].name} : ${value}${value !== msgNA ? graficoOptions.Bar.label.unit : ''}`}

      </p>
    )})}
  </div>
):  null)

export const RenderLegend = ({ payload, labels }) => (
  <div className={`custom-legend legend-${graficoOptions.Legend.align}`}>
    {payload.map((entry, i) => {
      const labelItem = labels.filter(f=> f.type === entry.value)
      return (
      <div key={`item-${i}`}>
        <span style={{backgroundColor: entry.color}}></span> 
        {labelItem.length && labelItem[0].name}
      </div>
    )})}
  </div>
)