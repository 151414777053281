import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './projetos.scss';
import { useEffect } from 'react';
import { Checkbox, LoadingContent, RadioButton, Select } from '../../components';
import { listarPaises, listarSegmentos } from '../../layout/redux/layoutActions';
import { listarProjetos, setProjetosFiltro } from './redux/projetosActions';
import { idiomaCustom } from '../../helpers';


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, listas } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { filtros, filtro } = useSelector(state=> state.projetosState)

  useEffect(()=> {
    if (filtro && filtro?.segmentos?.id && filtro?.paises?.idPais ) {//&& usuario?.moedaIso4217
      dispatch(listarProjetos({
        segmentoId: filtro.segmentos.id, 
        paisId: filtro.paises.idPais, 
        moeda: usuario?.moedaIso4217?usuario?.moedaIso4217:sistema?.moeda,
        idiomaId: sistema.idiomaIdValue
      }))
    }
  }, [filtro])

  const paisesLista = [
    ...listas?.paisesIdiomas[idiomaCustom(sistema.idioma)]?listas?.paisesIdiomas[idiomaCustom(sistema.idioma)]:[]
  ]
  
  const sgmentosLista = [
    ...listas?.segmentosIdiomas[idiomaCustom(sistema.idioma)]?listas?.segmentosIdiomas[idiomaCustom(sistema.idioma)]:[]
  ]
  
  useEffect(()=> {
    const paisSelect = paisesLista?.find(f=> f.codigoPais === usuario.codigoPais)
    if (paisesLista.length && paisSelect?.idPais && !filtro.paises.idPais) {
      dispatch(setProjetosFiltro({name: 'paises', value: paisSelect}))
    }
  }, [paisesLista])
  
  useEffect(()=> {
    const segmentoSelect = sgmentosLista?.find(f=> f.id === usuario.segmentoInteresseId)
    if (sgmentosLista.length && segmentoSelect?.id && !filtro.segmentos.id) {
      dispatch(setProjetosFiltro({name: 'segmentos', value: segmentoSelect}))
    }
  }, [sgmentosLista])

  const handleActive = e => {
    dispatch(setProjetosFiltro(e))
  }

  return (
    <div className='box-banco-projetos-filtro'>
      <LoadingContent
        show={false}
      >
        <div>
          <Select
            name='segmentos'
            // label={nls.segmento} 
            options={sgmentosLista}
            selected={filtro.segmentos}
            action={(e)=> handleActive(e)}
            optionLabel='nome'
            optionValue='id' 
            selectedItem={false}
            textCustom={nls.selectSegmento}
          />
          {/* <BoxFiltro 
            name='segmentos' 
            label={nls.segmento} 
            data={listas.segmentosIdiomas[sistema.idioma === 'en-US' ? 'en': sistema.idioma.toLowerCase()]} 
            filtrado={filtro.segmentos} 
            // multi={true} 
            action={(e)=> handleActive(e)}
            optionLabel='nome'
            optionValue='id' 
          /> */}
        </div>
        <div>
          <Select
            name='paises'
            // label={nls.pais} 
            options={paisesLista}
            selected={filtro.paises}
            action={(e)=> handleActive(e)}
            optionLabel='nomePais'
            optionValue='idPais' 
            selectedItem={false}
            textCustom={nls.selectPais}
          />
          {/* <BoxFiltro 
            name={'paises'} 
            label={nls.pais}
            data={paisesLista} 
            filtrado={filtro.paises} 
            action={(e)=> handleActive(e)} 
            optionLabel='nomePais'
            optionValue='idPais' 
          /> */}
        </div>
      </LoadingContent>
    </div>
  )
}


const BoxFiltro = ({
  name, 
  label, 
  data, 
  filtrado, 
  multi=false, 
  optionLabel='name',
  optionValue='id',
  action=()=> null
}) => {
  return <div className='box-filtro'>
    {
      multi? <>
        <Checkbox 
          name={name}
          label={label}
          options={data}
          checked={filtrado}
          optionLabel={optionLabel}
          optionValue={optionValue}
          action={(e, f)=> action(f)}
        />
      </>
      : <RadioButton
         name={name}
         label={label}
         options={data}
         checked={filtrado}
         optionLabel={optionLabel}
         optionValue={optionValue}
         action={(e)=> action(e)}
      />
    }
    
  </div>
}
