import React from 'react'
import { IcoCalendar, IcoCicle } from '../../components'
import { idiomaString } from '../../helpers'

const user = {
  emailUsuario: '',
  nomeEmpresa: '',
  nomeUsuario: '',
  id: '',
  usuarioID: ''
}
const userINSV = localStorage.getItem('userINSV')
const userInfo = JSON.parse(userINSV)

const filteredINSV = localStorage.getItem('filtered')
const initialStateFilters = {
  ciclo: {},
  compararCiclos: {id: 'ativar', name: 'Ativar'},
  dataInicial: null,
  dataFinal: null,
  typeSelect: {
    id: 'Ciclo',
    text: 'Filtrar por Ciclo',
    label: <IcoCicle />
  }, 
  categoria: {},
  empresa: {},
}
const filteredInfo = filteredINSV? {...initialStateFilters, ...JSON.parse(filteredINSV)}:initialStateFilters

export const nomesMeses = [
  {id: 1, name: 'Janeiro'},
  {id: 2, name: 'Fevereiro'},
  {id: 3, name: 'Março'},
  {id: 4, name: 'Abril'},
  {id: 5, name: 'Maio'},
  {id: 6, name: 'Junho'},
  {id: 7, name: 'Julho'},
  {id: 8, name: 'Agosto'},
  {id: 9, name: 'Setembro'},
  {id: 10, name: 'Outubro'},
  {id: 11, name:  'Novembro'},
  {id: 12, name: 'Dezembro'}
]
const initialState = {
  logIn: false,
  load: false,
  sidebarRight: false,
  sidebarLeft: false,
  statusModal: '',
  titlePage: '',
  usuario: userInfo?userInfo:user,
  projetos: [],
  projeto: {},
  cliente: {},
  grupos: [],
  grupo: {},
  empresas: [],
  empresa: {},
  categorias: [],
  categoria: {},
  ciclos: [],
  ciclo: {},

  sistema: {
    idioma: userInfo?.idioma?.nome?idiomaString(userInfo?.idioma?.nome):'en-US',
    idiomas: [
      {id: 'pt-BR', nome: 'Portugês Brasileiro'}
    ],
    paises: [
      {id: 'pt-BR', name: 'Brasil'}
    ],
    pais: {},
    idiomaIdValue: 1,
  },
  
  filters: filteredInfo,
  filterActive: false,
  filtered: {},
  
  listas: {
    listCiclos:[
      {id: 1, name: 'Janeiro'},
      {id: 2, name: 'Fevereiro'}
    ],
    nomesMeses: nomesMeses,
    typeSearch:[
      {
        id: 'Ciclo',
        text: 'Filtrar por Ciclo',
        label: <IcoCicle />
      },
      {
        id: 'Data',
        text: 'Filtrar por Data',
        label: <IcoCalendar />
      }
    ],

    segmentos: [],
    segmentosIdiomas: {},
    paises: [],
    paisesIdiomas: {},
  },
  showNotificacoes: false,
  notificacoes: [],
  alerta: {
    show: false,
  },
  erro: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LOADING':
      return { ...state, load: payload }
    case 'LOG_IN':
      return { ...state, logIn: payload }
    case 'GET_SISTEMA':
      return { ...state, sistema: {...state.sistema, [payload.name]: payload.value} }
    case 'USUARIO_LOGADO_CARREGAR':
      return { ...state, usuario: payload }
    case 'LIST_PROJETOS':
      return { ...state, projetos: payload}
    case 'INFO_PROJETO':
      //console.log('XXLAYOUTPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      return { ...state, projeto: payload }
    case 'INFO_CLIENTE':
      return { ...state, cliente: payload }
    case 'LIST_GRUPOS':
      return { ...state, grupos: payload }
    case 'INFO_GRUPO':
      return { ...state, grupo: payload }
    case 'LIST_EMPRESAS':
      return { ...state, empresas: payload }
    case 'INFO_EMPRESA':
      return { ...state, empresa: payload }
    case 'LIST_CATEGORIAS':
      return { ...state, categorias: payload }
    case 'INFO_CATEGORIA':
      return { ...state, categoria: payload }
    case 'LIST_CICLOS':
      return { ...state, ciclos: payload }
    case 'INFO_CICLO':
      return { ...state, ciclo: payload }
      
    case 'SIDEBAR_LEFT':
      return { ...state, sidebarLeft: payload }
    case 'SIDEBAR_RIGHT':
      return { ...state, sidebarRight: payload }
    case 'MODAL_OPEN':
      return { ...state, statusModal: payload }
    case 'TITLE_PAGE':
      return { ...state, titlePage: payload }
    case 'VALIDACAO':
      return { ...state, erro: payload }
      
    case 'INFO_FILTERED':
      return { ...state, filters: payload}
    case 'INFO_FILTERS':
      return { ...state, filters: {...state.filters, [payload.name]: payload.value}}
    case 'INFO_FILTERS_HAS':
      return { ...state, filters: {...state.filters, ...Object.keys(state.filters[payload.name]).length === 0 ? {[payload.name]: payload.value}:{}}}
    case 'INFO_FILTERS_ACTIVE':
      return { ...state, filterActive: payload}
    case 'INFO_FILTERS_CLEAN':
      return { ...state, filters: initialStateFilters, filterActive: false}


      
    case 'SET_LISTAS_DATA':
      return { ...state, listas: {...state.listas, [payload.name]: payload.value}}
      
    case 'SET_SHOW_NOTIFICACOES':
      return { ...state, showNotificacoes: payload}
    case 'GET_NOTIFICACOES':
      return { ...state, notificacoes: payload}
    case 'DELETE_NOTIFICACOES':
      return { ...state, notificacoes: []}
    case 'DELETE_NOTIFICACAO':
      return { ...state, notificacoes: state.notificacoes.filter(e=> e.idNotificao !== payload)}

    case 'MODAL_ALERTA_INFO':
      return { ...state, alerta: {show: true, ...payload}}
    case 'MODAL_ALERTA_CLEAN':
      return { ...state, alerta: initialState.alerta}

    default:
      return state
  }
}