import { useState, useRef, useEffect, ReactNode } from 'react';
import useOutsideClick from '../../useOusideClick/useoutsideclick';
import { IcoClose, IcoSearch } from '../../icon/icon';
import { validarCampo } from '../../validation/Validation';
import Portal from '../../portal/portal';
import { Button } from '../../index';
import { numberAleatori } from '../../../helpers';

const chargeDefault = { max: 0, text: 'Mais itens', action: () => null };
const textCustomDefault = {selecione: 'Selecione', selecionado: 'Selecionado',  selecionados: 'Selecionados',  todos: 'Marcar todos'}
export const SelectDefault = ({
  options,
  action,
  actionClose,
  actionFilter,
  selected,
  label,
  name,
  color = '',
  closeOnSelect = null,
  multiSelect = false,
  disabled = false,
  selectedItem = true,
  textCustom = textCustomDefault,
  filter,
  charge = chargeDefault,
  optionLabel = 'name',
  optionValue = 'id',
  optionCustom,
  labelCustom,
  required,
  ...props
}) => {
  const selectAction = (e) => {
    const resp = options.find(option=> String(option[optionValue]) === String(e.target.value) )
    action({ name: name, value: resp?resp:{}, type: 'select', pattern: required.pattern });
  };


  const require = required ? Object.keys(required) : undefined;
  return (
    <div
      className={`form-box form-select-box ${color} ${
        require && (required && (required.erro[name] ? 'erro' : ''))
      } `}
    >
      {label ? (
        <label className="label-input" htmlFor={`id-${name}`}>
          {require ? <span className='required-label'>*</span> : ''} {label}
        </label>
      ) : null}
      <div>
        <select {...props} value={selected[optionValue]} onChange={(e)=> selectAction(e)} disabled={disabled}>
          {
            selectedItem?
            <option value={''}>{textCustom.selecione}</option>
            :null
          }
          {
            options?.map(opt=> {
              return <option value={opt[optionValue]} className='select-option' key={numberAleatori()} >{opt[optionLabel]}</option>
            })
          }
        </select>
      </div>

      {required?.erro?.[name] ? (
        <span className="campo-obrigatorio">{required.message}</span>
      ) : null}
    </div>
  );
};