import axios from 'axios';

const apiIp = axios.create({
  baseURL: `${process.env.REACT_APP_INSV_API_BASE}`
});
apiIp.interceptors.request.use(async (config) => {
  const token = localStorage.tolkenINSV;
  
  if (token)
    config.headers.authorization = `${token}`;

    // config.headers.accept = `*/*`;
    config.headers.Senha = `${process.env.REACT_APP_INSV_SENHA_IP}`;

  return config;
});

apiIp.interceptors.response.use(
  (response) => {
    return response;
  },
  
  (err) => Promise.reject(err)
);

export default apiIp