import React from 'react';

const Plano7diasContent = () => {
  return (
    <div className="plano7dias-content">
      <h2 className="plano7dias-content-title"><strong>Resumo das Experiências de Compra dos Clientes</strong></h2>
      <p className="plano7dias-content-text">Os comentários dos clientes destacam que o Shopping Eldorado é elogiado por sua história e tradição, sendo considerado um local agradável e completo para passeios e compras. Muitos clientes mencionam a variedade de lojas, a praça de alimentação e a infraestrutura do shopping, incluindo o cinema e a facilidade de acesso. No entanto, há reclamações recorrentes sobre o valor elevado do estacionamento, a confusão no layout do shopping e a experiência negativa com a segurança em algumas situações.</p>
      
      <h2 className="plano7dias-content-title"><strong>Plano de Ação</strong></h2>
      
      <table className="plano7dias-content-table">
        <thead>
          <tr>
            <th>Número</th>
            <th>Descrição</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Rever a política de preços do estacionamento, considerando opções mais acessíveis para os clientes, especialmente nos finais de semana.</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Realizar uma revisão no layout do shopping, buscando simplificar a navegação e tornar mais claro o direcionamento para as diferentes áreas e serviços.</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Investir em treinamento e protocolos para a equipe de segurança, visando garantir uma experiência positiva e acolhedora para todos os clientes.</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Implementar painéis informativos em cada andar, incluindo os subsolos, para facilitar a localização das lojas e serviços.</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Oferecer promoções e descontos especiais para o estacionamento, incentivando a frequência dos clientes e tornando o custo mais atrativo.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Plano7diasContent;
