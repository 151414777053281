import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../../components'
import './rankingcsat.scss'
import Filter from './filter/filter'
import Grafico from './grafico'
import { carregarGrafico, filterHandle } from './redux/rankingcsatActions'
import { setInfoGrupo, setProjeto } from '../../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const { grupos, grupo, filters } = useSelector(state=> state.layoutState)
  const { projeto, totalAvaliacao, projetos, filtersProvisorio } = useSelector(state=> state.rankingcsatState)

  useEffect(()=> {
    if (projeto.id) {
      if (filters.typeSelect) {
        dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect}))
      } else {
        dispatch(carregarGrafico({projetoID: projeto.id, grupo, params: {TipoBusca: 'Ciclo', MesCliclo: '2023-01', Comparacao: true}}))
      }
    }
  }, [projeto, grupo])

  const handleFormFilter = (e) => {
    dispatch(setProjeto(e.value))
  }
  const handleGrupo = (e) => {
    dispatch(setInfoGrupo(e.value))
  }
  
  return (
    <div className='box-rankingcsat'>
      <div className='box-rankingcsat-topinfo'>
        <div>
          <div className='box-rankingcsat-topinfo-select'>
            <Select 
              name='projeto'
              label='Projeto:'
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormFilter(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          <div className='box-rankingcsat-topinfo-select'>
            {/* <label>Cliente: </label> 
            <span> {projeto?.empresa}</span> */}
          </div>
          <div className='box-rankingcsat-topinfo-select'>
            <Select 
              name='grupo'
              label='Grupo:'
              options={grupos}
              selected={grupo}
              action={(e)=> handleGrupo(e)}
              selectedItem={false}
            />
          </div>
        </div>
        <div className='box-rankingcsat-topinfo-avaliacao'>
            <label>Número de avaliações:</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <Grafico />
    </div>
  )
}
