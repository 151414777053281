import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../../components'
import './swot.scss'
import Filter from './filter/filter'
import { setInfoEmpresa, setInfoGrupo, setProjeto } from '../../../layout/redux/layoutActions'
import SwotContent from './swotContent'
import { useEffect } from 'react'
import { filterHandle, listarSwots } from './redux/swotActions'

export default () => {
  const dispatch = useDispatch()
  const { grupos, grupo, filters, empresas, empresa, sistema } = useSelector(state=> state.layoutState)
  const { projeto, filtersProvisorio, projetos, paginate, totalAvaliacao } = useSelector(state=> state.swotStateProdutos)

  useEffect(()=> {
    if (projeto.id) {
        dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate, idioma: sistema.idiomaIdValue}))
    }
  }, [projeto, grupo, empresa])

  const handleFormProjeto = (e) => {
    dispatch(setProjeto(e.value))
  }
  const handleGrupo = (e) => {
    dispatch(setInfoGrupo(e.value))
  }

  const handleFormFilterEmpresa = (e) => {
    dispatch(setInfoEmpresa(e.value))
  }
  
  
  return (
    <div className='box-swot'>
      <div className='box-swot-topinfo'>
        <div>
          <div className='box-swot-topinfo-select'>
            <Select 
              name='projeto'
              label='Projeto:'
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormProjeto(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          <div className='box-swot-topinfo-select'>
            <Select 
              name='empresa'
              label='Empresa analisada:'
              options={empresas}
              selected={empresa}
              action={(e)=> handleFormFilterEmpresa(e)}
              selectedItem={false}
              optionLabel='descricaoEmpresa'
              optionValue='empresaId'
            />
          </div>
          <div className='box-swot-topinfo-select'>
            <Select 
              name='grupo'
              label='Grupo:'
              options={grupos}
              selected={grupo}
              action={(e)=> handleGrupo(e)}
              selectedItem={false}
            />
          </div>
        </div>
        <div className='box-swot-topinfo-avaliacao'>
            <label>Número de avaliações:</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <SwotContent />
    </div>
  )
}
