import React from 'react'

export const IcoProjeto3 = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-projeto' viewBox="0 0 17 17" {...props} style={{...style}}>
    <path d="M1.5 7.05467V8.25C1.5 8.94067 2.06 9.5 2.75 9.5H14.25C14.94 9.5 15.5 8.94 15.5 8.25V7.05467C15.5 6.57735 15.3293 6.11578 15.0187 5.75333L12.8287 3.19867C12.6409 2.97955 12.408 2.80365 12.1458 2.68306C11.8837 2.56246 11.5986 2.50001 11.31 2.5H5.69C5.40145 2.50001 5.11631 2.56246 4.85417 2.68306C4.59203 2.80365 4.35909 2.97955 4.17133 3.19867L1.98133 5.75333C1.67074 6.11578 1.50002 6.57735 1.5 7.05467ZM5.69 3.5C5.54572 3.5 5.40316 3.53123 5.27209 3.59153C5.14101 3.65183 5.02454 3.73978 4.93067 3.84933L3.08733 6H5.19733C5.52658 6.00002 5.85073 6.08133 6.141 6.2367C6.43128 6.39208 6.67871 6.61671 6.86133 6.89067L6.97067 7.05467C7.06198 7.19164 7.18569 7.30396 7.33083 7.38165C7.47597 7.45933 7.63804 7.49999 7.80267 7.5H9.19733C9.36196 7.49999 9.52403 7.45933 9.66917 7.38165C9.81431 7.30396 9.93802 7.19164 10.0293 7.05467L10.1387 6.89067C10.3213 6.61671 10.5687 6.39208 10.859 6.2367C11.1493 6.08133 11.4734 6.00002 11.8027 6H13.9127L12.0693 3.84933C11.9755 3.73978 11.859 3.65183 11.7279 3.59153C11.5968 3.53123 11.4543 3.5 11.31 3.5H5.69Z"/>
    <path d="M2.37533 10.5C1.892 10.5 1.5 10.892 1.5 11.3753V12.5C1.5 13.0304 1.71071 13.5391 2.08579 13.9142C2.46086 14.2893 2.96957 14.5 3.5 14.5H13.5C14.0304 14.5 14.5391 14.2893 14.9142 13.9142C15.2893 13.5391 15.5 13.0304 15.5 12.5V11.3747C15.5 10.892 15.108 10.5 14.6247 10.5H11.8027C11.4734 10.5 11.1493 10.5813 10.859 10.7367C10.5687 10.8921 10.3213 11.1167 10.1387 11.3907L10.0293 11.5547C9.93802 11.6916 9.81431 11.804 9.66917 11.8816C9.52403 11.9593 9.36196 12 9.19733 12H7.80267C7.63804 12 7.47597 11.9593 7.33083 11.8816C7.18569 11.804 7.06198 11.6916 6.97067 11.5547L6.86133 11.3907C6.67871 11.1167 6.43128 10.8921 6.141 10.7367C5.85073 10.5813 5.52658 10.5 5.19733 10.5H2.37533Z" /> 
    </svg>
  )
}