import React from 'react'

export const IcoEqualizer = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-equalizer' viewBox="0 0 27 24" {...props} style={{...style,fill:'none'}}>
      <path d="M11.3125 3.25H25.5313M11.3125 3.25C11.3125 3.83016 11.082 4.38656 10.6718 4.7968C10.2616 5.20703 9.70516 5.4375 9.125 5.4375C8.54484 5.4375 7.98844 5.20703 7.5782 4.7968C7.16797 4.38656 6.9375 3.83016 6.9375 3.25M11.3125 3.25C11.3125 2.66984 11.082 2.11344 10.6718 1.7032C10.2616 1.29297 9.70516 1.0625 9.125 1.0625C8.54484 1.0625 7.98844 1.29297 7.5782 1.7032C7.16797 2.11344 6.9375 2.66984 6.9375 3.25M6.9375 3.25H1.46875M11.3125 20.75H25.5313M11.3125 20.75C11.3125 21.3302 11.082 21.8866 10.6718 22.2968C10.2616 22.707 9.70516 22.9375 9.125 22.9375C8.54484 22.9375 7.98844 22.707 7.5782 22.2968C7.16797 21.8866 6.9375 21.3302 6.9375 20.75M11.3125 20.75C11.3125 20.1698 11.082 19.6134 10.6718 19.2032C10.2616 18.793 9.70516 18.5625 9.125 18.5625C8.54484 18.5625 7.98844 18.793 7.5782 19.2032C7.16797 19.6134 6.9375 20.1698 6.9375 20.75M6.9375 20.75H1.46875M20.0625 12H25.5313M20.0625 12C20.0625 12.5802 19.832 13.1366 19.4218 13.5468C19.0116 13.957 18.4552 14.1875 17.875 14.1875C17.2948 14.1875 16.7384 13.957 16.3282 13.5468C15.918 13.1366 15.6875 12.5802 15.6875 12M20.0625 12C20.0625 11.4198 19.832 10.8634 19.4218 10.4532C19.0116 10.043 18.4552 9.8125 17.875 9.8125C17.2948 9.8125 16.7384 10.043 16.3282 10.4532C15.918 10.8634 15.6875 11.4198 15.6875 12M15.6875 12H1.46875" stroke='currentColor' strokeWidth="2" />
    </svg>

  )
}