import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoPaste, IcoUpload, Input, InputFile, Modal, Select, validacaoForm, verificaValidacao } from '../../../../components'
import { history, idiomaCustom, numberAleatori } from '../../../../helpers'
import { criarEstabelecimento, editarEstabelecimento, putListMeuProjetoTab, setListMeuProjetoTab, setMeusProjetosLoad } from '../../redux/meusProjetosActions'
import { GruposEstabelecimentos } from './grupos'

const formInit = { 
  nomeEstabelecimento: '', 
  nomeEmpresa: {}, 
  grupoEstabelecimento: {}, 
  ruaAvenidaRodovia: '', 
  bairro: '', 
  cidade: '', 
  estado: '', 
  cep: '', 
  pais: '', 
  urlTripAdvisorEstabelecimento: '', 
  complemento: '',
  bairro: '',
  coordenadas: '',
}

export default ({data}) => {
  const dispatch = useDispatch()
  const { listas, sistema, statusModal } = useSelector(state => state.layoutState)
  const { load, meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    if (statusModal === 'criarEstabelecimentosMeuProjeto') {
      let infoInit = formInit
      const selectEmpresa = meuProjeto.empresas?.find(e=> e.nomeEmpresa === data?.nomeEmpresa)
      const selectgrupo = meuProjeto.gruposEstabelecimentos?.find(e=> e.nomeGrupoEstabelecimento === data?.grupoEstabelecimento)
  
      if (data?.idEstabelecimento) {
        infoInit = {
          ...formInit,
          ...data,
          nomeEmpresa: data?.nomeEmpresa?selectEmpresa:{}, 
          grupoEstabelecimento: data?.grupoEstabelecimento?selectgrupo:{}, 
          pais: data?.país?data?.país:'', 
          urlTripAdvisorEstabelecimento: data?.urlTripAdvisorEstabelecimento?data?.urlTripAdvisorEstabelecimento:'', 
        }
      }
  
      setForm(infoInit)
    }
  }, [data])
  
  const formRequired = {
    nomeEstabelecimento: '',
    nomeEmpresa: 'select',
    grupoEstabelecimento: 'select',
    ruaAvenidaRodovia: '',
    numero: '',
    cidade: '',
    estado: '',
    cep: '',
    pais: '',
  }

  const handleChange = (e, v) => {
    setForm({...form, [e.name]: e.value})
    if (v?.name) {
      verifyErro(v)
    }
  } 

  const verifyErro = (e) => {
    setErro({...erro, [e.name]: e.message})
  } 

  const handleChangeFile = e => {
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom

    const valid = validacaoForm({formValues: e, formRequired})
    setErro(valid)
    const verify = verificaValidacao(valid)
    if (verify.value) {
      if (data?.idEstabelecimento) {
        dispatch(editarEstabelecimento({...e, projetoId: meuProjeto.projeto.idProjeto}))    
      } else {
        dispatch(criarEstabelecimento({...e, projetoId: meuProjeto.projeto.idProjeto}))
      }
    }
  }  

  const handleCloseModal = e => {
    setForm(formInit)
    dispatch(modalClose()) 
  }  

  const handleColar = e => {
    navigator.clipboard.readText().then((clipText) => {
      setForm({...form, [e]: clipText})
    });
  }  

  const handleAddForm = e => {
    dispatch(setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, add: !load.estabelecimentosAddEdit?.add}}))
  }  
  
  return (
    <Modal
      title={data?.idEstabelecimento?nls.configurar?.editEstabelecimentoTitle:nls.configurar?.addEstabelecimentoTitle}
      size='medium'
      open={statusModal === 'criarEstabelecimentosMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.id? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.configurar?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-estabelecimentos-add'>
        <h5>{nls.configurar?.subTitleDetalhes}</h5>
        <div className='colflex col2'>
          <Input
            name='nomeEstabelecimento'
            label={nls.configurar?.formNome}
            value={form?.nomeEstabelecimento}
            placeholder={nls.configurar?.formNomePH}
            action={(e, v) => handleChange(e, v)}
            required={{
              erro,
              pattern: formRequired.nomeEstabelecimento,
              message: nls.mensagem.obrigatorio
            }}
          />
          <Select
            name='nomeEmpresa'
            label={nls.configurar?.formEmpresa}
            options={meuProjeto.empresas}
            selected={form?.nomeEmpresa?form?.nomeEmpresa:{}}
            action={(e, v) => handleChange(e, v)}
            actionClose={(e, v) => verifyErro(v)}
            disabled={(data?.idEstabelecimento) ? true : false}
            textCustom={nls.select}
            optionLabel='nomeEmpresa'
            optionValue='empresaId'
            required={{
              erro,
              pattern: formRequired.nomeEmpresa,
              message: nls.mensagem.obrigatorio
            }}
          />
        </div>
        <div className='colflex justify'>
          <div className='colflex-100'>
            <Select
              name='grupoEstabelecimento'
              label={nls.configurar?.formGrupo}
              options={meuProjeto.gruposEstabelecimentos}
              selected={form?.grupoEstabelecimento?form?.grupoEstabelecimento:{}}
              action={(e, v) => handleChange(e, v)}
              actionClose={(e, v) => verifyErro(v)}
              textCustom={nls.select}
              optionLabel='nomeGrupoEstabelecimento'
              optionValue='idGrupoEstabelecimento'
              required={{
                erro,
                pattern: formRequired.grupoEstabelecimento,
                message: nls.mensagem.obrigatorio
              }}
            />
          </div>
          <div>
            <GruposEstabelecimentos
              show={load?.estabelecimentosAddEdit?.add}
              id={'add'}
              actionOpen={
                <Button
                  color='default circle'
                  onClick={()=> handleAddForm()}
                ><IcoAdd /></Button>
              }
            />
          </div>
        </div>
        <h5>{nls.configurar?.subTitleEndereco}</h5>
        <div className='colflex col2'>
          <Input
            name='ruaAvenidaRodovia'
            label={nls.configurar?.formRua}
            value={form?.ruaAvenidaRodovia}
            placeholder={nls.configurar?.formRuaPH}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            action={(e, v) => handleChange(e, v)}
            required={{
              erro,
              pattern: formRequired.ruaAvenidaRodovia,
              message: nls.mensagem.obrigatorio
            }}
          />
          <Input
            name='numero'
            type='number'
            label={nls.configurar?.formNumero}
            value={form?.number}
            placeholder={nls.configurar?.formNumeroPH}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            action={(e, v) => handleChange(e, v)}
            required={{
              erro,
              pattern: formRequired.numero,
              message: nls.mensagem.obrigatorio
            }}
          />
          <Input
            name='complemento'
            label={nls.configurar?.formComplemento }
            value={form?.number}
            placeholder={nls.configurar?.formComplementoPH}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            action={(e, v) => handleChange(e, v)}
          />
          <Input
            name='bairro'
            label={nls.configurar?.formBairro}
            value={form?.bairro}
            placeholder={nls.configurar?.formBairroPH}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            action={(e, v) => handleChange(e, v)}
          />

          <Input
            name='cidade'
            label={nls.configurar?.formCidade}
            value={form?.cidade}
            placeholder={nls.configurar?.formCidadePH}
            action={(e, v) => handleChange(e, v)}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            required={{
              erro,
              pattern: formRequired.cidade,
              message: nls.mensagem.obrigatorio
            }}
          />
          <Input
            name='estado'
            label={nls.configurar?.formEstado}
            value={form?.estado}
            placeholder={nls.configurar?.formEstadoPH}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            action={(e, v) => handleChange(e, v)}
            required={{
              erro,
              pattern: formRequired.estado,
              message: nls.mensagem.obrigatorio
            }}
          />

          <Input
            name='cep'
            label={nls.configurar?.formCep}
            value={form?.cep}
            placeholder={nls.configurar?.formCepPH}
            action={(e, v) => handleChange(e, v)}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            required={{
              erro,
              pattern: formRequired.cep,
              message: nls.mensagem.obrigatorio
            }}
          />
          <Input
            name='pais'
            label={nls.configurar?.formPais}
            value={form?.pais}
            placeholder={nls.configurar?.formPaisPH}
            action={(e, v) => handleChange(e, v)}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            required={{
              erro,
              pattern: formRequired.pais,
              message: nls.mensagem.obrigatorio
            }}
          />
          {/* <Select
            name='país'
            label={nls.configurar?.formPais}
            options={listas.paises}
            selected={form?.país?form?.país:{}}
            action={(e, v) => handleChange(e, v)}
            textCustom={nls.select}
            optionLabel='name'
            optionValue='id'
            required={{
              erro,
              message: nls.mensagem.obrigatorio
            }}
          /> */}
        </div>
        <div className='colflex col1'>
          <Input
            name='coordenadas'
            label={nls.configurar?.formCordenadas}
            value={form?.coordenadas}
            placeholder={nls.configurar?.formCordenadasPH}
            disabled={(data?.podeSerEditado === 2) ? true : false}
            action={(e, v) => handleChange(e, v)}
          />
        </div>
        <h5>{nls.configurar?.subTitleRedesSociais}</h5>
        <div>
          <Input
            name='urlTripAdvisorEstabelecimento'
            label={nls.configurar?.formTripAdvisor}
            value={form?.urlTripAdvisorEstabelecimento}
            placeholder={nls.configurar?.formTripAdvisorPH}
            action={(e, v) => handleChange(e, v)}
            disabled={(data?.podeSerEditado === 2 && data?.urlTripAdvisorEstabelecimento) ? true : false}
            right={<>
              <span className='btn-colar' onClick={()=> handleColar('urlTripAdvisorEstabelecimento')}><IcoPaste /> {nls.configurar?.btnColar}</span>
            </>}
          />
        </div>
      </div>
    </Modal>
  )
}