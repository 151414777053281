import { useEffect } from 'react';

export const Wizard = ({
  percent=0,
}) => {
  const handleClick = (e) => {
    
  };
  
  return (
    <div className='box-wizard'>
      <div className='box-wizard-base'></div>
      {percent ? <div className='box-wizard-bar' style={{width: `${percent}%`}}></div> : null}
    </div>
  )
};
