import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoBin, IcoPencil, Input, List, Modal, useOutsideClick } from '../../../../components'
import { numberAleatori } from '../../../../helpers'
import { criarGrupoEstabelecimento, removerGrupoEstabelecimento, setFormProjetoLista, setMeusProjetosLoad } from '../../redux/meusProjetosActions'
import Portal from '../../../../components/portal/portal'

export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { load, meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)

  const headerDefault = [
    {
      column: 'nomeGrupoEstabelecimento',
      text: nls?.configurar.tableNome,
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleEditForm = e => {
    dispatch(setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, [`edit${e.idGrupoEstabelecimento}`]: !load.estabelecimentosAddEdit?.[`edit${e.idGrupoEstabelecimento}`]}}))
  } 

  const handleAddForm = e => {
    dispatch(setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, add: !load.estabelecimentosAddEdit?.add}}))
  } 

  const handleRemove = e => {
    dispatch(removerGrupoEstabelecimento({...e, idProjeto: meuProjeto.projeto.idProjeto}))
  } 

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  

  return (
    <Modal
      title={nls.configurar?.gruposEstabelecimentoTitle}
      size='medium'
      open={statusModal === 'gruposEstabelecimentoMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.criarProjeto?.btnFechar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-grupos-add'>
        <h5>{nls.configurar?.addEmpresaDetalhes}</h5>
        
        <div className='colflex justify'>
          <div>
            <h5>{nls.configurar?.subTitleListaGrupos}</h5>
          </div>
          <div>
              <GruposEstabelecimentos 
                show={load?.estabelecimentosAddEdit?.add}
                id={'add'}
                actionOpen={
                  <Button
                    color='primary circle'
                    onClick={()=> handleAddForm()}
                  ><IcoAdd /></Button>
                }
              />
          </div>
        </div>
        <div className='colflex'>
          <List
            header={headerDefault}
            data={meuProjeto.gruposEstabelecimentos}
            listCustom={e=> {
              const item = e

              item.actions = <>
                {/* <GruposEstabelecimentos 
                  show={load?.estabelecimentosAddEdit?.[`edit${e.id}`]}
                  info={e} 
                  id={e.id}
                  actionOpen={
                    <Button
                      color='primary circle'
                      onClick={()=> handleEditForm(e)}
                    ><IcoPencil /></Button>
                  }
                /> */}
                <Button
                  color='primary circle'
                  onClick={()=> handleRemove(e)}
                ><IcoBin /></Button>
              </>

              return item
            }}
            paginate={false}
            actionPaginate={(e)=> null}
            handlePerPage={(e)=> null}
            nls={nls.paginacao}
          />
        </div>
      </div>
    </Modal>
  )
}


export const GruposEstabelecimentos = ({show = false, actionOpen, info={}, id=numberAleatori()}) => {
  const dispatch = useDispatch()
  const { sistema } = useSelector(state => state.layoutState)
  const { load, meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({nome: ''})
  const [selectCoordinates, setSelectCoordinates] = useState({});

  useEffect(()=> {
    setForm({...form, ...info})
  }, [])
  
  useEffect(()=> {
    if (show) {
      const elem = document.getElementById(`grupo-estabelecimento-${id}`)
      openSelect(elem)
    }
  }, [form.id, show])
  
  const handleChange = e => {
    if (!e.value && form.id) {
      const list = meuProjeto.gruposEstabelecimentos.find(g=> Number(g.id) === Number(form.id))
      e.value = list.nome
    }
    setForm({...form, [e.name]: e.value})
  } 

  const handleAdd = e => {
    if (e.nome) {
      setForm({nome: ''})
      dispatch(criarGrupoEstabelecimento({...e, projetoId: meuProjeto.projeto.idProjeto}))
      dispatch([
        // setFormProjetoLista({name: 'gruposEstabelecimentos', value: [...meuProjeto.gruposEstabelecimentos, {...e, id: numberAleatori()}]}),
        setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, add: false}})
      ])
    }
  } 

  const handleEdit = e => {
    if (e.nome) {
      const list = meuProjeto.gruposEstabelecimentos.map(g=> Number(g.id) === Number(e.id) ? {...g, ...e} : g)
      setForm({nome: ''})
      dispatch([
        setFormProjetoLista({name: 'gruposEstabelecimentos', value: list}),
        setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, [`edit${e.id}`]: false}})
      ])
    }
  } 
  
  const openSelect = (elem) => {
    if (elem) {
      let div = elem;
      let rect = div.getBoundingClientRect(),
        eixoX = rect.left,
        eixoY = rect.top,
        width = rect.width,
        height = rect.height;

      // let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let coor = {};
      if (Math.ceil(eixoY + height) + 300 >= windowHeight) {
        coor = { left: eixoX - (300 - width), bottom: windowHeight - eixoY, top: 'auto' };
      } else {
        coor = { left: eixoX - (300 - width), top: eixoY + height + 1 };
      }
      setSelectCoordinates({ ...coor, position: 'absolute', minWidth: width, width: 'auto', display: 'block' });
    } else {
      setSelectCoordinates({})
    }
  };

  const handleClose = e => {
    dispatch(setMeusProjetosLoad({name: 'estabelecimentosAddEdit', value: {...load.estabelecimentosAddEdit, [form.id?`edit${form.id}`:'add']: false}}))
  }

  return <div id={`grupo-estabelecimento-${id}`} className='action-grupo'>
    {actionOpen}
    <Portal name={'box-addedit-grupo'}>
      {show? (
        <div className={`box-addedit-grupo ${id}`} style={selectCoordinates}>
          <div className='modal-meuProjeto-grupos-add-form'>
            <div>
              <Input
                name='nome'
                label={''}
                value={form?.nome}
                placeholder={nls.configurar?.formNomeGrupoPH}
                action={(e) => handleChange(e)}
                required={{
                  erro,
                  message: ''
                }}
              />
            </div>
            <div>
              <Button
                color='default'
                onClick={()=> handleClose(form)}
              >{nls?.configurar.btnCancelar}</Button>
              <Button
                color='primary'
                onClick={()=> form.id?handleEdit(form): handleAdd(form)}
              >{form.id?nls?.configurar.btnEditar:nls?.configurar.btnAdicionar}</Button>
            </div>
          </div>
        </div>
      ):null}
    </Portal>
  </div>
}