import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../layout/redux/layoutActions'
import { Button, IcoArchive1, IcoBancoProjetos, Modal } from '../../../components'
import { history, numberAleatori } from '../../../helpers'
import CriarContent from './criar'
import { criarProjeto, setFormProjeto, setListMeuProjeto } from '../redux/meusProjetosActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  
  const typesAction = [
    {
      id: 'Personalizado',
      text: nls.criarProjeto?.personalizado,
      icon: <IcoArchive1 />,
    },
    {
      id: 'Bancodeprojetos',
      text: nls.criarProjeto?.bancoProjetos,
      icon: <IcoBancoProjetos />,
    }
  ]

  const handleType = e => {
    dispatch(setFormProjeto({name: 'tipo', value: e.text, tipo: 'projeto'}))
    if (e.id === 'Personalizado') {
      dispatch(modalOpen('criarMeuProjeto'))
    } else {
      history.push('/projetos')
    }
  }  

  const handlerConfigurar = e => {
    
  } 

  const handlerCriar = e => {
    dispatch(criarProjeto(e))
  }  

  return (
    <>
      <Modal
        title={<span></span>}
        size='small'
        open={statusModal === 'criarMeuProjetoTipo' ? true : false}
        close={() => dispatch(modalClose())}
        closeText={nls.criarProjeto?.btnFechar}
      >
        <div className='modal-criarMeuProjeto'>
          <h3>{nls.criarProjeto.titleTipo}</h3>
          <div className='type-btns'>
            {typesAction.map(e=> {
              return <div key={`types-${e.id}`} onClick={()=> handleType(e)}>
                <div>{e.icon}</div>
                <span>{e.text}</span>
              </div>
            })}
          </div>
        </div>
      </Modal>
      <Modal
        title={nls.criarProjeto?.titleCriar}
        size='medium'
        open={statusModal === 'criarMeuProjeto' ? true : false}
        close={() => dispatch(modalClose())}
        closeText={nls.criarProjeto?.btnFechar}
        actions={<>
          <Button
            onClick={()=> handlerConfigurar(meuProjeto.projeto)}
          >{nls.criarProjeto?.btnConfiguracao}</Button>
          <Button
            color='primary'
            onClick={()=> handlerCriar(meuProjeto.projeto)}
          >{nls.criarProjeto?.btnCriar}</Button>
        </>}
      >
        <CriarContent />
      </Modal>
    </>
  )
}
