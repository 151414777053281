import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputDateRange } from '../../../../components'
import { putFiltersProvisorio } from '../redux/csatconcorrentesActions'

export default () => {
  const dispatch = useDispatch()
  const { filtersProvisorio: {dataInicial, dataFinal} } = useSelector(state=> state.csatconcorrentesState)

  const handleFormFilter = ({ startDate, endDate }) => {
    if (startDate) {
      dispatch(putFiltersProvisorio({ name: 'dataInicial', value: startDate }))
    }
    if (endDate) {
      dispatch(putFiltersProvisorio({ name: 'dataFinal', value: endDate }))
    }
  }
  
  return (
   <div className='box-csatconcorrentes-search csatconcorrentes-search-data'>
      <InputDateRange
         label='Período'
         startDateId="dataInicial"
         startDate={dataInicial}
         endDateId="dataFinal"
         endDate={dataFinal}
         onDatesChange={({ startDate, endDate }) => handleFormFilter({ startDate, endDate })}
      />
   </div>
  )
}
