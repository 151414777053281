
const initialState = {
  centralAjuda: [],
  open: false,
  suporte: {
    menssagem: '',
    link: '',
    privado: '',
  },
  erro: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_INFO_CENTRAL_AJUDA':
      return { ...state, centralAjuda: payload }
    case 'SET_OPEN_CENTRAL_AJUDA':
      return { ...state, open: payload }

    case 'SET_SUPORTE_CENTRAL_AJUDA':
      return { ...state, suporte: {...state.suporte, [payload.name]: payload.value} }
    case 'CLEAN_SUPORTE_CENTRAL_AJUDA':
      return { ...state, suporte: initialState.suporte }
    default:
      return state
  }
}