import { dateCustom } from '../../../../components';
import { graficoCustom, numberZero, paramsApi } from '../../../../helpers';
import api from '../../../../helpers/api';
import apiOld from '../../../../helpers/apiOld';
import { setFiltered } from '../../../../layout/redux/layoutActions';
import { colors } from '../graficoOptions';
const labelsItens = ['Geral', 'Atendimento', 'Cliente', 'Preço', 'Produto']


export const setTotalAvaliacao = payload => ({
  type: 'INFO_GRAFICO_CSATCONCORRENTES_TOTAL_AVALIACAO',
  payload
})


export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_CSATCONCORRENTES',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_CSATCONCORRENTES',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_CSATCONCORRENTES',
})


export const setGraficoLoad = payload => ({
  type: 'LOAD_GRAFICO_CSATCONCORRENTES',
  payload
})
export const setGrafico = payload => ({
  type: 'INFO_GRAFICO_CSATCONCORRENTES',
  payload
})

export const carregarGrafico = ({projetoID, empresaId, params, typeSelect}) => {
  
  return dispatch => {
    dispatch(setGraficoLoad(true))
    let paramsUrl = paramsApi({
      ...params, 
      projetoId: projetoID,
      empresaId,
    })
    apiOld.get(`/front/GetCsatConcorrentes${paramsUrl}`)
    .then(result => {
      const resultado = graficoCustom(result.data)
      dispatch([
        setGrafico(resultado),
        setTotalAvaliacao(result.data.totalAvaliacoes),
        setGraficoLoad(false)
      ])
    })
    .catch(err => {
      dispatch([
        setGrafico([]),
        setTotalAvaliacao(0),
        setGraficoLoad(false)
      ])
    });
  }
}

export const filterHandle = ({projetoID, empresaId, params, typeSelect, filtersProvisorio}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  return dispatch => {
    let paramsFilter = filtersCustom({params: paramsCustom, typeSelect})
    dispatch(setGraficoLoad(true))
    dispatch([
      setFiltered(paramsCustom),
      carregarGrafico({projetoID, empresaId, params: paramsFilter, typeSelect})
    ])
  }
}

export const filtersCustom = ({params, typeSelect}) => {
  let paramsFilter = {}
  if (typeSelect?.id === 'Ciclo') {
    if (params?.ciclo?.mesAno) {
      paramsFilter = {
        comparacao: params.compararCiclos.id === 'ativar'?true:false,
        intervalo: `${params.ciclo.ano}-${params.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(params.dataInicial).data} ${dateCustom(params.dataFinal).data}`,
    }
  }
  return paramsFilter
}