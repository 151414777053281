import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, Select } from '../../../components'
import { putFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.comentariosState)

  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
  return (
   <div className='box-comentarios-search comentarios-search-ciclo'>
      <Select
         name='ciclo'
         label='Ciclo'
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
   </div>
  )
}
