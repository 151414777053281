import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from '../../components'
import { modalClose } from '../../layout/redux/layoutActions'

export default ({type='conteudo'}) => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  
  return (
    <Modal
      name='obter-mais'
      title={nls.obterMais.titulo}
      size='small'
      open={statusModal === 'obter-mais' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.btnFechar}
    >
      <div className='modal-modalInforme' 
        dangerouslySetInnerHTML={{__html: nls.obterMais[type]? nls.obterMais[type]?.replace('{{linkAdquirir}}', `<a target='__blank' href='#' class='link primary normal medium '>${nls.obterMais.linkAdquirir}</a>`):''}}
      />
    </Modal>
  )
}
