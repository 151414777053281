import React, { useState } from 'react'
import Portal from '../portal/portal';
import emojiAlegre from '../../layout/img/emojis/alegre.png';
import emojiTriste from '../../layout/img/emojis/triste.png';
import { numberAleatori } from '../../helpers';


export const GraficoHeatmapProduto = ({id, grafico, semdadosparaexibir, customHint=null, hint=false}) => {
  const [hintVisivel, sethintVisivel] = useState('');

  const invisible = (e) => {
    let div = document.getElementById("root-grafico-hint");
    let children = div.childNodes;
    for (let i = 0; i < children.length; i++) {
      if (children[i].className.includes(e)) {
        children[i].style.display = "block";
      } else {
        children[i].style.display = "none";
      }
    }
  }

  const openHint = (e, n) => {
    invisible(`graficotablehint-${id}`)
    if (e) {
      let div = e.target;
      let rect = div.getBoundingClientRect(),
        eixoR = rect.right,
        eixoX = rect.left,
        eixoY = rect.top,
        width = rect.width,
        height = rect.height;
        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;

      let coor = { left: eixoX , top: eixoY + height + 1 };

      if (Math.ceil(eixoY + height) + 300 >= windowHeight) {
        coor = {...coor, bottom: windowHeight - eixoY, top: 'auto' };
      } 
      if (Math.ceil(eixoX + width) + 300 >= windowWidth) {
        coor = {...coor, right: eixoR - eixoX, left: 'auto' };
      } 

      sethintVisivel(<div className={`grafico-table-hint graficotablehint-${id}`} style={{ ...coor, position: 'absolute', minWidth: width }}>
          <label>{n.x}</label>
          <span>{n.name}</span>
          {/* <span>{n.name}: <strong>{n.y}</strong></span> */}
    </div>);
    }
  };

  const icoReference = (value) => {
    if (value === 'positivo') {
      return <img src={emojiAlegre} title='Alegre' />
    }
    return <img src={emojiTriste} title='Triste' />
  }

  if (!Array.isArray(grafico) || grafico.length === 0 || !grafico[0]?.listaDeProdutosIndicador?.length) {
    return <div className='pageloadview-message'>{semdadosparaexibir}</div>;
  }
  
  //console.error(grafico, 'GRAFICO DENTRO DE HEATMAPPRODUTO');
  return grafico[0]?.listaDeProdutosIndicador?.length ? (
    
    <div id={`grafico-heatmap-${id}`} className="box-graficos-heatmap">
      <table className='grafico-table-head'>
        <tbody>
          <tr className='grafico-table-header'>
            <td></td>
            <td></td>
          </tr>
          {
            grafico?.map(e=> {
              return <tr key={`grafico-table-head-${e.descricaoIndicador}`}>
                  <td className='grafico-table-head-ico'>{icoReference(e.sinal)}</td>
                  <td className='grafico-table-head-name'>{e.descricaoIndicador}</td>
                </tr>
            })
          }
        </tbody>
      </table>
      <div className='grafico-table-scroll'>
        <table className='grafico-table-content'>
          <tbody>
            <tr className='grafico-table-header'>
              {
                grafico?.[0].listaDeProdutosIndicador?.map(d=> <td key={`grafico-table-header-${d.produtoId}`}>{d.descricao}</td>)
              }
            </tr>
            {
              grafico?.map(grafic=> {
                return <tr key={`grafico-table-content-${grafic.descricaoIndicador}`} >
                  {
                    grafic?.listaDeProdutosIndicador.map(produto=> {
                      return <td 
                        key={`grafico-table-content-item--${numberAleatori(99999999)}`}
                        style={{backgroundColor: produto.codigoCor, borderColor: produto.codigoCor}}
                        onMouseEnter={(ev)=> openHint(ev, {name: grafic.descricaoIndicador, x: produto.descricao, y: produto.qtdeIndicador}, `hint-${produto.produtoId}`)}
                        onMouseOut={(e)=> [sethintVisivel('')]}
                      >
                      </td>
                    })
                  }
                </tr>
              })
              
            }
          </tbody>
        </table>
      </div>
      {
        hint?
        <Portal name={`grafico-hint`}>
          {hintVisivel}
        </Portal>
        :null
      }
    </div>
  ) : <div className='pageloadview-message'>{semdadosparaexibir}</div> 
}
