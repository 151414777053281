import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, Select } from '../../../components'
import { putFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.comentariosState)
  const [ciclo1, setCiclo1] = useState({})
  const [ciclos1, setCiclos1] = useState([])

  const nls = require(`../nls/${sistema.idioma}.json`)

  useEffect(()=> {
    init()
  }, [])
  
  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
  console.error(ciclo,'CICLO EM FILTERCICLE');
  console.error(ciclos,'CICLOSSSSS EM FILTERCICLE');

const init = () => {
  const data = ciclo?.anoMesCiclo?.split('-') 
  if (data) {
    const mes = nls.nomesMeses.find(nm=> Number(nm.id) === Number(data[1]))
    const ciclo1Result = {...ciclo, mesAno:`${mes.name}-${data[0]}`, anoMes:`${data[0]}-${data[1]}`, ano: data[0], mes: data[1]}
    setCiclo1(ciclo1Result)
  }

  const ciclos1Result = ciclos.map(ciclolido => {
    const data = ciclolido.anoMesCiclo.split('-');
    const mes = nls.nomesMeses.find(nm => Number(nm.id) === Number(data[1]));
    return {
      ...ciclolido,
      mesAno: `${mes.name}-${data[0]}`,
      anoMes: `${data[0]}-${data[1]}`,
      ano: data[0],
      mes: data[1]
    };
  });
  setCiclos1(ciclos1Result)
}


  
  return (
   <div className='box-comentarios-search comentarios-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos1}
         selected={ciclo1}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
   </div>
  )
}
