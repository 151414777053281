import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, legacy_createStore as createStore } from "redux"
import { createLogger } from 'redux-logger'
import thunk from "redux-thunk"
import multi from 'redux-multi'
import rootRedux from "../main/reducer"

const loggerMiddleware = createLogger()

//ADICIONANDO PARA TRACE NO DEVTOOLS
const composeEnhancers = composeWithDevTools({
  trace: true, // Enable trace
  traceLimit: 25 // Adjust trace limit as needed
});

export const Store = createStore(
  rootRedux,
  composeEnhancers(applyMiddleware(thunk, loggerMiddleware, multi))
);

//REMOVI PARA ADICIONAR TRACE NO DEVTOOLS
//export const Store =  createStore(
//  rootRedux,
//  composeWithDevTools(applyMiddleware(thunk, loggerMiddleware, multi))
//)