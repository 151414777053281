import { PaginateTotal } from '../../../components'
import { listaAlterarAssinatura } from './minhasAssinaturasActions'

const paginateInit = {
  pageNumber: 1, 
  totalPages: 1, 
  totalElements: 0,
  perPage: 7
}

const initialState = {
  listaAssinaturas: [],
  paginate: paginateInit,
  ativos: [],
  listas: {
    alterarAssinaturas: [],
    economizarAssinatura: [],
    periodos: []
  },
  avaliacoes: {},
  assinatura: {
  },
  erro: {},
  load: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LIST_MINHAS_ASSINATURAS':
      return { ...state, listaAssinaturas: payload.assinaturas, paginate: {...state.paginate, totalElements: payload.totalProjetos } }
    case 'LIST_MINHAS_ASSINATURAS_ATIVOS':
      return { ...state, ativos: payload }
    case 'SET_MINHAS_ASSINATURAS_ATIVOS':
      return { ...state, ativos: state.ativos.map(e=> e.id === payload.id ? {...e, ...payload} : e) }
      
    case 'SET_MINHAS_ASSINATURAS_AVALIACOES':
      return { ...state, avaliacoes: payload }

      
    case 'LIST_ALL_ASSINATURAS':
      return { ...state, listas: {...state.listas, [payload.name]: payload.value} }

    case 'SET_MINHA_ASSINATURA':
      return { ...state, assinatura: payload }
    case 'CLEAN_MINHA_ASSINATURA':
      return { ...state, assinatura: initialState.assinatura }
    case 'SET_MINHAS_ASSINATURAS_ERRO':
      return { ...state, erro: payload }

    default:
      return state
  }
}
