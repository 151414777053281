import React from 'react'

export const IcoArchive1 = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-archive' viewBox="0 0 35 36" {...props} style={{...style,fill:'none'}}>
      <path d="M28.4375 21.2813V17.4531C28.4375 16.1478 27.9189 14.8959 26.9959 13.9728C26.0729 13.0498 24.821 12.5313 23.5156 12.5313H21.3281C20.893 12.5313 20.4757 12.3584 20.168 12.0507C19.8604 11.743 19.6875 11.3257 19.6875 10.8906V8.70313C19.6875 7.39776 19.1689 6.14586 18.2459 5.22283C17.3229 4.2998 16.071 3.78125 14.7656 3.78125H12.0313M15.3125 3.78125H8.20313C7.2975 3.78125 6.5625 4.51625 6.5625 5.42188V30.5781C6.5625 31.4838 7.2975 32.2188 8.20313 32.2188H26.7969C27.7025 32.2188 28.4375 31.4838 28.4375 30.5781V16.9063C28.4375 13.4253 27.0547 10.0869 24.5933 7.62547C22.1319 5.16406 18.7935 3.78125 15.3125 3.78125Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}