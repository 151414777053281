import React from 'react'

export const IcoEmojiInsatisfacao = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-emoji-insatisfacao' viewBox="0 0 15 16" {...props} style={{...style,fill:'none'}}>
      <path d="M9.79811 11.1186C9.19207 10.511 8.36973 10.1686 7.51156 10.1667C7.08273 10.1646 6.65776 10.2477 6.26129 10.4111C5.86481 10.5745 5.50472 10.8149 5.20189 11.1186M14 8C14 8.85359 13.8319 9.69883 13.5052 10.4874C13.1786 11.2761 12.6998 11.9926 12.0962 12.5962C11.4926 13.1998 10.7761 13.6786 9.98744 14.0052C9.19883 14.3319 8.35359 14.5 7.5 14.5C6.64641 14.5 5.80117 14.3319 5.01256 14.0052C4.22394 13.6786 3.50739 13.1998 2.90381 12.5962C2.30022 11.9926 1.82144 11.2761 1.49478 10.4874C1.16813 9.69883 1 8.85359 1 8C1 6.27609 1.68482 4.62279 2.90381 3.40381C4.12279 2.18482 5.77609 1.5 7.5 1.5C9.22391 1.5 10.8772 2.18482 12.0962 3.40381C13.3152 4.62279 14 6.27609 14 8ZM5.875 6.375C5.875 6.674 5.75367 6.91667 5.60417 6.91667C5.45467 6.91667 5.33333 6.674 5.33333 6.375C5.33333 6.076 5.45467 5.83333 5.60417 5.83333C5.75367 5.83333 5.875 6.076 5.875 6.375ZM5.60417 6.375H5.60994V6.38583H5.60417V6.375ZM9.66667 6.375C9.66667 6.674 9.54533 6.91667 9.39583 6.91667C9.24633 6.91667 9.125 6.674 9.125 6.375C9.125 6.076 9.24633 5.83333 9.39583 5.83333C9.54533 5.83333 9.66667 6.076 9.66667 6.375ZM9.39583 6.375H9.40161V6.38583H9.39583V6.375Z" stroke='currentColor' strokeWidth="1.5"/>
    </svg>
  )
}
