import React from 'react'

export const popupSuccess = (e) => {
    return <svg width="214" height="163" viewBox="0 0 214 163" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="71" width="214" height="92" rx="16" fill="#F7FAFC"/>
    <g clipPath="url(#clip0_86_4923)">
    <path d="M190.553 114.248H70.5336C70.1638 114.248 69.8093 114.101 69.5478 113.839C69.2863 113.578 69.1392 113.223 69.1387 112.853V1.39497C69.1392 1.02515 69.2863 0.670614 69.5478 0.409111C69.8093 0.147609 70.1638 0.000483448 70.5336 0H190.553C190.923 0.000483448 191.277 0.147609 191.539 0.409111C191.8 0.670614 191.947 1.02515 191.948 1.39497V112.853C191.947 113.223 191.8 113.578 191.539 113.839C191.277 114.101 190.923 114.248 190.553 114.248Z" fill="white"/>
    <path d="M190.553 114.248H70.5336C70.1638 114.248 69.8093 114.101 69.5478 113.839C69.2863 113.578 69.1392 113.223 69.1387 112.853V1.39497C69.1392 1.02515 69.2863 0.670614 69.5478 0.409111C69.8093 0.147609 70.1638 0.000483448 70.5336 0H190.553C190.923 0.000483448 191.277 0.147609 191.539 0.409111C191.8 0.670614 191.947 1.02515 191.948 1.39497V112.853C191.947 113.223 191.8 113.578 191.539 113.839C191.277 114.101 190.923 114.248 190.553 114.248ZM70.5336 0.55689C70.312 0.557371 70.0996 0.645632 69.9429 0.802355C69.7861 0.959078 69.6979 1.1715 69.6974 1.39314V112.853C69.6979 113.075 69.7861 113.287 69.9429 113.444C70.0996 113.601 70.312 113.689 70.5336 113.689H190.553C190.775 113.689 190.987 113.601 191.144 113.444C191.3 113.287 191.389 113.075 191.389 112.853V1.39497C191.389 1.17333 191.3 0.960908 191.144 0.804185C190.987 0.647463 190.775 0.559201 190.553 0.55872L70.5336 0.55689Z" fill="#CACACA"/>
    <path d="M127.246 28.9628C126.995 28.9632 126.754 29.0632 126.577 29.2407C126.4 29.4183 126.3 29.6589 126.3 29.9098C126.3 30.1607 126.4 30.4014 126.577 30.5789C126.754 30.7565 126.995 30.8565 127.246 30.8569H179.212C179.463 30.8565 179.704 30.7565 179.881 30.5789C180.058 30.4014 180.158 30.1607 180.158 29.9098C180.158 29.6589 180.058 29.4183 179.881 29.2407C179.704 29.0632 179.463 28.9632 179.212 28.9628H127.246Z" fill="#E4E4E4"/>
    <path d="M127.246 34.6456C126.995 34.6462 126.754 34.7463 126.577 34.9238C126.4 35.1013 126.301 35.3419 126.301 35.5927C126.301 35.8434 126.4 36.084 126.577 36.2615C126.754 36.4391 126.995 36.5391 127.246 36.5397H153.776C154.026 36.5391 154.267 36.4391 154.444 36.2615C154.621 36.084 154.72 35.8434 154.72 35.5927C154.72 35.3419 154.621 35.1013 154.444 34.9238C154.267 34.7463 154.026 34.6462 153.776 34.6456H127.246Z" fill="#E4E4E4"/>
    <path d="M81.8251 65.2061C81.5743 65.2067 81.334 65.3067 81.1569 65.4843C80.9798 65.6618 80.8804 65.9024 80.8804 66.1531C80.8804 66.4039 80.9798 66.6444 81.1569 66.822C81.334 66.9995 81.5743 67.0996 81.8251 67.1002H179.263C179.514 67.0996 179.754 66.9995 179.931 66.822C180.108 66.6444 180.208 66.4039 180.208 66.1531C180.208 65.9024 180.108 65.6618 179.931 65.4843C179.754 65.3067 179.514 65.2067 179.263 65.2061H81.8251Z" fill="#E4E4E4"/>
    <path d="M81.8251 70.8889C81.5743 70.8895 81.334 70.9895 81.1569 71.1671C80.9798 71.3446 80.8804 71.5852 80.8804 71.8359C80.8804 72.0867 80.9798 72.3272 81.1569 72.5048C81.334 72.6823 81.5743 72.7824 81.8251 72.783H153.826C154.077 72.7824 154.317 72.6823 154.494 72.5048C154.671 72.3272 154.771 72.0867 154.771 71.8359C154.771 71.5852 154.671 71.3446 154.494 71.1671C154.317 70.9895 154.077 70.8895 153.826 70.8889H81.8251Z" fill="#E4E4E4"/>
    <path d="M81.8251 76.4431C81.5743 76.4437 81.334 76.5437 81.1569 76.7212C80.9798 76.8988 80.8804 77.1393 80.8804 77.3901C80.8804 77.6409 80.9798 77.8814 81.1569 78.059C81.334 78.2365 81.5743 78.3365 81.8251 78.3372H179.263C179.514 78.3365 179.754 78.2365 179.931 78.059C180.108 77.8814 180.208 77.6409 180.208 77.3901C180.208 77.1393 180.108 76.8988 179.931 76.7212C179.754 76.5437 179.514 76.4437 179.263 76.4431H81.8251Z" fill="#E4E4E4"/>
    <path d="M81.8251 82.126C81.5743 82.1266 81.334 82.2266 81.1569 82.4042C80.9798 82.5817 80.8804 82.8222 80.8804 83.073C80.8804 83.3238 80.9798 83.5643 81.1569 83.7419C81.334 83.9194 81.5743 84.0195 81.8251 84.0201H153.826C154.077 84.0195 154.317 83.9194 154.494 83.7419C154.671 83.5643 154.771 83.3238 154.771 83.073C154.771 82.8222 154.671 82.5817 154.494 82.4042C154.317 82.2266 154.077 82.1266 153.826 82.126H81.8251Z" fill="#E4E4E4"/>
    <path d="M81.8251 87.8281C81.5743 87.8287 81.334 87.9288 81.1569 88.1063C80.9798 88.2839 80.8804 88.5244 80.8804 88.7752C80.8804 89.026 80.9798 89.2665 81.1569 89.444C81.334 89.6216 81.5743 89.7216 81.8251 89.7222H179.263C179.514 89.7216 179.754 89.6216 179.931 89.444C180.108 89.2665 180.208 89.026 180.208 88.7752C180.208 88.5244 180.108 88.2839 179.931 88.1063C179.754 87.9288 179.514 87.8287 179.263 87.8281H81.8251Z" fill="#E4E4E4"/>
    <path d="M81.8251 93.511C81.5743 93.5116 81.334 93.6116 81.1569 93.7891C80.9798 93.9667 80.8804 94.2072 80.8804 94.458C80.8804 94.7088 80.9798 94.9493 81.1569 95.1269C81.334 95.3044 81.5743 95.4044 81.8251 95.4051H153.826C154.077 95.4044 154.317 95.3044 154.494 95.1269C154.671 94.9493 154.771 94.7088 154.771 94.458C154.771 94.2072 154.671 93.9667 154.494 93.7891C154.317 93.6116 154.077 93.5116 153.826 93.511H81.8251Z" fill="#E4E4E4"/>
    <path d="M97.2575 46.3578C94.5664 46.3578 91.9357 45.5598 89.6981 44.0647C87.4605 42.5696 85.7165 40.4446 84.6867 37.9583C83.6568 35.4721 83.3873 32.7362 83.9123 30.0968C84.4373 27.4574 85.7332 25.0329 87.6361 23.13C89.539 21.2271 91.9635 19.9312 94.6029 19.4062C97.2423 18.8812 99.9781 19.1506 102.464 20.1804C104.951 21.2103 107.076 22.9543 108.571 25.1919C110.066 27.4294 110.864 30.0601 110.864 32.7512C110.86 36.3586 109.425 39.8171 106.874 42.3679C104.323 44.9188 100.865 46.3537 97.2575 46.3578Z" fill="#D72D3B"/>
    <path d="M55.9546 97.8049C55.9311 97.3083 55.799 96.8228 55.5675 96.3828C55.3361 95.9427 55.011 95.5588 54.6151 95.258C54.2193 94.9572 53.7622 94.7468 53.2763 94.6417C52.7903 94.5366 52.2872 94.5394 51.8024 94.6498L47.3049 88.3116L42.7866 90.1053L49.2637 98.9668C49.5031 99.769 50.0284 100.455 50.7401 100.896C51.4518 101.337 52.3005 101.501 53.1252 101.358C53.95 101.215 54.6935 100.774 55.215 100.119C55.7365 99.4641 55.9997 98.6408 55.9546 97.8049Z" fill="#A0616A"/>
    <path d="M47.1897 97.3353L33.5811 79.6807L38.6801 63.6552C39.0538 59.6262 41.575 58.5012 41.6823 58.4552L41.8459 58.385L46.283 70.2177L43.0251 78.9054L51.0215 92.3541L47.1897 97.3353Z" fill="#D72D3B"/>
    <path d="M94.4505 42.9122C93.9571 42.974 93.4834 43.1433 93.0625 43.4081C92.6417 43.6728 92.284 44.0267 92.0147 44.4446C91.7454 44.8626 91.571 45.3345 91.5038 45.8271C91.4366 46.3197 91.4783 46.8211 91.6258 47.2959L85.6543 52.27L87.792 56.6361L96.1262 49.4933C96.9074 49.1926 97.5512 48.6157 97.9355 47.8721C98.3198 47.1284 98.418 46.2696 98.2115 45.4584C98.0049 44.6472 97.5079 43.94 96.8148 43.4707C96.1216 43.0014 95.2803 42.8027 94.4505 42.9122Z" fill="#A0616A"/>
    <path d="M94.6598 51.6877L78.1102 66.6206L61.7385 62.7759C57.6926 62.7148 56.376 60.2882 56.3219 60.1848L56.2393 60.027L67.6934 54.6883L76.607 57.2648L89.3973 48.2525L94.6598 51.6877Z" fill="#D72D3B"/>
    <path d="M65.994 157.846L70.4406 157.846L72.5557 140.695L65.9932 140.695L65.994 157.846Z" fill="#A0616A"/>
    <path d="M79.0151 161.974L65.0404 161.975L65.0401 156.576L75.0942 156.575C76.1341 156.575 77.1313 156.988 77.8666 157.723C78.6019 158.459 79.015 159.456 79.0151 160.496L79.0151 161.974Z" fill="#2B2B2B"/>
    <path d="M48.2208 157.846L52.6673 157.846L54.7827 140.695L48.2202 140.695L48.2208 157.846Z" fill="#A0616A"/>
    <path d="M61.2437 161.974L47.2689 161.975L47.2686 156.576L57.3228 156.575C57.8376 156.575 58.3475 156.677 58.8231 156.874C59.2988 157.071 59.731 157.359 60.0951 157.723C60.4592 158.087 60.748 158.52 60.9451 158.995C61.1421 159.471 61.2436 159.981 61.2436 160.496L61.2437 161.974Z" fill="#2B2B2B"/>
    <path d="M46.6401 95.2432L47.0028 120.632L47.3657 153.999L55.3447 153.274L59.3345 107.212L64.4122 153.274H72.6497L73.4794 106.85L70.5779 96.6941L46.6401 95.2432Z" fill="#2B2B2B"/>
    <path d="M62.2573 98.6526C53.5754 98.6533 45.5838 94.7238 45.4759 94.6699L45.3863 94.6251L44.6577 77.1403C44.4465 76.5225 40.2862 64.3305 39.5817 60.4553C38.8678 56.5292 49.2141 53.0835 50.4705 52.6806L50.7556 49.5222L62.3499 48.2728L63.8195 52.3141L67.979 53.8738C68.4506 54.0508 68.8408 54.3946 69.0758 54.8401C69.3109 55.2855 69.3743 55.8017 69.2542 56.2909L66.9423 65.6922L72.5886 96.7964L71.373 96.849C68.4593 98.1649 65.3146 98.6525 62.2573 98.6526Z" fill="#D72D3B"/>
    <path d="M61.0106 45.2494C65.056 43.0379 66.5428 37.9657 64.3313 33.9203C62.1198 29.8748 57.0476 28.3881 53.0022 30.5995C48.9567 32.811 47.47 37.8832 49.6814 41.9286C51.8929 45.9741 56.9651 47.4608 61.0106 45.2494Z" fill="#A0616A"/>
    <path d="M50.2452 46.006C51.8361 47.7007 54.7907 46.7909 54.998 44.4758C55.0142 44.296 55.0131 44.1151 54.9945 43.9356C54.8874 42.9101 54.295 41.979 54.4369 40.8962C54.469 40.6267 54.5694 40.3699 54.7286 40.1501C55.9963 38.4525 58.9723 40.9094 60.1688 39.3726C60.9024 38.4302 60.04 36.9466 60.603 35.8933C61.3461 34.5032 63.547 35.189 64.9272 34.4277C66.4628 33.5807 66.371 31.2245 65.3601 29.7914C64.1273 28.0437 61.9658 27.1111 59.8313 26.9767C57.6967 26.8423 55.5769 27.4193 53.5841 28.1959C51.3199 29.0783 49.0746 30.2978 47.6813 32.2887C45.9868 34.7099 45.8237 37.965 46.6711 40.7961C47.1867 42.5183 48.946 44.6221 50.2452 46.006Z" fill="#2B2B2B"/>
    <path d="M96.4723 37.8904C96.2269 37.8907 95.9906 37.7979 95.811 37.6306L91.3244 33.4633C91.1358 33.2878 91.0247 33.0447 91.0153 32.7872C91.0059 32.5298 91.0991 32.2792 91.2744 32.0905C91.4497 31.9017 91.6928 31.7903 91.9502 31.7807C92.2076 31.771 92.4583 31.864 92.6472 32.0391L96.4475 35.569L103.966 28.0504C104.056 27.9596 104.164 27.8874 104.282 27.8381C104.4 27.7888 104.526 27.7633 104.654 27.7631C104.782 27.7629 104.909 27.7879 105.027 27.8368C105.145 27.8856 105.253 27.9574 105.343 28.0478C105.434 28.1383 105.506 28.2458 105.554 28.364C105.603 28.4823 105.628 28.609 105.628 28.737C105.628 28.8649 105.602 28.9916 105.553 29.1096C105.504 29.2277 105.431 29.3349 105.341 29.425L97.1597 37.6058C97.0695 37.6962 96.9623 37.7678 96.8444 37.8166C96.7264 37.8655 96.5999 37.8906 96.4723 37.8904Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_86_4923">
    <rect width="189.058" height="162.232" fill="white" transform="translate(2.89062)"/>
    </clipPath>
    </defs>
    </svg>
    
}

export const popupError = (e) => {
    return <svg width="214" height="174" viewBox="0 0 214 174" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="81.3115" width="214" height="92" rx="16" fill="#F7FAFC"/>
    <g clipPath="url(#clip0_53_942)">
    <path d="M117.032 133.436C153.753 133.436 183.521 103.668 183.521 66.9471C183.521 30.2262 153.753 0.458008 117.032 0.458008C80.3112 0.458008 50.543 30.2262 50.543 66.9471C50.543 103.668 80.3112 133.436 117.032 133.436Z" fill="#F4F4F4"/>
    <path d="M160.482 30.1914H38.6831C38.4219 30.1858 38.2147 29.9696 38.2203 29.7084C38.2257 29.4551 38.4298 29.251 38.6831 29.2456H160.482C160.743 29.2512 160.95 29.4674 160.945 29.7286C160.939 29.9819 160.735 30.186 160.482 30.1914Z" fill="#CACACA"/>
    <path d="M118.973 46.1289H85.3033C82.7743 46.1289 80.7241 48.1791 80.7241 50.7081V50.7081C80.7241 53.2371 82.7743 55.2872 85.3033 55.2872H118.973C121.502 55.2872 123.552 53.2371 123.552 50.7081C123.552 48.1791 121.502 46.1289 118.973 46.1289Z" fill="white"/>
    <path d="M63.5521 64.4458H140.724C143.253 64.4458 145.303 66.496 145.303 69.025C145.303 71.554 143.253 73.6041 140.724 73.6041H63.5521C61.0231 73.6041 58.9729 71.554 58.9729 69.025C58.9729 66.496 61.0231 64.4458 63.5521 64.4458Z" fill="white"/>
    <path d="M63.5521 82.7627H140.724C143.253 82.7627 145.303 84.8129 145.303 87.3419C145.303 89.8709 143.253 91.921 140.724 91.921H63.5521C61.0231 91.921 58.9729 89.8709 58.9729 87.3419C58.9729 84.8129 61.0231 82.7627 63.5521 82.7627Z" fill="white"/>
    <path d="M74.8411 169.785L77.6682 169.785L79.0134 158.879L74.8403 158.88L74.8411 169.785Z" fill="#A0616A"/>
    <path d="M74.7411 172.735L83.4356 172.735V172.625C83.4354 170.756 81.9204 169.241 80.0514 169.241L78.4631 168.036L75.4999 169.241L74.741 169.241L74.7411 172.735Z" fill="#2B2B2B"/>
    <path d="M52.1829 161.955L54.4443 163.651L62.0651 155.735L58.7271 153.231L52.1829 161.955Z" fill="#A0616A"/>
    <path d="M50.3328 164.254L57.2877 169.472L57.3537 169.384C58.4752 167.889 58.1725 165.767 56.6776 164.646L56.1301 162.729L53.0367 161.914L52.4296 161.459L50.3328 164.254Z" fill="#2B2B2B"/>
    <path d="M61.5811 118.608C61.5811 118.608 61.7372 122.865 61.9341 126.279C61.9664 126.839 60.6545 127.448 60.6885 128.057C60.711 128.461 60.9576 128.854 60.9805 129.275C61.0047 129.72 60.6642 130.114 60.6885 130.574C60.7122 131.023 61.1006 131.538 61.124 131.998C61.3761 136.948 62.2901 142.874 61.3749 144.53C60.9238 145.347 55.1748 156.657 55.1748 156.657C55.1748 156.657 58.378 161.461 59.2932 159.86C60.5019 157.744 69.8179 147.848 69.8179 145.958C69.8179 144.07 71.9731 129.924 71.9731 129.924L73.4759 138.296L74.165 139.954L73.9485 140.928L74.3938 142.242L74.4179 143.543L74.8514 145.958C74.8514 145.958 73.5473 164.774 74.2817 165.298C75.0184 165.824 78.5854 166.769 79.0064 165.824C79.4251 164.88 82.1504 146.187 82.1504 146.187C82.1504 146.187 82.5897 137.621 83.0656 129.658C83.093 129.196 83.418 128.638 83.4431 128.183C83.4729 127.654 83.267 126.968 83.2944 126.455C83.3241 125.867 83.585 125.453 83.6101 124.89C83.8046 120.541 82.7129 115.244 82.3651 114.723C81.3149 113.149 80.37 111.89 80.37 111.89C80.37 111.89 66.2252 107.243 62.4455 111.965L61.5811 118.608Z" fill="#2B2B2B"/>
    <path d="M75.6885 74.8275L70.0812 73.7061L67.4644 77.2573C62.5817 82.0583 62.5082 86.2956 63.3798 93.0875V102.62L62.749 109.01C62.749 109.01 60.5157 113.681 62.8191 114.769C65.1225 115.857 80.2525 115.764 81.697 115.33C83.1416 114.896 81.8895 114.363 81.5101 112.339C80.7976 108.539 81.2742 110.372 81.3232 109.162C82.0263 91.803 80.3887 84.6765 80.2224 82.8033L77.3707 77.6312L75.6885 74.8275Z" fill="#D72D3B"/>
    <path d="M105.243 88.823C104.658 89.9303 103.287 90.3542 102.18 89.7698C102.061 89.7075 101.949 89.6349 101.844 89.5528L89.7344 97.2418L89.7693 93.0555L101.533 86.229C102.371 85.2895 103.811 85.2072 104.751 86.0451C105.537 86.7462 105.74 87.8947 105.243 88.823Z" fill="#A0616A"/>
    <path d="M71.2615 79.6754L69.8828 79.5293C68.609 79.3972 67.3591 79.9427 66.5899 80.9666C66.2953 81.355 66.0837 81.7997 65.9682 82.2733L65.9674 82.2771C65.6216 83.7025 66.164 85.1963 67.3438 86.0678L72.1054 89.5784C75.447 94.0936 81.7271 97.0892 89.0938 99.5229L100.544 92.4115L96.4942 87.5064L88.6402 91.7156L76.9658 82.6784L76.9591 82.6729L72.5158 79.8102L71.2615 79.6754Z" fill="#D72D3B"/>
    <path d="M73.794 72.0739C77.4054 72.0739 80.333 69.1464 80.333 65.535C80.333 61.9237 77.4054 58.9961 73.794 58.9961C70.1827 58.9961 67.2551 61.9237 67.2551 65.535C67.2551 69.1464 70.1827 72.0739 73.794 72.0739Z" fill="#A0616A"/>
    <path d="M73.9451 72.0752C73.8924 72.0769 73.8395 72.0781 73.7867 72.0795C73.7642 72.1381 73.7398 72.1962 73.7117 72.2532L73.9451 72.0752Z" fill="#2B2B2B"/>
    <path d="M75.7395 65.7163C75.7498 65.7807 75.7654 65.8442 75.7864 65.906C75.7778 65.8412 75.762 65.7776 75.7395 65.7163Z" fill="#2B2B2B"/>
    <path d="M80.4161 59.2756C80.147 60.1114 79.9068 59.0513 79.0214 59.242C77.9563 59.4713 76.7168 59.3926 75.8446 58.7397C74.545 57.7861 72.8608 57.5294 71.3361 58.0525C69.8436 58.5805 67.3432 58.9528 66.9543 60.4874C66.8191 61.0209 66.7655 61.5998 66.4378 62.0419C66.1513 62.4284 65.6992 62.6488 65.3211 62.9463C64.0444 63.9508 65.0202 66.8047 65.6244 68.3126C66.2287 69.8206 67.6148 70.9191 69.1387 71.4818C70.6132 72.0262 72.2135 72.1222 73.7865 72.0795C74.0601 71.3699 73.9419 70.5504 73.7161 69.8148C73.4718 69.0185 73.1099 68.25 73.0078 67.4233C72.9057 66.5966 73.1299 65.6576 73.8231 65.1957C74.4602 64.7711 75.497 65.0298 75.7394 65.7162C75.598 64.858 76.4673 64.0276 77.3637 63.8815C78.3261 63.7247 79.2884 64.0722 80.2481 64.2451C81.2077 64.4181 80.8671 60.378 80.4161 59.2756Z" fill="#2B2B2B"/>
    <path d="M116.987 76.3514C117.596 68.9861 112.12 62.5213 104.754 61.9117C97.3891 61.3021 90.9242 66.7787 90.3147 74.144C89.7051 81.5092 95.1817 87.9741 102.547 88.5837C109.912 89.1933 116.377 83.7167 116.987 76.3514Z" fill="#D72D3B"/>
    <path d="M107.165 69.6719L103.65 73.187L100.135 69.6719C99.5663 69.1029 98.6437 69.1029 98.0747 69.6719C97.5057 70.2409 97.5057 71.1634 98.0747 71.7325L101.59 75.2476L98.0747 78.7627C97.5062 79.3322 97.5071 80.2547 98.0766 80.8232C98.6454 81.3909 99.5665 81.3909 100.135 80.8232L103.65 77.3081L107.165 80.8232C107.735 81.3917 108.658 81.3908 109.226 80.8213C109.794 80.2525 109.794 79.3314 109.226 78.7627L105.711 75.2476L109.226 71.7325C109.795 71.1634 109.795 70.2409 109.226 69.6719C108.657 69.1029 107.734 69.1029 107.165 69.6719Z" fill="white"/>
    <path d="M98.3778 64.0006C98.7723 65.1889 98.1287 66.4719 96.9405 66.8664C96.8138 66.9084 96.6836 66.9392 96.5515 66.9584L93.7164 81.0198L90.7197 78.0964L93.9376 64.8818C93.8397 63.6267 94.7779 62.53 96.0329 62.4322C97.083 62.3504 98.0525 62.9988 98.3778 64.0006Z" fill="#A0616A"/>
    <path d="M68.2509 82.188L67.1911 83.0817C66.2139 83.9094 65.7423 85.189 65.9486 86.453C66.0249 86.9344 66.1993 87.395 66.4611 87.8062L66.4633 87.8094C67.2524 89.0458 68.7059 89.6886 70.1515 89.4405L75.9813 88.4349C81.5529 89.1495 88.0623 86.6916 94.9184 83.0607L97.714 69.8747L91.3706 69.4014L88.9706 77.9828L74.3671 80.1507L74.3585 80.1518L69.2166 81.3762L68.2509 82.188Z" fill="#D72D3B"/>
    <path d="M44.8626 24.2013C46.0852 24.2013 47.0763 23.1881 47.0763 21.9383C47.0763 20.6885 46.0852 19.6753 44.8626 19.6753C43.64 19.6753 42.6489 20.6885 42.6489 21.9383C42.6489 23.1881 43.64 24.2013 44.8626 24.2013Z" fill="#2B2B2B"/>
    <path d="M52.5098 24.2013C53.7324 24.2013 54.7235 23.1881 54.7235 21.9383C54.7235 20.6885 53.7324 19.6753 52.5098 19.6753C51.2872 19.6753 50.2961 20.6885 50.2961 21.9383C50.2961 23.1881 51.2872 24.2013 52.5098 24.2013Z" fill="#2B2B2B"/>
    <path d="M60.1573 24.2013C61.3799 24.2013 62.3709 23.1881 62.3709 21.9383C62.3709 20.6885 61.3799 19.6753 60.1573 19.6753C58.9347 19.6753 57.9436 20.6885 57.9436 21.9383C57.9436 23.1881 58.9347 24.2013 60.1573 24.2013Z" fill="#2B2B2B"/>
    <path d="M149.527 19.937H146.75C146.433 19.937 146.177 20.1935 146.177 20.5103V23.2903C146.177 23.607 146.433 23.8635 146.75 23.8635H149.527C149.844 23.8635 150.103 23.607 150.103 23.2903V20.5103C150.103 20.1935 149.844 19.937 149.527 19.937Z" fill="#2B2B2B"/>
    <path d="M142.721 19.937H139.944C139.627 19.937 139.371 20.1935 139.371 20.5103V23.2903C139.371 23.607 139.627 23.8635 139.944 23.8635H142.721C143.038 23.8635 143.297 23.607 143.297 23.2903V20.5103C143.297 20.1935 143.038 19.937 142.721 19.937Z" fill="#2B2B2B"/>
    <path d="M155.94 20.0684H153.163C152.846 20.0684 152.59 20.3249 152.59 20.6416V23.4216C152.59 23.7384 152.846 23.9949 153.163 23.9949H155.94C156.257 23.9949 156.516 23.7384 156.516 23.4216V20.6416C156.516 20.3249 156.257 20.0684 155.94 20.0684Z" fill="#2B2B2B"/>
    <path d="M114.078 21.1255H91.8774C91.49 21.1255 91.1785 21.4396 91.1785 21.8244C91.1785 22.2092 91.49 22.5233 91.8774 22.5233H114.078C114.463 22.5233 114.777 22.2092 114.777 21.8244C114.777 21.4396 114.463 21.1255 114.078 21.1255Z" fill="#2B2B2B"/>
    </g>
    <defs>
    <clipPath id="clip0_53_942">
    <rect width="153.521" height="172.583" fill="white" transform="translate(30 0.458008)"/>
    </clipPath>
    </defs>
    </svg>
    
}