import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../../components'
import './filter.scss'
import FilterCiclo from './filterCiclo'
import FilterData from './filterData'
import { filterHandle, putFiltersProvisorio, setFiltersProvisorio } from '../redux/npsconcorrentesredesActions'

export default () => {
  const dispatch = useDispatch()
  const { projeto, empresa, filters, listas: {typeSearch}, ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio } = useSelector(state=> state.npsconcorrentesredesState)

  useEffect(()=> {
    let filtros = filters
    if (!filters?.ciclo?.anoMes  && ciclos.length) {
      filtros = {...filtersProvisorio, ...filtros, ciclo: ciclos[ciclos.length - 1]}
    }
    dispatch(setFiltersProvisorio(filtros))
  }, [ciclos])


  const handleSearching = (params) => {
    handleSearchingValid(params)
  }
  
  const handleSearchingValid = (params) => {
      dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, params, filtersProvisorio, typeSelect: params.typeSelect, idioma: sistema.idiomaIdValue}))
  }
  
  const contentFilterType = (e) => {
    switch (e?.id) {
      case 'Ciclo':
        return <FilterCiclo />;
      case 'Data':
        return <FilterData />;
      default:
        return;
    }
  }
  
  return (
    <div className='box-npsconcorrentesredes-search box-content'>
      <div className='box-npsconcorrentesredes-search-box'>
        <div className='box-npsconcorrentesredes-search-type'>
          {
            typeSearch.map((type)=> {
              return <Button
                color={'primary'}
                type={type.id === filtersProvisorio?.typeSelect?.id?'btn':'link'}
                size='small'
                onClick={()=> dispatch(putFiltersProvisorio({name: 'typeSelect', value: type}))}
                key={type.id}
              >
                {type.label}
              </Button> 
            })
          }
          <p>{filtersProvisorio?.typeSelect?.text}</p>
        </div>
        <div>
          {contentFilterType(filtersProvisorio?.typeSelect)}
        </div>

      </div>
      <div className='box-npsconcorrentesredes-search-action'>
        <Button
          color='primary'
          size='large'
          onClick={()=> handleSearching(filtersProvisorio)}
        >Pesquisar</Button>          
      </div>
    </div>
  )
}
