import React from 'react'

export const IcoCircleClose = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-circle-close' viewBox="0 0 29 28" {...props} style={{...style,fill:'none'}}>
      <path d="M11.2188 10.7188L17.7813 17.2813M17.7813 10.7188L11.2188 17.2813M27.625 14C27.625 15.7236 27.2855 17.4303 26.6259 19.0227C25.9663 20.6151 24.9995 22.062 23.7808 23.2808C22.562 24.4995 21.1151 25.4663 19.5227 26.1259C17.9303 26.7855 16.2236 27.125 14.5 27.125C12.7764 27.125 11.0697 26.7855 9.47728 26.1259C7.88488 25.4663 6.43799 24.4995 5.21922 23.2808C4.00045 22.062 3.03367 20.6151 2.37408 19.0227C1.71449 17.4303 1.375 15.7236 1.375 14C1.375 10.519 2.75781 7.18064 5.21922 4.71922C7.68064 2.25781 11.019 0.875 14.5 0.875C17.981 0.875 21.3194 2.25781 23.7808 4.71922C26.2422 7.18064 27.625 10.519 27.625 14Z" stroke='currentColor' strokeWidth="2" />
    </svg>

  )
}