import React from 'react'

export const IcoProjeto1 = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-projeto' viewBox="0 0 17 17" {...props} style={{...style}}>
      <path d="M5.5 4C5.5 3.46957 5.71071 2.96086 6.08579 2.58579C6.46086 2.21071 6.96957 2 7.5 2H9.5C10.0304 2 10.5391 2.21071 10.9142 2.58579C11.2893 2.96086 11.5 3.46957 11.5 4V4.13667C12.122 4.19333 12.738 4.268 13.3493 4.35933C14.3187 4.50467 15 5.348 15 6.304V8.326C15 9.13333 14.5107 9.894 13.7093 10.1607C12.0292 10.7184 10.2702 11.0018 8.5 11C6.68 11 4.92867 10.7053 3.29067 10.1607C2.48933 9.894 2 9.13333 2 8.326V6.304C2 5.348 2.68133 4.504 3.65067 4.35933C4.26486 4.26748 4.88156 4.19323 5.5 4.13667V4ZM10.5 4V4.06C9.16789 3.9791 7.83211 3.9791 6.5 4.06V4C6.5 3.73478 6.60536 3.48043 6.79289 3.29289C6.98043 3.10536 7.23478 3 7.5 3H9.5C9.76522 3 10.0196 3.10536 10.2071 3.29289C10.3946 3.48043 10.5 3.73478 10.5 4ZM8.5 9.5C8.63261 9.5 8.75979 9.44732 8.85355 9.35355C8.94732 9.25979 9 9.13261 9 9C9 8.86739 8.94732 8.74022 8.85355 8.64645C8.75979 8.55268 8.63261 8.5 8.5 8.5C8.36739 8.5 8.24022 8.55268 8.14645 8.64645C8.05268 8.74022 8 8.86739 8 9C8 9.13261 8.05268 9.25979 8.14645 9.35355C8.24022 9.44732 8.36739 9.5 8.5 9.5Z" />
      <path d="M2.5 12.7667V10.9027C2.65188 10.9858 2.81097 11.055 2.97533 11.1093C4.75712 11.7009 6.62257 12.0017 8.5 12C10.428 12 12.2867 11.688 14.0247 11.11C14.1927 11.054 14.3513 10.984 14.5 10.9027V12.7667C14.5 13.7347 13.802 14.5853 12.818 14.7153C11.4047 14.9033 9.96333 15 8.5 15C7.05597 15.0003 5.6135 14.9052 4.182 14.7153C3.198 14.5853 2.5 13.7347 2.5 12.7667Z" />  
    </svg>
  )
}