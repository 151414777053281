import React from 'react'

export const IcoDados = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-dados' viewBox="0 0 21 25" {...props} style={{...style,fill:'none'}}>
      <path d="M20 5.90805C20 8.5312 15.7463 10.6581 10.5 10.6581C5.2537 10.6581 1 8.5312 1 5.90805M20 5.90805C20 3.2849 15.7463 1.15805 10.5 1.15805C5.2537 1.15805 1 3.2849 1 5.90805M20 5.90805V18.8626C20 21.4857 15.7463 23.6126 10.5 23.6126C5.2537 23.6126 1 21.4857 1 18.8626V5.90805M20 5.90805V10.2262M1 5.90805V10.2262M20 10.2262V14.5444C20 17.1676 15.7463 19.2944 10.5 19.2944C5.2537 19.2944 1 17.1676 1 14.5444V10.2262M20 10.2262C20 12.8494 15.7463 14.9762 10.5 14.9762C5.2537 14.9762 1 12.8494 1 10.2262" stroke='currentColor' strokeWidth="1.5"/>
    </svg>
  )
}
