import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal } from '../../../components'
import { modalClose } from '../../../layout/redux/layoutActions'
import { calculadoraCompraDeAtivos, CheckoutAtivosAvulsosXD, listarAtivos, setAtivos } from '../redux/minhasAssinaturasActions'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { ativos, avaliacoes } = useSelector(state=> state.minhasAssinaturasState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)

  useEffect(()=> {
    if (statusModal === 'assinaturas-adquirir-ativos') {
      dispatch(listarAtivos({consumoConta: sistema.consumoConta, moeda: '', tipo: '', status: 'vazio', idioma: sistema.idiomaIdValue}))
    }
  }, [statusModal, sistema.idioma, sistema.consumoConta])

  const handleChange = e => {
    const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
    dispatch(setAtivos(e))
    dispatch(calculadoraCompraDeAtivos({ativos: listaAtivos}))
  }

  const handleCancelar = e => {
    dispatch(modalClose())
  }
  
  const handleSalvar = e => {
    console.warn(e, 'assinaturas-adquirir-ativos');
    const hasAtivos = ativos.filter(a=> a.quantidadeDesejada > 0)
    const params = {
      moeda: usuario.moedaIso4217,
      ativos: [...ativos, {
        descricao: "Avaliações mensais",
        id: 6,
        quantidadeDesejada: avaliacoes.somaQuantidadeQueriesSemQueryPura30,
        valor: avaliacoes.somaQuantidadeQueriesSemQueryPura30
      }]

    }
    if (hasAtivos.length) {
      dispatch(CheckoutAtivosAvulsosXD(params, nlsPopup))
    }
  }
  
  
  return (
    <Modal
      name='assinaturas-adquirir-ativos'
      title={nls[false? 'titleAlterarAtivos' : 'titleAdquirirAtivos']}
      open={statusModal === 'assinaturas-adquirir-ativos' ? true : false}
      close={() => handleCancelar()}
      closeText={nls.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCancelar()}
        >{nls.cancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar({})}
        >{nls[false? 'editarAtivos' : 'salvarAtivos']}</Button>
      </>}

    >
      <div>
        <div className='ativos-lista-title'>
          <h5>{nls.adicionarAtivos}</h5>
        </div>
        <div className='ativos-lista'>
          {ativos.map(e=> {
            return <div key={e.id} className='ativos-lista-item'>
              <div className='ativos-lista-item-info'>
                <strong>{e.descricao}</strong>
                <small>{nls.valorAtual}: {e.valor}</small>
              </div>
              <div className='ativos-lista-item-input'>
                  <Input 
                    type='number'
                    value={e.quantidadeDesejada}
                    action={a=> handleChange({...e, quantidadeDesejada: a.value})}
                    min={0}
                  />
              </div>
            </div>
          })}
          <div className='ativos-lista-item'>
            <div className='ativos-lista-item-info'>
              <strong>{nls.avaliacoesMensaisAdicionais}</strong>
              <small>{nls.valorAtual}: {usuario?.avaliacoesPermitidasConta}</small>
            </div>
            <div className='ativos-lista-item-input'>
              <div className='flag-primary'>
                {avaliacoes.somaQuantidadeQueriesSemQueryPura30}
              </div>
            </div>
          </div>
        </div>
        <div className='ativos-info'>
          <span>{nls.valorCobradoImediatamente}: <strong>{avaliacoes.valorAdicional}</strong> </span>
          <span>{nls.valorCobradoProxima}: <strong>{avaliacoes.valorTotal}</strong> </span>
        </div>
      </div>
    </Modal>
  )
}
