import React from 'react'

export const IcoProjeto4 = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-projeto' viewBox="0 0 17 17" {...props} style={{...style}}>
      <path d="M2 9.5C2 8.04131 2.57946 6.64236 3.61091 5.61091C4.64236 4.57946 6.04131 4 7.5 4C7.63261 4 7.75979 4.05268 7.85355 4.14645C7.94732 4.24021 8 4.36739 8 4.5V9H12.5C12.6326 9 12.7598 9.05268 12.8536 9.14645C12.9473 9.24022 13 9.36739 13 9.5C13 10.9587 12.4205 12.3576 11.3891 13.3891C10.3576 14.4205 8.95869 15 7.5 15C6.04131 15 4.64236 14.4205 3.61091 13.3891C2.57946 12.3576 2 10.9587 2 9.5Z"/>
      <path d="M9 2.5C9 2.36739 9.05268 2.24021 9.14645 2.14645C9.24022 2.05268 9.36739 2 9.5 2C10.9587 2 12.3576 2.57946 13.3891 3.61091C14.4205 4.64236 15 6.04131 15 7.5C15 7.63261 14.9473 7.75979 14.8536 7.85355C14.7598 7.94732 14.6326 8 14.5 8H9.5C9.36739 8 9.24022 7.94732 9.14645 7.85355C9.05268 7.75979 9 7.63261 9 7.5V2.5Z"/>
    </svg>
  )
}