import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '../components'
import { history, IF } from '../helpers'
import { popupError, popupSuccess } from '../layout/img/image'
import { modalAlertaInfo, PosCheckOutXD } from '../layout/redux/layoutActions'

export default ({}) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const { alerta, sistema, statusModal } = useSelector(state => state.layoutState)
  const nlsPopup = require(`../layout/nlsPopup/${sistema.idioma}.json`)

  useEffect(()=> {
    const query = new URLSearchParams(location.search);
    const searchTerm = query.get('session_id');
    if (searchTerm) {
      console.error(searchTerm, 'searchTermsearchTermsearchTermsearchTermsearchTerm');
      dispatch(PosCheckOutXD(searchTerm))
    }
  }, [history.location])

  useEffect(()=> {
    dispatch(modalAlertaInfo({
      ...nlsPopup.checkoutsucesso, 
      show: false,
      btnAction: ()=> history.push('/minhasassinaruras'), 
      textos: {}
    }))
  }, [])

  const textoCustom = (e, l) => {
    let texto = e
    if (l) {
      Object.keys(l).map(c=> {
        texto = texto.replace(`{{${c}}}`, l[c])
      })
    }
    return texto
  }

  const imgCustom = e => {
    switch (e.type) {
      case 'error':
        return popupError();
      default:
        return popupSuccess();
    }
  }
  
  return (
  <div className='page-alert box-content'>
    {alerta.type && <div className='modal-modalAlerta-img'>{imgCustom(alerta)}</div> }
    <strong>{alerta.titulo}</strong>
    <p>{textoCustom(alerta.texto, alerta.textos)}</p>
    <IF se={alerta.btn && alerta.btnAction}>
      <div className='modalAlerta-btns'>
        <Button
          type='btn block'
          color='primary'
          onClick={alerta.btnAction}
        >{alerta.btn}</Button>
      </div>
    </IF>
  </div>
  )
}
