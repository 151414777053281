import React, { useEffect } from 'react'
import { history } from '../helpers';

export default () => {
  useEffect(()=> {
    setTimeout(() => {
      history.push('/meusprojetos')
    }, 3000);
  }, [])
  return (
    <div className='box-telaDesenvolvimento'>
      <h1>Esta tela <strong>NÃO EXISTE.</strong></h1>
      <small>Estamos te retornando a uma rota existente</small>
    </div>
  )
}
