import { PaginateTotal, adicionaZero, dateCustom } from '../../../components';
import { capitalize, cicloIntervalo, numberZero, paramsApi } from '../../../helpers';
import api from '../../../helpers/api';
import apiOld from '../../../helpers/apiOld';
import { setFiltered, setInfoCategoria, setInfoEmpresa, setListarCategorias } from '../../../layout/redux/layoutActions';
import { nomesMeses } from '../../../layout/redux/layoutReducer';

const  generateDataName = (name, yrange) => {
    let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    return { x: name, y: y };
}
const nLojas = 7
const pagina = { 
  pageNumber: 1, 
  totalPages: 1, 
  totalElements: 0,
  perPage: 7
}

const listasMock = {
  data: Array(nLojas).fill().map((_, i)=> ({id: i+1, loja: `Loja ${i+1}`, comentario: i+1})),
  paginate: {...pagina, totalElements: nLojas, totalPages: PaginateTotal({total: nLojas, totalPerPage: pagina.perPage }) }
}

export const setInfoTotalAvaliacao = payload => ({
  type: 'COMENTARIOS_TOTAL_AVALIACAO',
  payload
})

export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_COMENTARIOS',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_COMENTARIOS',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_COMENTARIOS',
})


export const setListaComentarios = payload => ({
  type: 'LIST_COMENTARIOS',
  payload
})

export const setPaginateComentarios = payload => ({
  type: 'PAGINATE_COMENTARIOS',
  payload
})

export const setLoadList = payload => ({
  type: 'LOAD_LIST_COMENTARIOS',
  payload
})

// export const listarComentarios = e => {
//   return dispatch => dispatch([
//     setListaComentarios(listasMock.data),
//     setPaginateComentarios(listasMock.paginate)
//   ])
// }

// export const filterHandle = ({projetoID, params, filterActive, typeSelect, paginate}) => {
//   return dispatch => dispatch([
//     setFiltered(params),
//     setInfoEmpresa(params.empresa),
//     setInfoCategoria(params.categoria),
//     listarComentarios(),
//   ])
// }

export const filtersCustom = ({filters, typeSelect}) => {
  let paramsFilter = {}
  const dataAtual = new Date()
  if (typeSelect?.id === 'Ciclo') {
    if (filters?.ciclo?.mesAno) {
      paramsFilter = {
        intervalo: `${filters.ciclo.anoMes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(filters.dataInicial).data} ${dateCustom(filters.dataFinal).data}`,
    }
  }
  if (filters?.empresa?.empresaId) {
    paramsFilter = {
      ...paramsFilter,
      empresaId: filters.empresa.empresaId,
    }
  }
  if (filters?.categoria?.id) {
    paramsFilter = {
      ...paramsFilter,
      categoriaId: filters.categoria.id,
    }
  }
  return paramsFilter
}

export const listarComentarios = ({
  projetoID, 
  grupo,
  filters={}, 
  typeSelect={}, 
  paginate = {pageNumber: 1, perPage: 7}
}) => {
  
  return dispatch => {
    dispatch(setLoadList(true))

    let params = paramsApi({
      projetoId: projetoID,
      paginaAtual: paginate.pageNumber?paginate.pageNumber:1,
      RPP: paginate.perPage?paginate.perPage:7,
      grupo: grupo.name,
      ...filters,
    })

    apiOld.get(`/Front/GetComentarios${params}`)
    .then(result => {
      const initialStatePaginate = { 
        pageNumber: paginate.pageNumber?paginate.pageNumber:1, 
        totalPages: result.data.totalPaginas,  
        totalElements: result.data.totalRegistros, 
        perPage: result.data.rpp
      }
      dispatch([
        setListaComentarios(result.data.listaDeComentariosGrupoEmpresa),
        setPaginateComentarios(initialStatePaginate),
        setInfoTotalAvaliacao(result.data.qtdeAvaliacoes),
        setListarCategorias(result.data.listaCategorias),
        setLoadList(false)
      ])
    })
    .catch(err => {
        console.error(err);
        const initialStatePaginate = { 
          pageNumber: 1, 
          totalPages: 1,  
          totalElements: 0, 
          perPage: paginate.perPage
        }
        dispatch([
          setListaComentarios([]),
          setPaginateComentarios(initialStatePaginate),
          setInfoTotalAvaliacao(0),
          setLoadList(false)
        ])
    });
  }
}

export const filterHandle = ({
  projetoID, 
  grupo,
  params, 
  filtersProvisorio, 
  typeSelect, 
  paginate
}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  let paramsFilter = filtersCustom({filters: paramsCustom, typeSelect})
  return dispatch => dispatch([
    setFiltered(paramsCustom),
    listarComentarios({projetoID, grupo, filters: paramsFilter, paginate, typeSelect}),
  ])
}