import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List } from '../../components';
import { filterHandle, listarComentarios } from './redux/comentariosActions';

export default () => {
  const dispatch = useDispatch()
  const { grupo, filters } = useSelector(state=> state.layoutState)
  const { projeto, filtersProvisorio, comentarios, paginate } = useSelector(state=> state.comentariosState)
  const [sortColumn, setSortColumn] = useState({})

  const headerDefault = [
    {
      column: 'loja',
      text: 'LOJA',
      className: 'actions',
    },
    {
      column: 'comentario',
      text: 'COMENTÁRIO',
      className: '',
    }
  ]

  const handlePaginate = (e) => {
    dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate: {...paginate, pageNumber: Number(e)}}))
  }

  const handlePerPage = (e) => {
    dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate: {...paginate, pageNumber: 1, perPage: Number(e.value.id)}}))
  }

  const handleSort = (e) => {
    setSortColumn(e)
    dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate: {...paginate}}))
  }

  return (
    <>
      <div className='box-basededados-tabela box-content'>
        <div className="box-tabela-content">
          <List 
            header={headerDefault}
            data={comentarios}
            paginate={paginate}
            sort={sortColumn}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            handleSort={(e)=> handleSort(e)}
          />
        </div>
      </div>
    </>
  )
}
