import axios from 'axios';
import { gerarLista } from './centralAjuda';

export const getInfoCentralAjuda = payload => ({
  type: 'GET_INFO_CENTRAL_AJUDA',
  payload
})

export const setOpenCentralAjuda = payload => ({
  type: 'SET_OPEN_CENTRAL_AJUDA',
  payload
})

export const setCloseCentralAjuda = payload => ({
  type: 'SET_OPEN_CENTRAL_AJUDA',
  payload: false
})

export const listarCentralAjuda = e => {
  return dispatch => {
    dispatch(getInfoCentralAjuda(gerarLista()))
  }
}

export const setSuporteCentralAjuda = payload => ({
  type: 'SET_SUPORTE_CENTRAL_AJUDA',
  payload
})

export const cleanSuporteCentralAjuda = payload => ({
  type: 'CLEAN_SUPORTE_CENTRAL_AJUDA',
  payload
})