import React from 'react'

export const IcoPasteArchive = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-paste' viewBox="0 0 27 30" {...props} style={{...style,fill:'none'}}>
      <path d="M9.125 15H14.5938M9.125 19.375H14.5938M9.125 23.75H14.5938M18.9688 24.8438H22.25C23.1202 24.8438 23.9548 24.498 24.5702 23.8827C25.1855 23.2673 25.5313 22.4327 25.5313 21.5625V6.4075C25.5313 4.75229 24.299 3.34792 22.6496 3.21083C22.1042 3.1656 21.5582 3.12671 21.0119 3.09417M21.0119 3.09417C21.1086 3.40783 21.1563 3.73424 21.1563 4.0625C21.1563 4.35258 21.041 4.63078 20.8359 4.8359C20.6308 5.04102 20.3526 5.15625 20.0625 5.15625H13.5C12.8963 5.15625 12.4063 4.66625 12.4063 4.0625C12.4063 3.72563 12.4573 3.40042 12.5521 3.09417M21.0119 3.09417C20.5992 1.75542 19.3508 0.78125 17.875 0.78125H15.6875C14.9864 0.781415 14.3038 1.00604 13.7395 1.42225C13.1753 1.83845 12.7592 2.42435 12.5521 3.09417M12.5521 3.09417C12.0038 3.12771 11.4583 3.16708 10.9129 3.21083C9.26354 3.34792 8.03125 4.75229 8.03125 6.4075V9.53125M8.03125 9.53125H3.10938C2.20375 9.53125 1.46875 10.2663 1.46875 11.1719V27.5781C1.46875 28.4838 2.20375 29.2188 3.10938 29.2188H17.3281C18.2338 29.2188 18.9688 28.4838 18.9688 27.5781V11.1719C18.9688 10.2663 18.2338 9.53125 17.3281 9.53125H8.03125ZM5.84375 15H5.85542V15.0117H5.84375V15ZM5.84375 19.375H5.85542V19.3867H5.84375V19.375ZM5.84375 23.75H5.85542V23.7617H5.84375V23.75Z" stroke='currentColor' strokeWidth="2" />
    </svg>

  )
}
