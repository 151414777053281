import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, MaskData, Modal } from '../../../components'
import { modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions'
import { AtualizarAssinaturaProjetoProntoXD, listaAlterarAssinatura, listarAtivos } from '../redux/minhasAssinaturasActions'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)  
  const { assinatura, listas } = useSelector(state=> state.minhasAssinaturasState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [selected, setSelected] = useState({})

  useEffect(()=> {
    if (statusModal === 'assinaturas-alterar' && assinatura.idProjetoPronto) {
      dispatch(listaAlterarAssinatura({id: assinatura.idProjetoPronto, moeda: usuario.moedaIso4217}))
    }
  }, [statusModal, assinatura.idProjetoPronto])

  const handleCancelar = e => {
    dispatch(modalClose())
  }
  
  const handleConcordar = e => {
    console.warn(e, 'handleConcordar');

    const params = {
      projetoId: assinatura.idProjetoPronto,
      idPeriodo: e.idPeriodo,
      moeda: usuario.moedaIso4217,
      dataAgendadaAlteracao: assinatura.dataRenovacaoAssinatura
    }
    dispatch(AtualizarAssinaturaProjetoProntoXD(params, nlsPopup))
  }

  const handleSalvar = e => {
    console.warn(e, 'assinaturas-alterar');
    if (e.idPeriodo) {
      dispatch(modalAlertaInfo({
        ...nlsPopup['2'], 
        btnAction: ()=> handleConcordar(e, 'assinaturas-economizar'),
        show: true,
        textos: {
          DataRenovacaoAssinatura: MaskData(assinatura.dataRenovacaoAssinatura, 'DD/MM/YYYY', sistema.idioma),
        }
      }))
      handleCancelar()
    }
  }
  
  
  return (
    <Modal
      name='assinaturas-alterar'
      title={nls.titleAlterarAssinatura}
      open={statusModal === 'assinaturas-alterar' ? true : false}
      close={() => handleCancelar()}
      closeText={nls.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCancelar()}
        >{nls.cancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar(selected)}
        >{nls.salvarAssinatura}</Button>
      </>}

    >
      <div className='assinaturas-lista'>
        <div className='box-scrool'>
          <table className='list-box'>
            <thead>
              <tr>
                <td>{nls.periodo}</td>
                <td>{nls.valor}</td>
              </tr>
            </thead>
            <tbody>
              {listas?.alterarAssinaturas?.map(e=> {
                return <tr key={e.idPeriodo} className={`assinaturas-lista-item ${e.idPeriodo === selected.idPeriodo ? 'ativo': ''}`} onClick={c=> setSelected(e)}>
                  <td>{e.descricao}</td>
                  <td>{e.valor}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
