import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, Loading } from '../../components';
import { filterHandle, listarDadosProjeto, setFiltered } from './redux/basededadosActions';
import { capitalize } from '../../helpers';

export default () => {
  const dispatch = useDispatch()
  const { filters } = useSelector(state=> state.layoutState)
  const { 
    loadList, 
    projeto, 
    dadosProjeto, 
    paginate,
    filtersProvisorio, 
  } = useSelector(state=> state.basededadosState)
  
  const [sortColumn, setSortColumn] = useState({})

  const headerDefault = [
    {
      column: 'cidade',
      text: 'CIDADE',
      className: '',
    },
    {
      column: 'estado',
      text: 'ESTADO',
      sort: '',
      className: '',
    },
    {
      column: 'loja',
      text: 'LOJA',
      sort: '',
      className: '',
    },
    {
      column: 'grupoLoja',
      text: ' GRUPO DE LOJA',
      sort: '',
      className: '',
    },
    {
      column: 'empresa',
      text: 'EMPRESA',
      sort: '',
      className: '',
    }
  ]

  const handlePaginate = (e) => {
    dispatch(filterHandle({
      projetoID: projeto.id, 
      params: filters, 
      typeSelect: filters.typeSelect,
      filtersProvisorio,
      paginate: {...paginate, pageNumber: Number(e)}
    }))
  }

  const handlePerPage = (e) => {
    dispatch(filterHandle({
      projetoID: projeto.id, 
      params: filters, 
      typeSelect: filters.typeSelect,
      filtersProvisorio,
      paginate: {...paginate, perPage: Number(e.value.id)}
    }))
  }
  const handleSort = (e) => {
    const infoOrderby = {
      up: 'Desc',
      down: 'ASC'
    }
    let filteredCustom = {...filters}
    setSortColumn(e)
    if (e.type === 'normal') {
      delete filteredCustom.Coluna
      delete filteredCustom.Ordem
    } else {
      filteredCustom = {
        ...filteredCustom,
        Coluna: capitalize(e.column),
        Ordem: infoOrderby[e.type]
      }
    }
    
    dispatch([
      filterHandle({
        projetoID: projeto.id, 
        params: filteredCustom, 
        typeSelect: filters.typeSelect,
        filtersProvisorio,
        paginate: {...paginate}
      })
    ])
  }

  return (
    <>
      <div className='box-basededados-tabela box-content'>
        <div className="box-tabela-content">
          {
            loadList?
            <Loading type='element' title='' height='300px' />
            :
            <List 
              header={headerDefault}
              data={dadosProjeto}
              paginate={paginate}
              sort={sortColumn}
              actionPaginate={(e)=> handlePaginate(e)}
              handlePerPage={(e)=> handlePerPage(e)}
              handleSort={(e)=> handleSort(e)}
            />
          }
        </div>
      </div>
    </>
  )
}