import React from 'react'

export const IcoStar = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-star' viewBox="0 0 18 17" {...props} style={{...style,fill:'none'}}>
      <path d="M8.56669 1.91584C8.60194 1.82987 8.66196 1.75632 8.73912 1.70456C8.81629 1.65279 8.90711 1.62515 9.00002 1.62515C9.09294 1.62515 9.18376 1.65279 9.26092 1.70456C9.33809 1.75632 9.39811 1.82987 9.43336 1.91584L11.2042 6.17501C11.2373 6.2547 11.2918 6.32371 11.3617 6.37443C11.4315 6.42516 11.514 6.45563 11.6 6.46251L16.1984 6.83084C16.6142 6.86417 16.7825 7.38334 16.4659 7.65417L12.9625 10.6558C12.8971 10.7118 12.8483 10.7848 12.8215 10.8666C12.7948 10.9485 12.7911 11.0362 12.8109 11.12L13.8817 15.6075C13.9032 15.6975 13.8976 15.7919 13.8655 15.8788C13.8334 15.9656 13.7763 16.041 13.7014 16.0953C13.6265 16.1497 13.5371 16.1807 13.4446 16.1844C13.3521 16.188 13.2606 16.1642 13.1817 16.1158L9.24419 13.7117C9.17064 13.6668 9.08616 13.6431 9.00002 13.6431C8.91389 13.6431 8.82941 13.6668 8.75586 13.7117L4.81836 16.1167C4.73941 16.165 4.6479 16.1888 4.5554 16.1852C4.46291 16.1815 4.37357 16.1506 4.29866 16.0962C4.22376 16.0418 4.16666 15.9664 4.13457 15.8796C4.10248 15.7928 4.09683 15.6984 4.11836 15.6083L5.18919 11.12C5.20905 11.0362 5.2054 10.9485 5.17864 10.8666C5.15188 10.7847 5.10305 10.7118 5.03752 10.6558L1.53419 7.65417C1.46404 7.59385 1.41328 7.51413 1.38832 7.42503C1.36335 7.33594 1.36528 7.24146 1.39387 7.15346C1.42246 7.06546 1.47643 6.98789 1.549 6.93048C1.62156 6.87308 1.70948 6.83841 1.80169 6.83084L6.40002 6.46251C6.48607 6.45563 6.56854 6.42516 6.63838 6.37443C6.70822 6.32371 6.76271 6.2547 6.79586 6.17501L8.56669 1.91584Z" stroke='currentColor' strokeWidth="1.5" />
    </svg>

  )
}