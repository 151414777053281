import axios from 'axios';

const apiOld = axios.create({
  baseURL: `${process.env.REACT_APP_INSV_API_OLD}`
});
apiOld.interceptors.request.use(async (config) => {
  const token = localStorage.tolkenINSV;

  if (token)
    config.headers.authorization = `${token}`;

  return config;
});

apiOld.interceptors.response.use(
  (response) => {
    return response;
  },
  
  (err) => Promise.reject(err)
);

export default apiOld