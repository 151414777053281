import { PaginateTotal } from '../../../components'

const paginateInit = {
  pageNumber: 1, 
  totalPages: 1, 
  totalElements: 0,
  perPage: 7
}

const initialState = {
  usuarios: [],
  paginate: paginateInit,
  usuario: {
    nome: '',
    email: '',
    idioma: {},
    perfil: {},
    permissoes: {},
  },
  permissoes: [],
  load: {},
  erro: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_USUARIOS':
      return { ...state, 
        usuarios: payload.usuarios, 
        paginate: payload.paginacao
      }
    case 'SET_USUARIOS_PAGINATE':
      return { ...state, paginate: payload }
    case 'CLEAN_USUARIOS_PAGINATE':
      return { ...state, paginate: paginateInit }
    case 'POST_USUARIO':
      return { ...state, usuarios: [payload, ...state.usuarios] }
    case 'PUT_USUARIO':
      return { ...state, usuarios: state.usuarios.map(e=> String(e.id) === String(payload.id)? {...e, ...payload} : e) }
    case 'DELETE_USUARIO':
      return { ...state, usuarios: state.usuarios.filter(e=> String(e.uniqueId) !== String(payload.userId)) }
      
    case 'GET_USUARIO':
      return { ...state, usuario: payload, permissoes: payload.permissoes,  }
    case 'SET_USUARIO':
      return { ...state, usuario: {...state.usuario, [payload.name]: payload.value} }
    case 'CLEAN_USUARIO':
      return { ...state, usuario: initialState.usuario }

    case 'LOAD_USUARIOS':
      return { ...state, load: {...state.load, [payload.name]: payload.value} }
    case 'ERROR_USUARIOS':
      return { ...state, erro: payload }
    default:
      return state
  }
}