import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Listwithicon } from './listwithicon/listwithicon';
import { filterHandle, listarComentarios } from './redux/comentariosActions';


export default () => {
  const dispatch = useDispatch()
  const { grupo, filters, sistema } = useSelector(state=> state.layoutState)
  const { projeto, filtersProvisorio, comentarios, paginate, sobreComentario } = useSelector(state=> state.comentariosnovosState)
  const [sortColumn, setSortColumn] = useState({})

  const nls = require(`./nls/${sistema.idioma}.json`)
  const semdadosparaexibir = nls.semdadosparaexibir;
  const erronaapi = nls.erronaapi;

  const tipo = sobreComentario.tipo;

  const headerLoja = [
    {
      column: 'loja',
      text: nls.estabelecimento,
      className: 'actions',
    },
    {
      column: 'comentario',
      text: nls.comentario,
      className: '',
    },
    {
      column: 'canal',
      text: nls.canal,
      className: '',
    }
  ]


  const headerProduto = [
    {
      column: 'produto',
      text: nls.produto,
      className: 'actions',
    },
    {
      column: 'comentario',
      text: nls.comentario,
      className: '',
    },
    {
      column: 'canal',
      text: nls.canal,
      className: '',
    }
  ]
  

  const headerEmpresa = [
    {
      column: 'canal',
      text: nls.empresa,
      className: 'actions',
    },
    {
      column: 'comentario',
      text: nls.comentario,
      className: '',
    },
    {
      column: 'post',
      text: nls.post,
      className: '',
    }
  ]

  const handlePaginate = (e) => {
    dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate: {...paginate, pageNumber: Number(e)}, idioma: sistema.idiomaIdValue, tipo: tipo}))
  }

  const handlePerPage = (e) => {
    dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate: {...paginate, pageNumber: 1, perPage: Number(e.value.id)}, idioma: sistema.idiomaIdValue, tipo: tipo}))
  }

  const handleSort = (e) => {
    setSortColumn(e)
    dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate: {...paginate}, idioma: sistema.idiomaIdValue, tipo: tipo}))
  }

  console.error(paginate.pageNumber, 'PAGINA ATUAL TABELA');


  //const header = tipo === "produtos" ? headerProduto : headerLoja;
  const header = tipo === "produtos" ? headerProduto : tipo === "lojas" ? headerLoja : headerEmpresa;
  
  console.error(comentarios, 'DADOS EM TABELA.JS')

  return (
    <>
      <div className='box-basededados-tabela box-content'>
        <div className="box-tabela-content">
          <Listwithicon
            header={header}
            tipo={tipo}
            data={comentarios}
            semdadosparaexibir={semdadosparaexibir}
            erronaapi={erronaapi}
            paginate={paginate}
            sort={sortColumn}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            handleSort={(e)=> handleSort(e)}
            nlsgeral={nls}
          />
        </div>
      </div>
    </>
  )
}
