import moment from "moment/moment";

export const adicionaZero = (numero) => {
  if (numero <= 9) return `0${numero}`;
  else return numero;
};

export const MaskTelefone = (valor) => {
  valor = valor || '';
  let valueNew = '';
  if (valor.length <= 10) {
    valueNew = valor
      .replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/(.{3})(\d)/, '$1)$2')
      .replace(/(.{4})(\d)/, '$1 $2')
      .replace('-', '')
      .replace(/(\d{4})(\d)/, '$1-$2');
  } else if (valor.length > 10) {
    valueNew = valor
      .replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/(.{3})(\d)/, '$1)$2')
      .replace(/(.{4})(\d)/, '$1 $2')
      .replace('-', '')
      .replace(/(\d{5})(\d)/, '$1-$2');
  }
  return valueNew;
};

export const MaskTelefoneInt = (valor) => {
  valor = valor || '';
  let valueNew = '';
  if (valor.length <= 13) {
    valueNew = valor
      .replace(/\D/g, '')
      .replace(/^(\d)/, '+$1')
      .replace(/(.{3})(\d)/, '$1 $2')
      .replace(/(.{4})(\d)/, '$1($2')
      .replace(/(.{7})(\d)/, '$1)$2')
      .replace(/(.{8})(\d)/, '$1 $2')
      .replace(/(.{13})(\d)/, '$1-$2');
  } else if (valor.length > 13) {
    valueNew = valor
      .replace(/\D/g, '')
      .replace(/^(\d)/, '+$1')
      .replace(/(.{3})(\d)/, '$1 $2')
      .replace(/(.{4})(\d)/, '$1($2')
      .replace(/(.{7})(\d)/, '$1)$2')
      .replace(/(.{8})(\d)/, '$1 $2')
      .replace(/(.{14})(\d)/, '$1-$2');
  }
  return valueNew;
};

export const MaskItemCep = (valor) => {
  let valueNew = '';
  valueNew = valor.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2');
  return valueNew;
};

export const MaskCpf = (valor) => {
  let valueNew = '';
  valueNew = valor
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');

  return valueNew;
};

const lenguageList = {
  "pt-BR": { style: 'currency', currency: 'BRL' }
}
export const MaskValorMoedaex = (valor, language = "pt-BR", int=false) => {
  valor = String(valor)
  if(valor?.includes('R$ ')){
    valor = valor.replace('R$ ', '').replace('.', '').replace('.', '').replace('.', '');
  }
  if (int) {
    valor = valor.replace('.', ',').split(',')
    const formatter = new Intl.NumberFormat(language, lenguageList[language]);
    const val = formatter.format(valor[0]);
    return val;
  } else {
    let valorSplit = valor
    if (valor?.includes('.')) {
      valorSplit = valor.replace('.', ',').split(',')
    } else {
      valorSplit = `${valor},00`.split(',')
    }
    valor = Number(`${valorSplit[0]}.${valorSplit[1]}`)
    const formatter = new Intl.NumberFormat(language, lenguageList[language]);
    const val = formatter.format(valor);
    return val;
  }
};

export const MaskValor = (valor, language = "pt-BR" ) => {
  let v = valor.toLocaleString(language)
  return v
}

export const MaskData = (valor, format= '', language = "pt-BR" ) => {
  if (!format && language === "pt-BR") {
    format = 'DD/MM/YYYY - HH:mm'
  }  
  if (!format && language === "en-US") {
    format = 'MM/DD/YYYY - HH:mm'
  }  
  if (!format && language === "esp") {
    format = 'DD/MM/YYYY - HH:mm'
  }
  let data =  moment(valor).format(format)
  return data;
}