import React from 'react'

export const IcoProjeto2 = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-projeto' viewBox="0 0 17 17" {...props} style={{...style}}>
      <path d="M2.75 2.5C2.05933 2.5 1.5 3.06 1.5 3.75V4.25C1.5 4.94067 2.06 5.5 2.75 5.5H14.25C14.94 5.5 15.5 4.94 15.5 4.25V3.75C15.5 3.05933 14.94 2.5 14.25 2.5H2.75Z"/>
      <path d="M2.55811 6.5L2.91811 12.6173C2.94802 13.1264 3.17123 13.6047 3.54211 13.9547C3.91299 14.3046 4.40353 14.4997 4.91344 14.5H12.0848C12.5949 14.5 13.0858 14.3051 13.4569 13.9551C13.8281 13.6052 14.0515 13.1266 14.0814 12.6173L14.4421 6.5H2.55811ZM6.66677 9C6.66677 8.86739 6.71945 8.74022 6.81322 8.64645C6.90699 8.55268 7.03416 8.5 7.16677 8.5H9.83344C9.96605 8.5 10.0932 8.55268 10.187 8.64645C10.2808 8.74022 10.3334 8.86739 10.3334 9C10.3334 9.13261 10.2808 9.25978 10.187 9.35355C10.0932 9.44732 9.96605 9.5 9.83344 9.5H7.16677C7.03416 9.5 6.90699 9.44732 6.81322 9.35355C6.71945 9.25978 6.66677 9.13261 6.66677 9Z"/> 
    </svg>
  )
}