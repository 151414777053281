import React from 'react'

export const IcoMoney = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-money' viewBox="0 0 31 26" {...props} style={{...style,fill:'none'}}>
      <path d="M1.28125 21.8437C9.06234 21.8374 16.8096 22.8678 24.3185 24.9077C25.3788 25.1965 26.4375 24.409 26.4375 23.3094V21.8437M3.46875 1.0625V2.15625C3.46875 2.44633 3.35352 2.72453 3.1484 2.92965C2.94328 3.13477 2.66508 3.25 2.375 3.25H1.28125M1.28125 3.25V2.70312C1.28125 1.7975 2.01625 1.0625 2.92188 1.0625H27.5313M1.28125 3.25V16.375M27.5313 1.0625V2.15625C27.5313 2.76 28.0213 3.25 28.625 3.25H29.7188M27.5313 1.0625H28.0781C28.9838 1.0625 29.7188 1.7975 29.7188 2.70312V16.9219C29.7188 17.8275 28.9838 18.5625 28.0781 18.5625H27.5313M1.28125 16.375V16.9219C1.28125 17.357 1.4541 17.7743 1.76178 18.082C2.06945 18.3896 2.48675 18.5625 2.92188 18.5625H3.46875M1.28125 16.375H2.375C2.66508 16.375 2.94328 16.4902 3.1484 16.6954C3.35352 16.9005 3.46875 17.1787 3.46875 17.4687V18.5625M27.5313 18.5625V17.4687C27.5313 17.1787 27.6465 16.9005 27.8516 16.6954C28.0567 16.4902 28.3349 16.375 28.625 16.375H29.7188M27.5313 18.5625H3.46875M19.875 9.8125C19.875 10.9728 19.4141 12.0856 18.5936 12.9061C17.7731 13.7266 16.6603 14.1875 15.5 14.1875C14.3397 14.1875 13.2269 13.7266 12.4064 12.9061C11.5859 12.0856 11.125 10.9728 11.125 9.8125C11.125 8.65218 11.5859 7.53938 12.4064 6.71891C13.2269 5.89844 14.3397 5.4375 15.5 5.4375C16.6603 5.4375 17.7731 5.89844 18.5936 6.71891C19.4141 7.53938 19.875 8.65218 19.875 9.8125ZM24.25 9.8125H24.2617V9.82417H24.25V9.8125ZM6.75 9.8125H6.76167V9.82417H6.75V9.8125Z" stroke='currentColor' strokeWidth="2" />
    </svg>

  )
}