import React from 'react'
import { IcoCog, IcoComentarios, IcoDados, IcoEmpresa, IcoEnvelope, IcoGrupos, IcoList, IcoLogOut, IcoPriceTag, IcoUsers } from "../components"
import { IcoRedes } from "../components/icon/custom/icoredes"
import { IcoProdutos } from "../components/icon/custom/icoprodutos"
import { IcoPlanos } from "../components/icon/custom/icoplanos"

export const menuItensHeader = (permissoes, nls) => {
  return [
    ...permissoes?.VerFazerUpgrade ?[
      { 
        id: "VerFazerUpgrade", 
        label: nls.menus.fazerUpgrade, 
        go: "/upgradeconta", 
        icon: '' 
      },
    ] : [],
    { 
      id: "visualizarProjeto", 
      label: nls.menus.visualizar, 
      go: "/meusprojetos", 
      icon: '' 
    },
    { 
      id: "ConfigurarProjeto", 
      label: nls.menus.configurar, 
      go: "/meusprojetos/novo/configurar", 
      icon: '' 
    },
    // ...e?.creditoProjetos?[{ 
    //   id: "CriarProjeto", 
    //   label: nls.menus.criar, 
    //   go: "/meusprojetos", 
    //   icon: '' 
    // }]:[],
    { 
      id: "CriarProjeto", 
      label: nls.menus.criar, 
      go: "/meusprojetos", 
      icon: '' 
    },
    { 
      id: "ComprarProjeto", 
      label: nls.menus.comprar, 
      go: "/projetos", 
      icon: '' 
    },
  ]
}
  
  
export const menuItens = ({id, projeto}) => {
  return [
    { 
      id: "Resumodoprojeto", 
      label: "Resumo do projeto", 
      go: `/meusprojetos/${id}/resumoprojeto`, 
      icon: <IcoDados /> 
    },
    { 
      id: "planos", 
      label: "Planos", 
      icon: <IcoPlanos />,
      sub: [
        { 
          id: "Plano7Dias", 
          label: "Plano 7 Dias", 
          go: `/meusprojetos/${id}/pl7`,
        }   
      ]
    },
    ...projeto?.temMenuEmpresa ? [{ 
      id: "empresas", 
      label: "Empresas", 
      icon: <IcoEmpresa />,
      sub: [
        { 
          id: "CSAT", 
          label: "CSAT", 
          go: `/meusprojetos/${id}/csat`,
        },
        { 
          id: "NPS", 
          label: "NPS", 
          go: `/meusprojetos/${id}/nps`,
        },
        { 
          id: "CSATConcorrentes", 
          label: "CSAT: comparação com concorrentes", 
          go: `/meusprojetos/${id}/csatconcorrentes`,
        },
        { 
          id: "NPSConcorrentes", 
          label: "NPS: comparação com concorrentes", 
          go: `/meusprojetos/${id}/npstconcorrentes`,
        }
      ]
    }] : [],

    { 
      id: "redessociais", 
      label: "Redes Sociais", 
      icon: <IcoRedes />,
      sub: [
        { 
          id: "CSATREDES", 
          label: "CSAT", 
          go: `/meusprojetos/${id}/csatredescsatcliente`,
        },
        { 
          id: "NPSREDES", 
          label: "NPS", 
          go: `/meusprojetos/${id}/npsredescsatcliente`,
        },
        { 
          id: "CSATConcorrentesRedes", 
          label: "CSAT: comparação com concorrentes", 
          go: `/meusprojetos/${id}/csatredesconcorrentes`,
        },
        { 
          id: "NPSConcorrentesRedes", 
          label: "NPS: comparação com concorrentes", 
          go: `/meusprojetos/${id}/npsredesconcorrentes`,
        },
        { 
          id: "INDICADORESREDES", 
          label: "Indicadores", 
          go: `/meusprojetos/${id}/indicadoresredes`,
        }

      ]
    },

    ...projeto?.temMenuEstabelecimento ? [{ 
      id: "lojas", 
      label: "Lojas", 
      icon: <IcoGrupos />,
      sub: [
        { 
          id: "Resumodosgrupos", 
          label: "Resumo dos grupos", 
          go: `/meusprojetos/${id}/resumogrupos`,
        },
        { 
          id: "RankingClienteCSAT", 
          label: "CSAT dos estabelecimentos", 
          go: `/meusprojetos/${id}/csatestabelecimentos`,
        },
        { 
          id: "RankingClienteNPS", 
          label: "NPS dos estabelecimentos", 
          go: `/meusprojetos/${id}/npsestabelecimentos`,
        },
        { 
          id: "RankingCSAT", 
          label: "CSAT dos estabelecimentos: comparação com concorrentes", 
          go: `/meusprojetos/${id}/csatestabelecimentosconcorrentes`,
        },
        { 
          id: "RankingNPS", 
          label: "NPS dos estabelecimentos: comparação com concorrentes", 
          go: `/meusprojetos/${id}/npsestabelecimentosconcorrentes`,
        },
        { 
          id: "Indicadores", 
          label: "Indicadores", 
          go: `/meusprojetos/${id}/indicadores`,
        },
        { 
          id: "“AnáliseSWOT", 
          label: "Análise SWOT", 
          go: `/meusprojetos/${id}/analiseswot`,
        }
      ]
    }] : [],

    ...projeto?.temMenuProduto ? [{ 
      id: "Produtos", 
      label: "Produtos", 
      icon: <IcoProdutos />,
      sub: [
        { 
          id: "Resumodosgruposprodutos", 
          label: "Resumo dos grupos", 
          go: `/meusprojetos/${id}/resumogruposprodutos`,
        },
        { 
          id: "CSATPRODUTOS", 
          label: "CSAT", 
          go: `/meusprojetos/${id}/csatprodutoscsatcliente`,
        },
        { 
          id: "NPSPRODUTOS", 
          label: "NPS", 
          go: `/meusprojetos/${id}/npsprodutosnpscliente`,
        },
        { 
          id: "RankingClienteCSAT", 
          label: "CSAT dos produtos: comparação com concorrentes", 
          go: `/meusprojetos/${id}/csatprodutosconcorrentes`,
        },
        { 
          id: "RankingClienteNPS", 
          label: "NPS dos produtos: comparação com concorrentes", 
          go: `/meusprojetos/${id}/npsprodutosconcorrentes`,
        },
        { 
          id: "INDICADORESPRODUTOS", 
          label: "Indicadores", 
          go: `/meusprojetos/${id}/indicadoresprodutos`,
        },
        { 
          id: "“AnáliseSWOTProdutos", 
          label: "Análise SWOT", 
          go: `/meusprojetos/${id}/analiseswotprodutos`,
        }
      ]
    }] : [],

    { 
      id: "Comentarios", 
      label: "Comentários", 
      go: `/meusprojetos/${id}/comentarios`,
      icon: <IcoComentarios /> 
    },
    { 
      id: "ComentariosNovo", 
      label: "ComentáriosNovo", 
      go: `/meusprojetos/${id}/comentariosnovos`,
      icon: <IcoComentarios /> 
    }
  ]
}

export const menuUserInfo = (e, nls) => {
  return [
    { 
      id: "Dadoscadastro", 
      label: nls.menus.dadosCadastro, 
      action: "modalMeusDadosCadastro", 
      icon: <IcoCog /> 
    },
    ...e?.VerGerenciarUsuarios?[{ 
      id: "Gerenciarusuários", 
      label: nls.menus.gerenciarUsuarios,  
      go: "/usuarios", 
      icon: <IcoUsers /> 
    }]:[],
    ...e?.VerHistoricoFaturas?[{ 
      id: "Históricodefaturas", 
      label: nls.menus.historicoFaturas,
      icon: <IcoList />
    }]:[],
    ...e?.VerMinhasAssinaturas?[{ 
      id: "MinhasAssinaturas", 
      label: nls.menus.minhasAssinaturas, 
      go: "/minhasassinaruras", 
      icon: <IcoPriceTag /> 
    }]:[],
    { 
      id: "Suporte", 
      label: nls.menus.suporte, 
      action: "contatoSuporteModal", 
      icon: <IcoEnvelope /> 
    },
    { 
      id: "Sair", 
      label: nls.menus.sair, 
      icon: <IcoLogOut /> 
    },
  ]
}