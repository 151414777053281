import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoHelpCircleSvg, IcoQuestionCircleSvg, Modal } from '../components'

import { informeNaoMostrar, modalClose } from './redux/layoutActions'

export default ({}) => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const [informe, setInforme ] = useState({ title: '', content: '' })
  const nls = require(`./nls/${sistema.idioma}.json`)

  useEffect(()=> {
    setInforme(nls.modalInforme)
  }, [])
  
  const handleAcessar = (e) => {
    dispatch(modalClose())
  }
  
  const handleNaoMostrar = (e) => {
    dispatch([
      informeNaoMostrar(),
      modalClose()
    ])
  }

  const contentInforme = (e) => {
    return <>
      <p>{e.info1}</p>
      <ul>
        <li dangerouslySetInnerHTML={{__html: e.info2?.replace('{{IcoQuestionCircle}}', `${IcoQuestionCircleSvg()}`)}}></li>
        <li dangerouslySetInnerHTML={{__html: e.info3?.replace('{{IcoHelpCircle}}', `${IcoHelpCircleSvg()}`)}}></li>
      </ul>
      <div className='box-btns'>
        <Button
          type='btn block'
          color='primary'
          onClick={()=> handleAcessar()}
        >{e.continuar}</Button>
        <Button
          type='link block'
          color='primary'
          onClick={()=> handleNaoMostrar()}
        >{e.continuarCiente}</Button>
      </div>
    </>
  }
  return (
    <Modal
      title={informe?.title}
      size='medium'
      open={statusModal === 'modalInforme' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={informe?.closeText}
    >
      <div className='modal-modalInforme'>
        {contentInforme(informe)}
      </div>
    </Modal>
  )
}
