const initialStateFilters = {
  ciclo: {id: 1, name: 'Janeiro'},
  compararCiclos: {id: 'ativar', name: 'Ativar'},
  dataInicial: '',
  dataFinal: ''
}

const initialState = {
  projetos: [],
  projeto: {},
  cliente: {},
  grafico: [],
  graficoLoad: false,
  filtersProvisorio: initialStateFilters,
  totalAvaliacao: 0,
  tooltipAtual: [],
  tooltipAnterior: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LIST_PROJETOS':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, projetos: payload}
    case 'INFO_PROJETO':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, projeto: payload}
    case 'INFO_CLIENTE':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, cliente: payload}
    case 'INFO_FILTERS_CSATCONCORRENTESPRODUTOS':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, filtersProvisorio: payload}
    case 'CHANGE_FILTERS_CSATCONCORRENTESPRODUTOS':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, filtersProvisorio: {...state.filtersProvisorio, [payload.name]: payload.value}}
    case 'CLEAN_FILTERS_CSATCONCORRENTESPRODUTOS':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, filtersProvisorio: initialStateFilters}
    case 'LOAD_GRAFICO_CSATCONCORRENTESPRODUTOS':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, graficoLoad: payload}
    case 'INFO_GRAFICO_CSATCONCORRENTESPRODUTOS':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, grafico: payload}
    case 'INFO_GRAFICO_CSATCONCORRENTESPRODUTOS_TOTAL_AVALIACAO':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, totalAvaliacao: payload}
    case 'INFO_TOOLTIP_ATUAL':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, tooltipAtual: payload}
    case 'INFO_TOOLTIP_ANTERIOR':
      console.log('XXPrevious State:', state, 'Action:', type, 'Novo State: ', payload);
      console.log('Previous State:', state);
      console.log('Action1:', type);
      console.log('Novo State:', payload);
      return { ...state, tooltipAnterior: payload}
  
    default:
      return state
  }
}