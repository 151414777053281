import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoCalendar, IcoCicle, Select } from '../../../components'
import './filter.scss'
import FilterCiclo from './filterCiclo'
import FilterData from './filterData'
import { filterHandle, putFiltersProvisorio, setFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { projeto, filters, listas: {typeSearch}, empresas, categorias, ciclos, grupo } = useSelector(state=> state.layoutState)
  const { filtersProvisorio, paginate } = useSelector(state=> state.comentariosState)

  useEffect(()=> {
    let filtros = filters
    if (!filters?.ciclo?.anoMes  && ciclos.length) {
      filtros = {...filtros, ciclo: ciclos[ciclos.length - 1]}
    }
    dispatch(setFiltersProvisorio(filtros))
  }, [ciclos])

  const handleSearching = (params) => {
    dispatch(filterHandle({projetoID: projeto.id, grupo, params, typeSelect: params.typeSelect, filtersProvisorio, paginate}))
  }
  
  const contentFilterType = (e) => {
    switch (e?.id) {
      case 'Ciclo':
        return <FilterCiclo />;
      case 'Data':
        return <FilterData />;
      default:
        return;
    }
  }

  const handleFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
  return (
    <div className='box-comentarios-search box-content'>
      <div className='box-comentarios-search-box'>
        <div className='box-comentarios-search-type'>
          {
            typeSearch.map((type)=> {
              return <Button
                color={'primary'}
                type={type.id === filtersProvisorio?.typeSelect?.id?'btn':'link'}
                size='small'
                onClick={()=> dispatch(putFiltersProvisorio({name: 'typeSelect', value: type}))}
                key={type.id}
              >
                {type.label}
              </Button> 
            })
          }
          <p>{filtersProvisorio?.typeSelect?.text}</p>
        </div>
        <div>
          {contentFilterType(filtersProvisorio?.typeSelect)}
        </div>

      </div>
      <div className='box-comentarios-search-action'>
          <div className='box-comentarios-search-select'>
            <Select
              name='empresa'
              options={empresas}
              selected={filtersProvisorio.empresa}
              action={(e)=> handleFilter(e)}
              optionLabel='descricaoEmpresa'
              optionValue='empresaId'
              textCustom={['Todas empresas', 'Selecionado', 'Selecionados', 'Marcar todos']}
              // selectedItem={false}
            />
          </div>
          <div className='box-comentarios-search-select'>
            <Select
              name='categoria'
              options={categorias}
              selected={filtersProvisorio.categoria}
              action={(e)=> handleFilter(e)}
              optionLabel='descricao'
              optionValue='id'
              textCustom={['Todas categorias', 'Selecionado', 'Selecionados', 'Marcar todos']}
              // selectedItem={false}
            />
          </div>
        <Button
          color='primary'
          size='large'
          onClick={()=> handleSearching(filtersProvisorio)}
        >Pesquisar</Button>          
      </div>
    </div>
  )
}
