import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, validacaoForm, verificaValidacao } from '../../components'
import { modalClose } from '../../layout/redux/layoutActions'
import MeusDados from './meusDados'
import { cleanInfoMeusDados, salvarMeusDados, setMeusDadosErro } from './redux/meusDadosActions'

export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { erro, meusDados } = useSelector(state => state.meusDadosState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  const formRequired = {
    nome: '',
    sobrenome: '',
    email: 'email',
    celular: '',
    idioma: ''
  }

  const handleClose = e => {
    dispatch([
      cleanInfoMeusDados(),
      modalClose()
    ])
  }

  const handleSalvar = formValues => {
    const valid = validacaoForm({formValues, formRequired})
    dispatch(setMeusDadosErro(valid))
    const verify = verificaValidacao(valid)
    if (verify.value) {
      dispatch([
        salvarMeusDados(formValues),
      ])
    }
  }
  
  return (
    <Modal
      title={nls?.title}
      size='medium'
      open={statusModal === 'modalMeusDadosCadastro' ? true : false}
      close={() => handleClose()}
      closeText={nls?.closeText}
      actions={<>
        <Button
          onClick={()=> handleClose()}
        >{nls?.btnCancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar(meusDados)}
        >{nls?.btnSalvar}</Button>
      </>}
    >
      <div className='modal-modalMeusDadosCadastro'>
        <MeusDados />
      </div>
    </Modal>
  )
}
