import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, RadioButton, Select } from '../../../../components'
import { putFiltersProvisorio } from '../redux/rankingclientenpsActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.rankingclientenpsState)

  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }

  const compararCiclosList = [
   {
      id: 'desativar',
      name: 'Desativar',
   },
   {
      id: 'ativar',
      name: 'Ativar',
   }
  ]
  
  return (
   <div className='box-rankingclientenps-search rankingclientenps-search-ciclo'>
      <Select
         name='ciclo'
         label='Ciclo'
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
      <RadioButton
         name='compararCiclos'
         label='Comparar ciclos'
         options={compararCiclosList}
         checked={compararCiclos}
         action={(e)=> handleFormFilter(e)}
      />
   </div>
  )
}
