import React from 'react';
import Portal from '../portal/portal';

export const Loading = ({
  title = 'Carregando',
  icon = <div className="loader-default"></div>,
  type = 'full',
  height = 'auto'
}) => {
  const contentLoading = () => {
    return <div className="box-loading">
      <div className="box-load">
        {icon}
        {title ? <h5>{title}</h5> : null}
      </div>
    </div>
  }
  return type === 'full' ? (
    <Portal name="loading">
      {contentLoading()}
    </Portal>
  ):(
    <div className='box-loading-element' style={{height}}>
      {contentLoading()}
    </div>
  )
};

export const LoadingContent = ({
  show = false,
  title = 'Carregando',
  icon = <div className="loader-default"></div>,
  type = 'full',
  height = 'auto',
  children,
}) => {
  const contentLoading = () => {
    return <div className="box-loading">
      <div className="box-load">
        {icon}
        {title ? <h5>{title}</h5> : null}
      </div>
    </div>
  }
  return show ? (
    <div className='box-loading-element' style={{height}}>
      {contentLoading()}
    </div>
  ):( children )
};
