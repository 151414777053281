import React from 'react'

export const IcoQuestionCircle = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-question-circle' viewBox="0 0 20 20" {...props} style={{...style}}>
      <path d="M10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10v0c5.523 0 10 4.477 10 10s-4.477 10-10 10v0zM12 7c0 0.28-0.21 0.8-0.42 1l-1.58 1.58c-0.57 0.58-1 1.6-1 2.42v1h2v-1c0-0.29 0.21-0.8 0.42-1l1.58-1.58c0.57-0.58 1-1.6 1-2.42 0-2.209-1.791-4-4-4s-4 1.791-4 4v0h2c0-1.105 0.895-2 2-2s2 0.895 2 2v0zM9 15v2h2v-2h-2z"></path>
    </svg>
  )
}

export const IcoQuestionCircleSvg = (e) => {
  const style = {...e, fontSize:'14px'}
  return `<svg className='icon-question-circle' viewBox="0 0 20 20" style="font-size: ${style.fontSize}; display: inline-block; width: 1em; height: 1em; stroke-width: 0; stroke: currentColor; fill: currentColor;"">
    <path d="M10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10v0c5.523 0 10 4.477 10 10s-4.477 10-10 10v0zM12 7c0 0.28-0.21 0.8-0.42 1l-1.58 1.58c-0.57 0.58-1 1.6-1 2.42v1h2v-1c0-0.29 0.21-0.8 0.42-1l1.58-1.58c0.57-0.58 1-1.6 1-2.42 0-2.209-1.791-4-4-4s-4 1.791-4 4v0h2c0-1.105 0.895-2 2-2s2 0.895 2 2v0zM9 15v2h2v-2h-2z"></path>
  </svg>`
}