import { combineReducers } from 'redux'
import alerts from '../components/alert/alertsRedux'
import layoutState from '../layout/redux/layoutReducer'

import questionarioState from '../modules/questionario/redux/questionarioReducer'

import meusProjetosState from '../modules/meusProjetos/redux/meusProjetosReducer'
import minhasAssinaturasState from '../modules/minhasAssinaturas/redux/minhasAssinaturasReducer'
import meusDadosState from '../modules/meusDados/redux/meusDadosReducer'
import usuariosState from '../modules/usuarios/redux/usuariosReducer'
import centralAjudaState from '../modules/centralAjuda/redux/centralAjudaReducer'
import projetosState from '../modules/projetos/redux/projetosReducer'
import upgradeContaState from '../modules/upgradeConta/redux/upgradeContaReducer'

import homeState from '../modules/home/redux/homeReducer'

import loginState from '../modules/login/redux/loginReducer'
import basededadosState from '../modules/basededados/redux/basededadosReducer'

import csatclienteState from '../modules/empresa/csatcliente/redux/csatclienteReducer'
import npsclienteState from '../modules/empresa/npscliente/redux/npsclienteReducer'
import rankingclientecsatState from '../modules/empresa/rankingclientecsat/redux/rankingclientecsatReducer'
import rankingclientenpsState from '../modules/empresa/rankingclientenps/redux/rankingclientenpsReducer'
import csatconcorrentesState from '../modules/empresa/csatconcorrentes/redux/csatconcorrentesReducer'
import npsconcorrentesState from '../modules/empresa/npsconcorrentes/redux/npsconcorrentesReducer'

import infoGrupoState from '../modules/grupos/infoGrupo/redux/infoGrupoReducer'
import rankingcsatState from '../modules/grupos/rankingcsat/redux/rankingcsatReducer'
import rankingnpsState from '../modules/grupos/rankingnps/redux/rankingnpsReducer'
import indicadoresState from '../modules/grupos/indicadores/redux/indicadoresReducer'
import swotState from '../modules/grupos/swot/redux/swotReducer'

import comentariosState from '../modules/comentarios/redux/comentariosReducer'
import redescsatclienteState from '../modules/redes/csatcliente/redux/csatclienteReducer'

import csatconcorrentesredesState from '../modules/redes/csatconcorrentes/redux/csatconcorrentesredesReducer'

import redesnpsclienteState from '../modules/redes/npscliente/redux/npsclienteReducer'

import npsconcorrentesredesState from '../modules/redes/npsconcorrentes/redux/npsconcorrentesredesReducer'

import indicadoresredesState from '../modules/redes/indicadores/redux/indicadoresReducer'

//PRODUTO
import csatconcorrentesprodutosState from '../modules/produtos/csatconcorrentes/redux/csatconcorrentesprodutosReducer'
import npsconcorrentesprodutosState from '../modules/produtos/npsconcorrentes/redux/npsconcorrentesprodutosReducer'
import indicadoresprodutosState from '../modules/redes/indicadores/redux/indicadoresReducer'
import swotStateProdutos from '../modules/produtos/swot/redux/swotReducer'
import infoGrupoStateProdutos from '../modules/produtos/infoGrupo/redux/infoGrupoReducer'

//COMENTÁRIOS
import comentariosnovosState from '../modules/comentnovo/redux/comentariosReducer'

//PLANO DE AÇÃO
  import plano7diasState from '../modules/planos/plano7dias/redux/plano7diasReducer'

export const rootReducer = combineReducers({
  alerts,
  loginState,
  layoutState,

  questionarioState,
  meusProjetosState,
  minhasAssinaturasState,
  meusDadosState,
  usuariosState,
  centralAjudaState,
  projetosState,
  upgradeContaState,

  homeState,
  basededadosState,

  csatclienteState,
  npsclienteState,
  rankingclientecsatState,
  rankingclientenpsState,
  csatconcorrentesState,
  npsconcorrentesState,

  infoGrupoState,
  rankingcsatState,
  rankingnpsState,
  indicadoresState,
  swotState,

  comentariosState,
  redescsatclienteState,
  redesnpsclienteState,
  csatconcorrentesredesState,
  npsconcorrentesredesState,
  indicadoresredesState,

  //PRODUTO
  csatconcorrentesprodutosState,
  npsconcorrentesprodutosState,
  indicadoresprodutosState,
  swotStateProdutos,
  infoGrupoStateProdutos,

  //COMENTÁRIOS
  comentariosnovosState,

  //PLANOS
  plano7diasState,


})

export default rootReducer
