import React from 'react'

export const gerarLista = e => {
    return Array(4).fill().map((_, i)=> ({
        id: i+1,
        titulo: `Titulo ${i+1}`,
        conteudo: `Explore os projetos de cortesia disponíveis no sistema para ter uma visão inicial do que oferecemos. ${i+1}`,
        action: 'Ver mais',
        status: true,
        licencaData: "2023-12-08T00:09:51.744Z"
    }))
}

export const paginateInit = e => {
  return { 
    pageNumber: 1, 
    totalPages: 1, 
    totalElements: 0,
    perPage: 7
  }
}
