import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../layout/redux/layoutActions'
import { Button, Checkbox, IcoPaste, Input, Modal, TextArea } from '../../components'
import { cleanSuporteCentralAjuda, setSuporteCentralAjuda } from './redux/centralAjudaActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { suporte, erro } = useSelector(state=> state.centralAjudaState)
  const nls = require(`./nls/${sistema?.idioma}.json`)

  const handleCloseModal = e => {
    dispatch([
      modalClose(),
      cleanSuporteCentralAjuda()
    ]) 
  }  

  const handleChange = e => {
    dispatch(setSuporteCentralAjuda(e))
  }

  const handleColar = e => {
    navigator.clipboard.readText().then((clipText) => {
      dispatch(setSuporteCentralAjuda({name: e, value: clipText}))
    });
  }  

  const handlerCriar = e => {
    dispatch(handleCloseModal(e))
  }

  return (
    <Modal
      title={nls?.titleSuporte}
      size='medium'
      open={statusModal === 'contatoSuporteModal' ? true : false}
      close={() => handleCloseModal()}
      closeText={nls?.btnFechar}
      actions={<>
        <Button
          color='primary'
          onClick={()=> handlerCriar(suporte)}
        >{nls?.btnEnviarQuestao}</Button>
      </>}
    >
      <div>
        <TextArea
          name='mensagem'
          label={nls?.formMenssagem}
          value={suporte?.mensagem}
          placeholder={nls?.formMenssagemPH}
          action={(e) => handleChange(e)}
          required={{
            erro,
            message: ''
          }}
        />
        <Input
          name='link'
          label={nls?.formLink}
          value={suporte?.link}
          placeholder={nls?.formLinkPH}
          action={(e) => handleChange(e)}
          right={<>
            <span className='btn-colar' onClick={()=> handleColar('link')}><IcoPaste /> {nls?.btnColar}</span>
          </>}
        />
        <div className="form-box">
          <label className="label-input"><span className="input-label-span-text">{nls.privado}</span></label>
        </div>
        <div>
          <p>{nls.privadoInfo1}</p>
          <p>{nls.privadoInfo2}</p>
          <Checkbox
            name='privado'
            checked={suporte.privado}
            action={c=> handleChange({name: 'privado', value: c})}
            text={nls.tornarPrivado}
          />
        </div>
      </div>
    </Modal>
  )
}
