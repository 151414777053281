import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, IcoEye, IcoEyeBlocked, Input, Loading, validacaoForm, validarCampo, verificaValidacao } from '../../components'

import { setChangeEsqueci, setChangeForm, setError, setSenhaVisivel } from './redux/loginActions'
import './login.scss'
import { history } from '../../helpers'
import { getIp, logIn } from '../../layout/redux/layoutActions'
import imgFrame from '../../layout/img/login/bg-1-front.png'
import imgLogo from '../../layout/img/logo-yellow.png'

export default () => {
  const dispatch = useDispatch()
  const { sistema, load } = useSelector(state=> state.layoutState)
  const { senhaVisivel, login, erro } = useSelector(state=> state.loginState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const logInState = useSelector(state=> state.layoutState)

  useEffect(()=> {
    if (!logInState.logIn) { 
      dispatch([
        getIp()
      ])
    }
  },[])

  const formRequired = {
    email: 'email',
    senha: ''
  }

  const handleChange = e => {
    dispatch(setChangeForm(e))
    if (formRequired[e.name]) {
      const verify = validarCampo(e)
      dispatch(setError({...erro, [verify.name]: verify.message}))
    }
  }

  const handleSee = e => {
    dispatch(setSenhaVisivel(e))
  }

  const handleLogin = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    dispatch(setError(valid))
    const verify = verificaValidacao(valid)
    if (verify.value) {
      dispatch(logIn(login))
    }
  }

  const handleEsqueciSenha = e => {
    if (e) {
      dispatch(setChangeEsqueci({name: 'email', value: e}))
    }
    history.push('/recuperarsenha')
  }

  return (
    <div className='box-login'>
      <Alert />
      <div className='box-login-container'>
        <div className='box-login-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-login-form'>
          <div className='box-login-content'>
            <div className='box-login-logo'>
              {/* <IcoLogo style={{width: '262px'}} /> */}
              <img src={imgLogo} />
            </div>
            <div className='box-login-info'>
              <h2>{nls.login.saudacao}</h2>
              {/* <h4></h4> */}
              <p>{nls.login.subtitle}</p>
            </div>
            <Input
              // label={nls.formEmail}
              name='email'
              action={(e) => handleChange(e)}
              value={login.email}
              placeholder={nls.formEmailCadastradoPH}
              required={{
                erro,
                message: nls.mensagem,
                pattern: formRequired.email
              }}
            />
            <Input
              // label={nls.formSenha}
              name='senha'
              type={!senhaVisivel.senha?'password':'text'}
              action={(e) => handleChange(e)}
              value={login.senha}
              placeholder={nls.formSenhaPH}
              right={
                <Button type='link' onClick={()=> handleSee({name: 'senha', value: !senhaVisivel.senha})}>
                  {!senhaVisivel.senha ? <IcoEye />
                  : <IcoEyeBlocked />}
                </Button>
              }
              required={{
                erro,
                message: nls.mensagem.obrigatorio,
                pattern: formRequired.senha
              }}
            />
            <div className='box-login-action'>
              <div>
                <small>{nls.login.minimoCaracteres}</small>
                <Button
                  type='link'
                  color='info'
                  variant='outline'
                  onClick={()=> handleEsqueciSenha(login.email)}
                >{nls.btnEsqueciSenha}</Button>
              </div>
              <div className='box-login-action-btns'>
                <Button
                  color='primary'
                  onClick={()=> handleLogin(login)}
                >{nls.btnEntrar}</Button>
              </div>
              <div className='box-login-action-btns'>
                <Button
                  color='primary'
                  type='link'
                  variant='outline'
                  // onClick={()=> window.open('https://www.xdive.com.br/#Contato', '_blank')}
                  onClick={()=> history.push('/cadastro')}
                >{nls.btnNaoPossuiCadastro}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {load? <Loading />:null}
    </div>
  )
}
