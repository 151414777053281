import React from 'react'

export const IcoArrowTop = ({style={}}) => {
  return (
  <svg 
  className='icon-top' 
  stroke="currentColor" 
  fill="currentColor" 
  viewBox="0 0 24 24" 
  style={{...style, transform: 'rotate(180deg)'}} >
    <path fill="none" d="M0 0h24v24H0V0z"></path>
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
  </svg>
  )
}