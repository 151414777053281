import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Paginate } from '../../../components/pagination/pagination';
import { Select } from '../../../components/form/form';
import { numberAleatori } from '../../../helpers';

//https://www.flaticon.com/br/buscar?word=google%20map
import gmbIcon from './iconimages/gmb.png';
import facebookIcon from './iconimages/facebook.png';
import instagramIcon from './iconimages/instagram.png';
import tiktokIcon from './iconimages/tiktok.png';
import tripadvisorIcon from './iconimages/tripadvisor.png';
import youtubeIcon from './iconimages/youtube.png';
import twitterIcon from './iconimages/twitter.png';
import amazonIcon from './iconimages/amazon.png';

const iconMap = {
  "Google My Business": gmbIcon,
  "Facebook": facebookIcon,
  "Instagram": instagramIcon,
  "TikTok": tiktokIcon,
  "Youtube": youtubeIcon,
  "Twitter": twitterIcon,
  "Tripadvisor": tripadvisorIcon,
  "Amazon": amazonIcon
};


export const perPageList = [
  { id: '7' },
  { id: '20' },
  { id: '50' }
]

/*
export const nlsDefault = {
  paginas: `{{Id}} / Páginas`,
  registro: `Registro`,
  registros: `Registros`,
  noData: `Sem informação`,
}
*/

export const Listwithicon = ({
  header,
  tipo,
  data,
  semdadosparaexibir,
  erronaapi,
  listCustom = () => null,
  sort={},
  noData = 'Sem informação',
  paginate = false,
  perPageOptions=[],
  actionPaginate=()=> null,
  handlePerPage=()=> null,
  handleSort=()=> null,
  //nls=nlsDefault, 
  nlsgeral
}) => {

  const nlsDefault = {
    paginas: `{{Id}} / ` + nlsgeral.paginas,
    registro: nlsgeral.registro,
    registros: nlsgeral.registros,
    noData: nlsgeral.semdadosparaexibir,
  }

  const nls=nlsDefault
  const [listState, setListState] = useState([]);
  perPageOptions = perPageList.map(e=> ({...e, name: nls?.paginas?.replace('{{Id}}', e.id)}))
  const { erroComentario } = useSelector(state=> state.comentariosnovosState)

  useEffect(()=>{
    setListState(data)
  }, [data])

  const sortType = (e) => {
    switch (e) {
      case 'up':
        return 'down';
      case 'down':
        return 'normal';
      case 'normal':
        return 'up';
      default:
        return 'up';
    }
  }

  const sortHandle = ({sortInfo, sortColumn}) => {
    if (sortColumn) {
      handleSort(sortInfo)
    }
  }

  
  console.error(data, 'DATASXXXXX');
  

  return (
    <>
    <div className="box-scrool">
      <table className="list-box">
        <thead>
          <tr>
            {header?.map((head) => {
              return (
                <td 
                  key={head.column}
                  className={`${head.className} ${head.sort?`list-box-header-sort ${sort?.column === head.column?`sort-${sort?.type}`: ''}`:''}`} 
                  onClick={()=> 
                    sortHandle({
                      sortColumn: head.sort, 
                      sortInfo: {
                        column: head.column, 
                        type: sort?.column === head.column ? sortType(sort.type) : 'up'
                      }
                    })
                  }
                >
                  {head.text}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length && erroComentario === false? (
            listState?.map((container, i) => {
              listCustom(container, i, data);
              const aleatorioId = numberAleatori()
              return (
                <tr key={`${container.id ? container.id : i}-${aleatorioId}`}>
                  {header.map((head,index) => {
                    return (
                      <td
                        className={head.className}
                        key={`${container.id ? container.id : i}-${ head.column }-${aleatorioId}`}
                      >

                      {tipo === 'redes' ? (
                        index === 0 ? (
                          <img src={iconMap[container[head.column]]} alt={container[head.column]} width="32" height="32" />
                        ) : (
                          container[head.column] ? container[head.column] : ''
                        )
                      ) : (
                        index === 2 ? (
                          <img src={iconMap[container[head.column]]} alt={container[head.column]} width="32" height="32" />
                        ) : (
                          container[head.column] ? container[head.column] : ''
                        )
                      )}

                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={header?.length} className="text-center">
              <div className='pageloadview-message'>{erroComentario ? erronaapi: semdadosparaexibir}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    {
      paginate?
      <PaginateList
        nls={nls}
        paginate={paginate}
        perPageOptions={perPageOptions}
        actionPaginate={actionPaginate}
        handlePerPage={handlePerPage}
      />
      :null
    }
    </>
  );
};

export const PaginateList = ({
  paginate,
  nls={},
  perPageOptions = [],
  actionPaginate = ()=> null,
  handlePerPage = ()=> null,
}) => {

  const selectedValue = e => {
    const filter = perPageOptions.filter(f=> String(f.id) === String(e))
    if (filter.length) {
      return filter[0]
    } else {
      return perPageOptions.length?perPageOptions[0]:{}
    }
  }

  

  
  return (
  <div className="list-box-actionsfooter">
    <div className="list-box-registros">
      {paginate.totalElements} {paginate.totalElements === 1 ? nls.registro : nls.registros}
    </div>
    <div className="list-box-paginacao">
      <Paginate
        data={paginate}
        action={actionPaginate}
      />
    </div>
    <div className="list-box-itensperpage">
      <Select
        name='projeto'
        label=''
        options={perPageOptions}
        selected={selectedValue(paginate?.perPage)}
        action={handlePerPage}
        selectedItem={false}
      />
    </div>
  </div>
  )
}