import React, { useEffect, useState } from 'react';
import { history } from '../../helpers/history';
import { IcoArrowDown, IcoArrowUp } from '../icon/icon';

export const Menu = ({ children, data, action = () => null }) => {
  const location = window.location;
  const [submenuStatus, setSubmenuStatus] = useState({})

  const isActive = ({go}) => location.pathname === go //location.pathname.includes(go);

  useEffect(()=>{
    data?.map(d=> {
      if (d.sub) {
        const activeItem = d.sub.filter(e=> e.go === location.pathname)
        if (activeItem.length) {
          setSubmenuStatus({...submenuStatus, [d.id]: !submenuStatus[d.id]});
        }
      }
    })
  },[data])

  const actionMenu = (e) => {
    if (e.url) {
      window.open(e.url, '_blank');
    }
    if (e.go) {
      history.push(e.go);
    }
    if (e.sub) {
      setSubmenuStatus({...submenuStatus, [e.id]: !submenuStatus[e.id]});
    }
    action(e);
  };

  return (
    <div className="menu">
      {data && data.length
        ? data.map((item) => {
            return (
              <div key={item.id}>
              <button
                className={`${isActive(item) ? 'active' : ''} ${ submenuStatus[item.id] ? 'open' : ''}`}
                onClick={() => actionMenu(item)}
              >
                <span>{item.icon ? item.icon : null} {item.label}</span>
                {
                  item.sub?
                  <i>
                    {
                      isActive(item) || submenuStatus[item.id]?
                      <IcoArrowUp />
                      :
                      <IcoArrowDown />
                    }
                  </i>
                  :null
                }
              </button>
              {
                item.sub && (submenuStatus[item.id] || isActive(item))? <div className='menu-submenu'>
                  {
                    item.sub.map((subitem) => {
                      return (
                        <button
                          key={`${item.id}-${subitem.id}`}
                          className={isActive(subitem) ? 'active' : ''}
                          onClick={() => actionMenu(subitem)}
                        >
                          <span>{subitem.icon ? subitem.icon : null} {subitem.label}</span>
                        </button>
                        
                      );
                    })
                  }
                </div>
                : null
              }
              </div>
            );
          })
        : null}

      {children}
    </div>
  );
};
