import React from 'react'

export const IcoImportar = ({...props}) => {
  const style = props.style?props.style:{}
  return (
    <svg className='icon-importar' viewBox="0 0 214 161" {...props} style={{...style}}>
    <rect y="56.3936" width="214" height="92" rx="16" fill="#F7FAFC"/>
<g clipPath="url(#clip0_2_9063)">
<path d="M109.159 0.988031L103.368 2.48162L32.0115 20.883L26.2197 22.3766C23.4084 23.1049 21.0008 24.9187 19.5252 27.4199C18.0495 29.9211 17.6262 32.9056 18.3483 35.7185L43.4628 133.107C44.1912 135.918 46.0049 138.326 48.5061 139.802C51.0074 141.277 53.9918 141.7 56.8047 140.978L56.8197 140.975L139.73 119.594L139.745 119.59C142.556 118.862 144.963 117.048 146.439 114.547C147.915 112.045 148.338 109.061 147.616 106.248L122.501 8.85947C121.773 6.04819 119.959 3.64061 117.458 2.16494C114.957 0.689268 111.972 0.266012 109.159 0.988031Z" fill="#F2F2F2"/>
<path d="M109.947 4.04135L103.087 5.8104L33.867 23.6609L27.0072 25.4299C25.0052 25.9486 23.2907 27.2402 22.2398 29.0214C21.189 30.8025 20.8875 32.9278 21.4017 34.931L46.5163 132.32C47.0349 134.322 48.3266 136.036 50.1077 137.087C51.8889 138.138 54.0142 138.439 56.0174 137.925L56.0323 137.921L138.942 116.54L138.957 116.536C140.959 116.018 142.674 114.726 143.725 112.945C144.775 111.164 145.077 109.038 144.563 107.035L119.448 9.64678C118.929 7.64483 117.638 5.93035 115.857 4.87949C114.075 3.82863 111.95 3.5272 109.947 4.04135Z" fill="white"/>
<path d="M104.767 35.5784L62.825 46.3944C62.3565 46.5152 61.8593 46.445 61.4426 46.1992C61.0259 45.9533 60.724 45.5521 60.6032 45.0836C60.4824 44.6152 60.5526 44.1179 60.7985 43.7012C61.0443 43.2846 61.4455 42.9826 61.914 42.8618L103.856 32.0458C104.324 31.9255 104.821 31.996 105.238 32.2419C105.654 32.4877 105.956 32.8888 106.076 33.357C106.197 33.8252 106.127 34.3221 105.881 34.7387C105.636 35.1552 105.235 35.4572 104.767 35.5784Z" fill="#F2F2F2"/>
<path d="M113.59 39.6607L64.3623 52.3556C63.8939 52.4764 63.3966 52.4062 62.98 52.1604C62.5633 51.9145 62.2614 51.5133 62.1406 51.0448C62.0198 50.5764 62.09 50.0791 62.3358 49.6625C62.5816 49.2458 62.9829 48.9438 63.4514 48.823L112.679 36.1281C113.148 36.0073 113.645 36.0775 114.062 36.3234C114.478 36.5692 114.78 36.9705 114.901 37.4389C115.022 37.9074 114.952 38.4046 114.706 38.8213C114.46 39.2379 114.059 39.5399 113.59 39.6607Z" fill="#F2F2F2"/>
<path d="M111.656 62.2936L69.7144 73.1097C69.4824 73.1695 69.241 73.183 69.0038 73.1495C68.7666 73.116 68.5383 73.0361 68.332 72.9144C68.1257 72.7927 67.9454 72.6315 67.8014 72.4401C67.6573 72.2487 67.5524 72.0308 67.4926 71.7989C67.4328 71.5669 67.4192 71.3254 67.4527 71.0883C67.4862 70.8511 67.5661 70.6228 67.6879 70.4165C67.8096 70.2102 67.9707 70.0299 68.1621 69.8858C68.3536 69.7418 68.5715 69.6369 68.8034 69.5771L110.746 58.761C110.977 58.7012 111.219 58.6877 111.456 58.7212C111.693 58.7547 111.922 58.8346 112.128 58.9563C112.334 59.078 112.515 59.2392 112.659 59.4306C112.803 59.622 112.907 59.8399 112.967 60.0718C113.027 60.3038 113.041 60.5453 113.007 60.7824C112.974 61.0196 112.894 61.2479 112.772 61.4542C112.65 61.6605 112.489 61.8408 112.298 61.9849C112.106 62.1289 111.888 62.2338 111.656 62.2936Z" fill="#F2F2F2"/>
<path d="M120.48 66.3759L71.2516 79.0708C71.0197 79.1307 70.7782 79.1442 70.541 79.1107C70.3038 79.0772 70.0756 78.9973 69.8692 78.8756C69.6629 78.7539 69.4826 78.5927 69.3386 78.4013C69.1946 78.2099 69.0896 77.992 69.0298 77.76C68.97 77.5281 68.9565 77.2866 68.99 77.0494C69.0235 76.8122 69.1034 76.584 69.2251 76.3777C69.3468 76.1714 69.508 75.991 69.6994 75.847C69.8908 75.703 70.1087 75.5981 70.3406 75.5383L119.569 62.8433C120.037 62.7225 120.534 62.7927 120.951 63.0386C121.368 63.2844 121.67 63.6857 121.791 64.1541C121.911 64.6226 121.841 65.1198 121.595 65.5365C121.349 65.9532 120.948 66.2551 120.48 66.3759Z" fill="#F2F2F2"/>
<path d="M118.546 89.0088L76.6037 99.8249C76.1354 99.9453 75.6384 99.8748 75.222 99.629C74.8056 99.3831 74.5039 98.982 74.3831 98.5138C74.2624 98.0455 74.3325 97.5485 74.578 97.1319C74.8236 96.7154 75.2245 96.4134 75.6927 96.2923L117.635 85.4762C118.103 85.3554 118.601 85.4257 119.017 85.6715C119.434 85.9173 119.736 86.3186 119.857 86.787C119.977 87.2555 119.907 87.7528 119.661 88.1694C119.416 88.5861 119.014 88.888 118.546 89.0088Z" fill="#F2F2F2"/>
<path d="M127.369 93.0911L78.141 105.786C77.909 105.846 77.6674 105.86 77.43 105.826C77.1927 105.793 76.9642 105.713 76.7578 105.592C76.5513 105.47 76.3708 105.309 76.2267 105.117C76.0825 104.926 75.9775 104.708 75.9177 104.476C75.8578 104.244 75.8443 104.002 75.8778 103.765C75.9114 103.527 75.9914 103.299 76.1133 103.093C76.2352 102.886 76.3966 102.706 76.5882 102.562C76.7798 102.418 76.9979 102.313 77.23 102.253L126.458 89.5585C126.927 89.4377 127.424 89.508 127.84 89.7538C128.257 89.9996 128.559 90.4009 128.68 90.8693C128.801 91.3378 128.73 91.835 128.485 92.2517C128.239 92.6684 127.838 92.9703 127.369 93.0911Z" fill="#F2F2F2"/>
<path d="M167.006 25.6748H81.3526C78.4485 25.6781 75.6643 26.8332 73.6108 28.8867C71.5573 30.9402 70.4022 33.7244 70.3989 36.6285V137.203C70.4022 140.107 71.5573 142.891 73.6108 144.945C75.6643 146.999 78.4485 148.154 81.3526 148.157H167.006C169.91 148.154 172.694 146.999 174.748 144.945C176.801 142.891 177.956 140.107 177.96 137.203V36.6285C177.956 33.7244 176.801 30.9402 174.748 28.8867C172.694 26.8332 169.91 25.6781 167.006 25.6748Z" fill="#E6E6E6"/>
<path d="M167.006 28.8281H81.3526C79.2845 28.8304 77.3018 29.653 75.8395 31.1154C74.3771 32.5777 73.5546 34.5604 73.5522 36.6285V137.203C73.5546 139.271 74.3771 141.254 75.8395 142.716C77.3018 144.179 79.2845 145.001 81.3526 145.004H167.006C169.074 145.001 171.057 144.179 172.519 142.716C173.981 141.254 174.804 139.271 174.806 137.203V36.6285C174.804 34.5604 173.981 32.5777 172.519 31.1154C171.057 29.653 169.074 28.8305 167.006 28.8281Z" fill="white"/>
<path d="M176 157.5C185.389 157.5 193 149.889 193 140.5C193 131.111 185.389 123.5 176 123.5C166.611 123.5 159 131.111 159 140.5C159 149.889 166.611 157.5 176 157.5Z" fill="#D72D3B"/>
<path d="M181.5 139H177.5V135C177.5 134.602 177.342 134.221 177.061 133.939C176.779 133.658 176.398 133.5 176 133.5C175.602 133.5 175.221 133.658 174.939 133.939C174.658 134.221 174.5 134.602 174.5 135V139H170.5C170.102 139 169.721 139.158 169.439 139.439C169.158 139.721 169 140.102 169 140.5C169 140.898 169.158 141.279 169.439 141.561C169.721 141.842 170.102 142 170.5 142H174.5V146C174.5 146.398 174.658 146.779 174.939 147.061C175.221 147.342 175.602 147.5 176 147.5C176.398 147.5 176.779 147.342 177.061 147.061C177.342 146.779 177.5 146.398 177.5 146V142H181.5C181.898 142 182.279 141.842 182.561 141.561C182.842 141.279 183 140.898 183 140.5C183 140.102 182.842 139.721 182.561 139.439C182.279 139.158 181.898 139 181.5 139Z" fill="white"/>
<path d="M154.115 84.0936H110.801C110.561 84.0939 110.324 84.0469 110.102 83.9553C109.881 83.8638 109.679 83.7295 109.51 83.5601C109.34 83.3906 109.206 83.1895 109.114 82.968C109.022 82.7466 108.975 82.5092 108.975 82.2695C108.975 82.0298 109.022 81.7924 109.114 81.571C109.206 81.3496 109.34 81.1484 109.51 80.979C109.679 80.8096 109.881 80.6752 110.102 80.5837C110.324 80.4921 110.561 80.4452 110.801 80.4454H154.115C154.599 80.446 155.062 80.6384 155.404 80.9804C155.745 81.3224 155.937 81.7861 155.937 82.2695C155.937 82.7529 155.745 83.2166 155.404 83.5586C155.062 83.9006 154.599 84.0931 154.115 84.0936Z" fill="#E6E6E6"/>
<path d="M161.639 90.2498H110.801C110.561 90.2501 110.324 90.2031 110.102 90.1116C109.881 90.02 109.679 89.8857 109.51 89.7163C109.34 89.5469 109.206 89.3457 109.114 89.1243C109.022 88.9028 108.975 88.6655 108.975 88.4258C108.975 88.186 109.022 87.9487 109.114 87.7272C109.206 87.5058 109.34 87.3046 109.51 87.1352C109.679 86.9658 109.881 86.8315 110.102 86.7399C110.324 86.6484 110.561 86.6014 110.801 86.6017H161.639C162.123 86.6017 162.587 86.7939 162.929 87.1359C163.271 87.478 163.464 87.942 163.464 88.4258C163.464 88.9095 163.271 89.3735 162.929 89.7156C162.587 90.0577 162.123 90.2498 161.639 90.2498Z" fill="#E6E6E6"/>
<path d="M154.115 111.683H110.801C110.561 111.683 110.324 111.636 110.102 111.545C109.881 111.453 109.679 111.319 109.51 111.149C109.34 110.98 109.206 110.779 109.114 110.557C109.022 110.336 108.975 110.098 108.975 109.859C108.975 109.619 109.022 109.382 109.114 109.16C109.206 108.939 109.34 108.738 109.51 108.568C109.679 108.399 109.881 108.264 110.102 108.173C110.324 108.081 110.561 108.034 110.801 108.035H154.115C154.599 108.035 155.063 108.227 155.405 108.569C155.747 108.911 155.939 109.375 155.939 109.859C155.939 110.343 155.747 110.806 155.405 111.149C155.063 111.491 154.599 111.683 154.115 111.683Z" fill="#E6E6E6"/>
<path d="M161.639 117.839H110.801C110.561 117.839 110.324 117.792 110.102 117.701C109.881 117.609 109.679 117.475 109.51 117.306C109.34 117.136 109.206 116.935 109.114 116.714C109.022 116.492 108.975 116.255 108.975 116.015C108.975 115.775 109.022 115.538 109.114 115.317C109.206 115.095 109.34 114.894 109.51 114.725C109.679 114.555 109.881 114.421 110.102 114.329C110.324 114.238 110.561 114.191 110.801 114.191H161.639C161.879 114.191 162.117 114.238 162.338 114.329C162.56 114.421 162.761 114.555 162.931 114.725C163.1 114.894 163.235 115.095 163.327 115.317C163.418 115.538 163.466 115.775 163.466 116.015C163.466 116.255 163.418 116.492 163.327 116.714C163.235 116.935 163.1 117.136 162.931 117.306C162.761 117.475 162.56 117.609 162.338 117.701C162.117 117.792 161.879 117.839 161.639 117.839Z" fill="#E6E6E6"/>
<path d="M101.098 93.0447H85.7697C85.5378 93.0444 85.3155 92.9522 85.1515 92.7882C84.9875 92.6242 84.8953 92.4019 84.895 92.17V78.5255C84.8953 78.2936 84.9875 78.0713 85.1515 77.9073C85.3155 77.7434 85.5378 77.6511 85.7697 77.6509H101.098C101.33 77.6511 101.552 77.7434 101.716 77.9073C101.88 78.0713 101.973 78.2936 101.973 78.5255V92.17C101.973 92.4019 101.88 92.6242 101.716 92.7882C101.552 92.9522 101.33 93.0444 101.098 93.0447Z" fill="#E6E6E6"/>
<path d="M101.098 120.634H85.7697C85.5378 120.634 85.3155 120.541 85.1515 120.377C84.9875 120.213 84.8953 119.991 84.895 119.759V106.115C84.8953 105.883 84.9875 105.66 85.1515 105.496C85.3155 105.332 85.5378 105.24 85.7697 105.24H101.098C101.33 105.24 101.552 105.332 101.716 105.496C101.88 105.66 101.973 105.883 101.973 106.115V119.759C101.973 119.991 101.88 120.213 101.716 120.377C101.552 120.541 101.33 120.634 101.098 120.634Z" fill="#E6E6E6"/>
<path d="M154.163 53.3814H122.229C121.746 53.3814 121.282 53.1893 120.94 52.8472C120.597 52.5051 120.405 52.0411 120.405 51.5574C120.405 51.0736 120.597 50.6096 120.94 50.2675C121.282 49.9255 121.746 49.7333 122.229 49.7333H154.163C154.647 49.7333 155.111 49.9255 155.453 50.2675C155.795 50.6096 155.987 51.0736 155.987 51.5574C155.987 52.0411 155.795 52.5051 155.453 52.8472C155.111 53.1893 154.647 53.3814 154.163 53.3814Z" fill="#CCCCCC"/>
<path d="M161.688 59.5377H122.229C121.99 59.5377 121.753 59.4905 121.531 59.3988C121.31 59.3072 121.109 59.1728 120.94 59.0034C120.77 58.834 120.636 58.633 120.544 58.4116C120.452 58.1903 120.405 57.9531 120.405 57.7136C120.405 57.4741 120.452 57.2369 120.544 57.0156C120.636 56.7943 120.77 56.5932 120.94 56.4238C121.109 56.2544 121.31 56.12 121.531 56.0284C121.753 55.9367 121.99 55.8895 122.229 55.8895H161.688C162.171 55.8895 162.635 56.0817 162.977 56.4238C163.319 56.7659 163.512 57.2298 163.512 57.7136C163.512 58.1974 163.319 58.6613 162.977 59.0034C162.635 59.3455 162.171 59.5377 161.688 59.5377Z" fill="#CCCCCC"/>
<path d="M115.162 66.9983H85.7216C85.4897 66.9981 85.2674 66.9058 85.1034 66.7419C84.9394 66.5779 84.8472 66.3556 84.8469 66.1237V43.1472C84.8472 42.9153 84.9394 42.693 85.1034 42.529C85.2674 42.3651 85.4897 42.2728 85.7216 42.2726H115.162C115.394 42.2728 115.616 42.3651 115.78 42.529C115.944 42.693 116.036 42.9153 116.036 43.1472V66.1237C116.036 66.3556 115.944 66.5779 115.78 66.7419C115.616 66.9058 115.394 66.9981 115.162 66.9983Z" fill="#D72D3B"/>
</g>
<defs>
<clipPath id="clip0_2_9063">
<rect width="178" height="159.754" fill="white" transform="translate(18 0.639648)"/>
</clipPath>
</defs> 
    </svg>

  )
}