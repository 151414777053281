import React, { useRef } from 'react'
import Portal from '../portal/portal'

export const ModalRight = ({
  children,
  open = false,
  style = { maxHeight: `calc(100vh - 0px)`, width: '260px', top: `0px` },
  action= ()=> null,
}) => {

  return open ? (
    <Portal name="modal-sidebar">
      <div
        id='box-sidebar-right'
        className={open ? 'open-sidebar' : ''}
        style={style}
      >
        {children}
      </div>
    </Portal>
  ) :null
}
