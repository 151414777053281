import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './calculadora.scss'

import { Button, Input, MaskData, Modal } from '../../../components'
import { getIp, modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions'
import { AtualizarAssinaturaContaXD, calculadoraContaEmpresarial, listaPeriodos, listarAtivos, setAtivos } from '../../minhasAssinaturas/redux/minhasAssinaturasActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const { assinatura, listas, ativos, avaliacoes } = useSelector(state=> state.minhasAssinaturasState)
  const nls = require(`../../minhasAssinaturas/nls/${sistema?.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema?.idioma}.json`)
  const [form, setForm] = useState({periodo: {}})
  
  useEffect(()=> {
    dispatch(getIp())
  }, [])

  useEffect(()=> {
    if (listas?.periodos.length) {
      const list = listas?.periodos.find(f=> f.id === 4)
      setForm({...form, periodo: list})
    }
  }, [listas?.periodos])

  useEffect(()=> {
      dispatch(listarAtivos({consumoConta: sistema.consumoConta, moeda: sistema.moeda, tipo: 'CE', status: 'vazio', idioma: sistema.idiomaIdValue}))
      dispatch(listaPeriodos({idioma: sistema.idiomaIdValue}))
  }, [sistema.idioma, sistema.consumoConta])

  const handleChange = e => {
    if (sistema.moeda) {
      const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
      dispatch(setAtivos(e))
      dispatch(calculadoraContaEmpresarial({ativos: listaAtivos, moeda: sistema.moeda}))
    }
  }

  const handleCancelar = e => {
    dispatch(modalClose())
  }

  const handleConcordar = e => {
    console.warn(e, 'handleConcordar');

    const params = {
      idPeriodo: e.periodo.id,
      moeda: sistema.moeda,
      dataAgendadaAlteracao: assinatura.dataRenovacaoAssinatura,
      ativos: [...ativos, {
        descricao: "Avaliações mensais",
        id: 6,
        quantidadeDesejada: avaliacoes.totalQueriesSemQueryPura30,
        valor: avaliacoes.totalQueriesSemQueryPura30
      }]
    }
    dispatch(AtualizarAssinaturaContaXD(params, nlsPopup))
  }
  
  const handleSalvar = e => {
    console.warn(e, 'assinaturas-alterar-projeto');
    dispatch(modalAlertaInfo({
      ...nlsPopup['1'], 
      btnAction: ()=> handleConcordar(e, 'assinaturas-economizar'),
      show: true,
      textos: {
        DataRenovacaoAssinatura: MaskData(assinatura.dataRenovacaoAssinatura, 'DD/MM/YYYY', sistema.idioma),
      }
    }))
    handleCancelar()
  }
  
  const handleChangePeriodo = e => {
    setForm({...form, periodo: e})
  }
  
  const valorTotal = e => {
    const val = e?.find(f=> Number(f.id) === Number(form.periodo.id))
    return val?.valor
  }
  
  
  return (
    <div className='box-assinaturas-adquirir-ativos'>
        <div className='periodos-lista'>
          <strong>{nls.selecionePeriodo}</strong>
          <div>
            {listas?.periodos?.map(e=> {
              return <div key={e.id}>
                <Button
                  type='btn circle'
                  color={form?.periodo?.id === e.id ? 'primary' : 'default'}
                  size='small'
                  onClick={()=> handleChangePeriodo(e)}
                >{e.descricao}</Button>
              </div>
            })}
          </div>
        </div>
        <div className='ativos-lista-title'>
          <h5>{nls.adicionarAtivos}</h5>
        </div>
        <div className='ativos-lista'>
          {ativos.map(e=> {
            const min = e.id <= 2 ? e.id : 0
            return <div key={e.id} className='ativos-lista-item'>
              <div className='ativos-lista-item-info'>
                <strong>{e.descricao}</strong>
              </div>
              <div className='ativos-lista-item-input'>
                  <Input 
                    type='number'
                    value={e.quantidadeDesejada}
                    action={a=> handleChange({...e, quantidadeDesejada: a.value})}
                    min={min}
                  />
              </div>
            </div>
          })}
          <div className='ativos-lista-item'>
            <div className='ativos-lista-item-info'>
              <strong>{nls.avaliacoesMensais}</strong>
            </div>
            <div className='ativos-lista-item-input'>
              <div className='flag-primary'>
                {avaliacoes.totalQueriesSemQueryPura30}
              </div>
            </div>
          </div>
        </div>
        <div className='ativos-info'>
          <span>{nls.valorTotalAssinatura}: <strong>{valorTotal(avaliacoes.valores)}</strong> </span>
        </div>
      </div>
  )
}
