import React, { HTMLAttributes, ReactChildren, useEffect, useState } from 'react';
import { validarCampo } from '../../validation/Validation';
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/pt-br'
import 'moment/locale/es'

export const InputDateRange = ({
  startDateId="startDate",
  startDate=null,
  endDateId="endDate",
  endDate=null,
  onDatesChange=() => null,
  focusedInput=null,
  onFocusChange=() => null,
  maxDate,
  rangeDays=500,
  minimumNights=1,
  startLabel='Data inicial',
  endLabel='Data final',


  action = () => null,
  actionBlur,
  type = 'text',
  color = '',
  required,
  placeholder = '',
  label,
  name = "",
  idioma,
  value,
  ...props
}) => {
  const [openCalendar, setopenCalendar] = useState(null)

  // moment.locale("pt-br");
  useEffect(()=> {
    openClose(focusedInput)
  },[focusedInput])

  const openClose = e => {
    setopenCalendar(e)
  }
  
  const isOutsideRange = day => {
    if (maxDate && moment(maxDate).isBefore(day, 'day')) {
      return true;
    }
    if (!isInclusivelyBeforeDay(day, moment())) {
      return true;
    }
    let currDate = moment(startDate).clone().startOf('day');
    if (
      startDate &&
      isInclusivelyAfterDay(day, currDate.add(rangeDays, 'days'))
    ) {
      return true;
    }
    return false;
  };

  if (required){
    const require = Object.keys(required);

  }

  //aqui define como o datepicker vai aparecer em função do locale escolhido
  //e precisa ajustar para os idiomas que não estão no padrão do Locale
  // no caso espanhol da espanha é 'es' mas usamos 'esp' que já está no nls
  //***********************TRATAR EXCECÕES DE LOCALE******************
  const idioma1 = (idioma === 'esp') ? 'es' : idioma;
  moment.locale(idioma1)

  return (
    <div
      className={`form-box form-input-date ${color} ${
        required && (required.erro[name] ? 'erro' : '')
      } `}
    >
      {label ? (
        <label className="label-input" htmlFor={`id-${name}`}>
          {required && require.length ? <span className='required-label'>*</span> : ''} 
          <span className='input-label-span-text'>{label}</span>
        </label>
      ) : null}

      <div className={`input-${name}-wrapper input-wrapper`}>
        {props.left ? <div className="input-actions-left">{props.left}</div> : null}
        <DateRangePicker
          startDateId={startDateId}
          startDate={startDate?moment(startDate):null}
          endDateId={endDateId}
          endDate={endDate?moment(endDate):null}
          onDatesChange={onDatesChange}
          focusedInput={openCalendar}
          onFocusChange={(e)=>openClose(e)}
          numberOfMonths={1}
          monthFormat="MMMM YYYY"
          displayFormat="DD/MM/YYYY"
          showDefaultInputIcon 
          isOutsideRange={isOutsideRange}
          minimumNights={minimumNights}
          startDatePlaceholderText={startLabel}
          endDatePlaceholderText={endLabel}

          // phrases={e=> console.log(e, 'jflkdjaflkdjsaflkdjasflkjda')}
          hideKeyboardShortcutsPanel 
          // autoFocus 
          // phrases={{closeDatePicker: 'Fechar',clearDates: 'Limpar', }}
        />
        {props.right ? <div className="input-actions">{props.right}</div> : null}
      </div>
      {required && required.erro?.[name] ? (
        <span className="campo-obrigatorio">
          {required.message?.[name] && required.erro[name]?
          required.message[name][required.erro[name]]
          :required.message }
        </span>
      ) : null}
    </div>
  );
};

export const dateCustom = e => {
  if (e) {
    const dateInfomoment = moment(e).toDate().toJSON()
    const dateInfo = dateInfomoment.split('T')
    const dateInfoSplit = dateInfo[0].split('-')
    
    return ({
      data: dateInfo[0],
      hora: dateInfo[1].replace('Z', ''),
      fullData: dateInfomoment,
      dataCustom: dateInfoSplit.reverse().join('/'),
      dataCustomUs: dateInfoSplit.join('/')
    })
  } else {
    return ({
      data: '',
      hora: '',
      fullData: '',
      dataCustom: '',
      dataCustomUs: ''
    })
  }
}