import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputDateRange } from '../../../components'
import { putFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { filtersProvisorio: {dataInicial, dataFinal} } = useSelector(state=> state.comentariosnovosState)

  const { sistema } = useSelector(state=> state.layoutState)
  const nls = require(`../nls/${sistema.idioma}.json`)


  const handleFormFilter = ({ startDate, endDate }) => {
    if (startDate) {
      dispatch(putFiltersProvisorio({ name: 'dataInicial', value: startDate }))
    }
    if (endDate) {
      dispatch(putFiltersProvisorio({ name: 'dataFinal', value: endDate }))
    }
  }
  
  console.error(dataInicial,'datainicial em filterdata')
  console.error(dataFinal,'datafinal em filterdata')
  return (
   <div className='box-comentarios-search comentarios-search-data'>
   <InputDateRange
     label={nls.periodo}
     startDateId="dataInicial"
     startDate={dataInicial}
     endDateId="dataFinal"
     endDate={dataFinal}
     onDatesChange={({ startDate, endDate }) => handleFormFilter({ startDate, endDate })}
     idioma={sistema.idioma}
   />
   </div>
  )
}
